import { FormMixin } from "@cp/store/mixins";
import { modalAddon } from "@cp/store/addons";
import { wait } from "@cp/utils/promiseUtils";
import { isEmpty } from "@cp/utils/objectUtils";
import { eventbus } from "@cp/lib";

export class BulkActionDialog extends FormMixin {
  constructor({ baseUrl, noun, width = 500, ...config }) {
    const name = "dialog";
    super({ baseUrl, ...config, name });
    this.instantiate({ noun, width });

    this.add(
      modalAddon({
        modalName: `${config.module}Dialog`,
        modalKey: `${config.module}Dialog`,
        open({ state, commit }, items) {
          commit(this.keys.reset);
          state.items = items;
        },
        async close({ commit }) {
          await wait(500);
          commit(this.keys.reset);
        },
      })
    );

    this.add({
      keys: ["confirm"],
      state: {
        items: [],
      },
      actions: {
        async confirm({ state, dispatch, commit }, dataArg = {}) {
          const data = isEmpty(dataArg)
            ? { ids: state.items.map(x => x.id) }
            : dataArg;
          try {
            const { response, errors } = await dispatch(this.keys.submit, {
              method: "PUT",
              data,
            });
            if (response && response.status < 300) {
              if (typeof config.successMessage === "function")
                eventbus.$emit("snackAlert", {
                  message: config.successMessage(data),
                  color: "success",
                });
              else if (typeof config.successMessage === "string")
                eventbus.$emit("snackAlert", {
                  message: config.successMessage,
                  color: "success",
                });

              await wait(1500);

              // reload the table
              dispatch(this.parent.p.a.fetch, null, { root: true });
              // clear table selection
              commit(this.parent.p.m.resetSelected, null, { root: true });

              dispatch(this.p.a.modalClose, { success: true }, { root: true });

              eventbus.$emit("bulkActionSuccess");

              return true;
            } else {
              console.log(errors);
              eventbus.$emit("snackAlert", {
                message: "Request Failed",
                color: "error",
              });
            }
          } catch (errors) {
            console.log(errors);
            eventbus.$emit("snackAlert", {
              message: "Request Failed",
              color: "error",
            });
          }
        },
      },
    });
  }
}
