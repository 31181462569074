import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"color":"#e7eaf4"}},[_c(VCardTitle,{staticClass:"pa-1 d-flex align-center"},[_c('span',{staticClass:"mr-auto"},[_vm._v("Current Version")]),(!_vm.editingRecord)?_c(VBtn,{attrs:{"loading":_vm.file.saving,"color":"primary","rounded":"","small":"","depressed":""},on:{"click":_vm.rejectChanges}},[_vm._v(" Keep Current Version ")]):_vm._e()],1),_c(VDivider),(_vm.item.records.differences.includes('last_archived_at'))?_c('div',{staticClass:"text-center mt-1"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.pluralize(_vm.item.changeable_type, 1))+" is")]),_c(VChip,{attrs:{"color":_vm.item.pending_action === 'delete' ? 'success' : 'error'}},[_vm._v(" "+_vm._s(_vm.item.pending_action === "delete" ? "Active" : "Archived")+" ")])],1):_vm._e(),_c('div',{staticClass:"change-sheets"},_vm._l((_vm.orderedLabels),function(label,i){return _c(VSheet,{key:i,staticClass:"my-1 pa-1 py-0",class:{
        willChange: _vm.item.records.differences.includes(label),
      },attrs:{"rounded":""}},[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.snakeToTitleCase(label)))]),_c('br'),(_vm.version[label] === null || _vm.version[label] === '')?_c('span',{staticClass:"text-subtitle-2",staticStyle:{"color":"#aaaaaa"}},[_vm._v(" – ")]):_c('span',{staticClass:"text-subtitle-2"},[_vm._v(" "+_vm._s(_vm.version[label])+" ")]),(_vm.showAttributeLockButton(label))?_c('cp-icon-button',_vm._b({staticClass:"lockIcon",on:{"click":function($event){return _vm.toggleIgnore(label)}}},'cp-icon-button',_vm.lockProps(label),false)):_vm._e()],1)}),1),(!_vm.editingRecord)?_c('div',{staticClass:"pa-1 d-flex justify-end"},[_c(VBtn,{attrs:{"loading":_vm.file.saving,"color":"primary","rounded":"","small":"","depressed":""},on:{"click":_vm.rejectChanges}},[_vm._v(" Keep Current Version ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }