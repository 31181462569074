import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"color":"#e7eaf4"}},[_c(VCardTitle,{staticClass:"pa-1 d-flex align-center"},[_c('span',{staticClass:"mr-auto"},[_vm._v("Changes")]),(_vm.editingRecord)?[_c(VBtn,{attrs:{"loading":_vm.file.saving,"color":"white","rounded":"","small":""},on:{"click":_vm.cancelEditRecord}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"color":"success","rounded":"","small":"","depressed":"","loading":_vm.file.saving},on:{"click":_vm.update}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" Save Changes ")],1)]:[_c(VBtn,{attrs:{"loading":_vm.file.saving,"color":"white","rounded":"","small":"","depressed":""},on:{"click":_vm.editRecord}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Edit ")],1),_c(VBtn,{attrs:{"loading":_vm.file.saving,"color":"success","rounded":"","small":"","depressed":""},on:{"click":_vm.acceptChanges}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-check")]),_vm._v(" Approve Changes ")],1)]],2),_c(VDivider),(_vm.item.records.differences.includes('last_archived_at'))?_c('div',{staticClass:"text-center mt-1"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.pluralize(_vm.item.changeable_type, 1))+" will be")]),_c(VChip,{attrs:{"color":_vm.item.pending_action !== 'delete' ? 'success' : 'error'}},[_vm._v(" "+_vm._s(_vm.item.pending_action !== "delete" ? "Active" : "Archived")+" ")])],1):_vm._e(),(_vm.editingRecord)?_c('div',{staticClass:"ModelForm"},[_c('model-form',{attrs:{"modelModule":_vm.modelModule,"filter-fields":_vm.item.editable_fields,"cols":{ md: 12 },"fieldProps":{ hideDetails: 'auto' },"mode":"import"}})],1):_c('div',{staticClass:"change-sheets"},_vm._l((_vm.orderedLabels),function(label,i){return _c(VSheet,{key:i,staticClass:"my-1 pa-1 py-0",class:{
        ignored: _vm.showAttributeIgnored(label),
        willChange: _vm.item.records.differences.includes(label),
      },attrs:{"rounded":""}},[(_vm.showAttributeIgnored(label))?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"frozenIcon",attrs:{"x-large":""}},'v-icon',attrs,false),on),[_vm._v("mdi-sync-off")])]}}],null,true)},[_c('span',[_vm._v(" This attribute will be ignored in all future imports of this record ")])]):_vm._e(),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.snakeToTitleCase(label)))]),_c('br'),(_vm.checkForRemoved(label))?_c('span',{staticClass:"text-subtitle-2"},[_vm._v(" (removed) ")]):(_vm.version[label] === null || _vm.version[label] === '')?_c('span',{staticClass:"text-subtitle-2",staticStyle:{"color":"#aaaaaa"}},[_vm._v(" – ")]):_c('span',{staticClass:"text-subtitle-2"},[_vm._v(" "+_vm._s(_vm.version[label])+" ")])],1)}),1),_c('div',{staticClass:"pa-1 d-flex justify-end"},[(_vm.editingRecord)?[_c(VBtn,{attrs:{"loading":_vm.file.saving,"color":"white","rounded":"","small":""},on:{"click":_vm.cancelEditRecord}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"color":"success","rounded":"","small":"","depressed":"","loading":_vm.file.saving},on:{"click":_vm.update}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" Save Changes ")],1)]:[_c(VBtn,{attrs:{"loading":_vm.file.saving,"color":"white","rounded":"","small":"","depressed":""},on:{"click":_vm.editRecord}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Edit ")],1),_c(VBtn,{attrs:{"loading":_vm.file.saving,"color":"success","rounded":"","small":"","depressed":""},on:{"click":_vm.acceptChanges}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-check")]),_vm._v(" Approve Changes ")],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }