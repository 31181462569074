import Crud from "./generic";
let base = new Crud("position_category", "position_categories");

const state = {
    ...base.state,
};
const getters = {
    ...base.getters,
};
const actions = {
    ...base.actions,
    fetchPositionCategories({ commit, state }) {
        return state.client.authorize()
            .get(`/position_categories`)
            .then(data => {
                commit("setItems", data);
            });
    },
};
const mutations = {
    ...base.mutations,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
