<template>
  <div>
    <cp-h1>
      Areas

      <template #subtitle>
        <span v-if="selected.length">
          Selected: {{ selected.length }}/{{ meta.pagination.records }}
        </span>
        <span v-else>Total: {{ meta.pagination.records }}</span>
      </template>

      <template #rightOfTitle>
        <bulk-actions-menu
          v-if="selected.length"
          v-bind="bulkActionsMenuProps"
        />
        <v-btn v-else :to="{ name: 'addArea' }" color="primary" small>
          <v-icon left>mdi-plus</v-icon>
          Add Area
        </v-btn>
      </template>

      <template #right>
        <cp-filters-menu :module="table" />
      </template>
    </cp-h1>

    <v-card>
      <Table
        v-bind="tableProps"
        v-on="tableListeners"
        v-model="tableSelected"
        class="clickable-rows"
        @click:row="
          ({ id }) => $router.push({ name: 'areaDetail', params: { id } })
        "
      >
      </Table>
    </v-card>

    <bulk-action-modals v-bind="bulkActionsMenuProps" />
  </div>
</template>

<script>
import onWindowFocus from "@cp/mixins/onWindowFocus";

import { area } from "@/store/modules/area";

export default {
  mixins: [
    area.table.mixin,
    onWindowFocus(function() {
      this.fetchItems();
    }),
  ],
  beforeRouteEnter(to, from, next) {
    next(vm => vm.refreshTable());
  },
};
</script>
