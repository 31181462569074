import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.selected.length && _vm.bulkActions.length)?_c(VMenu,{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-btn',Object.assign({}, attrs, _vm.$attrs),false),on),[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-chevron-down")]),_vm._v(" Bulk Actions ( "+_vm._s(_vm.selected.length)+" selected ) ")],1)]}}],null,false,4024047516)},[_c(VList,{attrs:{"dense":""}},_vm._l((_vm.applicableBulkActions),function(action,i){return _c(VListItem,{key:("action-" + i),on:{"click":function($event){return _vm.openBAModal(action.subModule)}}},[_vm._v(" "+_vm._s(_vm.capitalize(_vm.actionLabel(action)))+" ")])}),1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }