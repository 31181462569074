import store from "@cp/store/appStore";
import { HaystackSearch, Mixin } from "@cp/store/mixins";

class Search extends Mixin {
  register(urlKey, config = {}) {
    if (this.subModuleRefs.hasOwnProperty(urlKey))
      return this.subModuleRefs[urlKey];

    const subModule = new HaystackSearch({
      parent: this,
      module: urlKey,
      name: urlKey,
      urlKey,
      ...config,
    });

    return subModule;
  }

  init() {
    store.registerModule(this.modulePath, this.toVuex);
    for (const subModule of Object.values(this.subModuleRefs)) {
      store.registerModule(subModule.modulePath, subModule.toVuex);
    }
  }
}
const search = new Search({ module: "search" });

window.$search = search;

export default search;
