<template>
  <div>
    <v-card-title class="TableTop">
      <template v-if="items.length">
        <v-btn @click="confirmRejectAll" color="error" :loading="submitting">
          <v-icon class="mr-2">mdi-cancel</v-icon>
          Reject all {{ pluralize("Change", items.length, true) }}
        </v-btn>
        <v-btn
          @click="confirmApproveAll"
          color="success"
          class="ml-5"
          :loading="submitting"
        >
          <v-icon class="mr-2">mdi-check-all</v-icon>
          Approve all {{ pluralize("Change", items.length, true) }}
        </v-btn>
      </template>

      <v-text-field
        v-model="search"
        class="ml-auto flex-grow-0"
        prepend-inner-icon="mdi-magnify"
        placeholder="Search"
        outlined
        dense
        hide-details
        clearable
      />

      <v-menu>
        <template #activator="{on, attrs}">
          <v-btn class="ml-4" color="primary" outlined v-on="on" v-bind="attrs">
            <v-icon left>mdi-filter</v-icon>
            Filter

            <template v-if="filteredActionIndex != null">
              ({{
                pluralize(
                  actionsObj[actions[filteredActionIndex]].title,
                  actionCounts[actions[filteredActionIndex]],
                  true
                )
              }})
            </template>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-if="filteredActionIndex != null"
            @click="filteredActionIndex = null"
          >
            <v-list-item-icon>
              <v-icon>mdi-close</v-icon>
            </v-list-item-icon>
            <v-list-item-content>Clear Filter</v-list-item-content>
          </v-list-item>
          <v-list-item-group v-model="filteredActionIndex">
            <v-list-item v-for="action in actions" :key="action">
              <v-list-item-icon>
                <v-icon :color="actionsObj[action].color">{{
                  actionsObj[action].icon
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content
                >Show
                {{
                  pluralize(
                    actionsObj[action].title,
                    actionCounts[action],
                    true
                  )
                }}</v-list-item-content
              >
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-data-table
      :items="items"
      :headers="headers"
      :search="search"
      :page="options.page"
      :items-per-page.sync="options.itemsPerPage"
      :sort-by.sync="options.sortBy"
      :sort-desc.sync="options.sortDesc"
      :custom-filter="filterByAction"
      @current-items="x => (visibleItems = x)"
      @click:row="x => openModal({ id: x.id })"
      class="clickable-rows"
      hide-default-footer
    >
      <template #header.approved>
        <v-btn-toggle :value="allMatch">
          <v-btn
            @click.stop="setAllVisible(0)"
            small
            :color="allRejected ? 'error' : ''"
            :class="{ 'mar-toggle-active': allRejected }"
          >
            <v-icon left>mdi-cancel</v-icon>
            Reject All
          </v-btn>
          <v-btn
            @click.stop="setAllVisible(1)"
            small
            :color="allApproved ? 'success' : ''"
            :class="{ 'mar-toggle-active': allApproved }"
          >
            <v-icon left>mdi-check</v-icon>
            Accept All
          </v-btn>
        </v-btn-toggle>
      </template>
      <template #item.action="{value}">
        <action-chip :action="value" />
      </template>
      <template #item.approved="{item }">
        <v-btn-toggle v-model="item.approved">
          <v-btn
            fab
            x-small
            :color="item.approved == 0 ? 'error' : ''"
            :class="{ 'mar-toggle-active': item.approved === 0 }"
            @click.stop=""
          >
            <v-icon>mdi-cancel</v-icon>
          </v-btn>
          <v-btn
            fab
            x-small
            :color="item.approved == 1 ? 'success' : ''"
            :class="{ 'mar-toggle-active': item.approved === 1 }"
            @click.stop=""
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </v-btn-toggle>
      </template>
    </v-data-table>
    <v-data-footer
      :options.sync="options"
      :pagination="{
        page: options.page,
        itemsPerPage: options.itemsPerPage,
        itemsLength: items.length,
        pageStart: (options.page - 1) * options.itemsPerPage,
        pageStop:
          (options.page - 1) * options.itemsPerPage + visibleItems.length,
        pageCount: Math.ceil(items.length / options.itemsPerPage),
      }"
      fixed-header
    />

    <div class="d-flex justify-end pa-4">
      <v-chip
        class="mr-4"
        :disabled="approvals.length <= 0"
        color="green lighten-4"
      >
        {{ approvals.length }} Will Be Approved
      </v-chip>
      <v-chip
        class="mr-4"
        :disabled="rejections.length <= 0"
        color="red lighten-4"
      >
        {{ rejections.length }} Will Be Rejected
      </v-chip>
      <v-btn
        color="primary"
        @click="submit"
        :disabled="!approvals.length && !rejections.length"
        :loading="submitting"
      >
        Save and Submit
      </v-btn>
    </div>

    <!-- <pending-change-dialog /> -->
    <review-pending-change />
  </div>
</template>

<script>
import pluralize from "pluralize";
import { mapGetters, mapState, mapActions } from "vuex";

import {
  pendingChange,
  pendingChangesTable,
  actions,
  actionsObj,
} from "@/store/modules/pendingChange";
export default {
  mixins: [pendingChange.modalMixin],
  data() {
    return {
      actionsObj,
      search: "",
      filteredActionIndex: null,
      visibleItems: [],
      options: {
        page: 1,
        itemsPerPage: 15,
        sortBy: "action",
        sortDesc: false,
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
    };
  },
  computed: {
    ...mapState({
      file: state => state.file,
    }),
    ...mapState(pendingChangesTable.mp, [
      "initialLoad",
      "items",
      "columns",
      "submitting",
      "import",
    ]),
    ...mapState(pendingChangesTable.mp, { loading: "itemsLoading" }),
    ...mapGetters(pendingChangesTable.mp, ["actionCounts"]),
    headers() {
      const beHeaders = this.columns.map(
        x => Object.entries(x).map(([value, text]) => ({ value, text }))[0]
      );

      beHeaders[0].filter = (value, search, item) => {
        if (this.filteredActionIndex === null) return true;
        return item.action === this.actions[this.filteredActionIndex];
      };

      return [
        ...beHeaders,
        {
          text: "",
          value: "approved",
          sortable: false,
          class: "text-center",
          cellClass: "text-center",
          width: 290,
        },
      ];
    },
    actions() {
      return actions.filter(a => this.actionCounts[a]);
    },
    actionTitles() {
      return this.actions.map(a =>
        pluralize(actionsObj[a].title, this.actionCounts[a], true)
      );
    },
    changeSummaryHtml() {
      const innerLis = this.actionTitles.join("</li><li>");
      return `<ul><li>${innerLis}</li></ul>`;
    },

    approvals() {
      return this.items.filter(x => x.approved == 1);
    },
    rejections() {
      return this.items.filter(x => x.approved == 0);
    },

    // filtered items approved state:
    allMatch() {
      if (!this.visibleItems[0]) return null;
      const value = this.visibleItems[0].approved;
      if (value === null) return null;
      if (this.visibleItems.every(x => x.approved === value)) return value;
      return null;
    },
    allRejected() {
      return this.visibleItems.every(x => x.approved === 0);
    },
    allApproved() {
      return this.visibleItems.every(x => x.approved === 1);
    },
  },

  methods: {
    pluralize,
    ...mapActions("confirm", ["confirm"]),
    ...mapActions(pendingChangesTable.mp, ["refetchItems", "submit"]),

    confirmApproveAll() {
      this.confirm({
        verb: "approve",
        noun: "proposed changes",
        determiner: "these",
        appendText: `This will approve: ${this.changeSummaryHtml}`,
        action: "file/approveAllChanges",
        actionData: this.import.id,
      }).then(() => {
        this.$store.dispatch(pendingChangesTable.p.a.modalClose);
      });
    },

    confirmRejectAll() {
      this.confirm({
        verb: "reject",
        noun: "proposed changes",
        determiner: "these",
        appendText: `This will reject: ${this.changeSummaryHtml}`,
        action: "file/rejectAllChanges",
        actionData: this.import.id,
      }).then(() => {
        this.$store.dispatch(pendingChangesTable.p.a.modalClose);
      });
    },

    setAllVisible(value) {
      this.visibleItems.forEach(x => {
        x.approved = value;
      });
    },

    filterByAction(...args) {
      console.log(args);
      return true;
    },
  },

  watch: {
    "file.processing"() {
      if (!this.file.processing) {
        this.refetchItems();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.TableTop {
  display: flex;
  align-items: stretch;

  .v-btn {
    height: auto;
  }
}

.v-data-table::v-deep {
  max-height: calc(89vh - 48px - 72px - 58px - 68px);
  overflow: auto;

  .text-center {
    text-align: center !important;
  }
}

.mar-toggle-active::v-deep .v-btn__content {
  color: $white !important;

  .v-icon {
    color: $white !important;
  }
}
</style>
