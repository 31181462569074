<template>
  <v-card color="#e7eaf4">
    <v-card-title class="pa-1 d-flex align-center">
      <span class="mr-auto">Current Version</span>
      <v-btn
        v-if="!editingRecord"
        @click="rejectChanges"
        :loading="file.saving"
        color="primary"
        rounded
        small
        depressed
      >
        Keep Current Version
      </v-btn>
    </v-card-title>

    <v-divider />

    <div
      v-if="item.records.differences.includes('last_archived_at')"
      class="text-center mt-1"
    >
      <span class="mr-2">{{ pluralize(item.changeable_type, 1) }} is</span>
      <v-chip :color="item.pending_action === 'delete' ? 'success' : 'error'">
        {{ item.pending_action === "delete" ? "Active" : "Archived" }}
      </v-chip>
    </div>

    <div class="change-sheets">
      <v-sheet
        v-for="(label, i) in orderedLabels"
        :key="i"
        rounded
        class="my-1 pa-1 py-0"
        :class="{
          willChange: item.records.differences.includes(label),
        }"
      >
        <span class="text-caption">{{ snakeToTitleCase(label) }}</span>
        <br />
        <span
          v-if="version[label] === null || version[label] === ''"
          class="text-subtitle-2"
          style="color: #aaaaaa;"
        >
          –
        </span>
        <span v-else class="text-subtitle-2">
          {{ version[label] }}
        </span>
        <cp-icon-button
          v-if="showAttributeLockButton(label)"
          class="lockIcon"
          v-bind="lockProps(label)"
          @click="toggleIgnore(label)"
        />
      </v-sheet>
    </div>

    <div v-if="!editingRecord" class="pa-1 d-flex justify-end">
      <v-btn
        @click="rejectChanges"
        :loading="file.saving"
        color="primary"
        rounded
        small
        depressed
      >
        Keep Current Version
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import pluralize from "pluralize";
import { mapState, mapGetters, mapActions } from "vuex";

import { toggle } from "@cp/utils/arrayUtils";
import { get } from "@cp/utils/objectUtils";
import { snakeToTitleCase } from "@cp/utils/stringUtils";

import { pendingChange, etlForm } from "@/store/modules/pendingChange";
import orderedLabels from "@/mixins/pendingChangeOrderedLabels";

export default {
  computed: {
    ...mapState({
      file: state => state.file,
    }),
    ...mapState(pendingChange.mp, ["item", "editingRecord"]),
    ...mapState(etlForm.mp, ["etl"]),
    ...mapGetters(pendingChange.mp, { modelModule: "polyModule" }),
    version() {
      return this.item.records.current_version;
    },
    orderedLabels,
    showStatusChange() {
      return ["delete", "restore", ""].includes(this.item.pending_action);
    },
  },

  methods: {
    pluralize,
    snakeToTitleCase,
    ...mapActions(pendingChange.mp, ["acceptChanges", "rejectChanges"]),

    showAttributeLockButton(label) {
      // can only lock when the user is adding an ignore_attributes ETL
      if (this.etl.transform_type !== "ignore_attributes") return false;

      const lockableAttributes = get(
        this.item,
        "transformation_options.details.ignore_attributes.ignorable",
        []
      );
      // allow them to lock attributes in
      return lockableAttributes.includes(label);
    },

    lockProps(label) {
      const isLocked = this.etl.transformations.ignore_attributes.includes(
        label
      );

      return {
        icon: isLocked ? "mdi-lock" : "mdi-lock-open-variant",
        color: isLocked ? "primary" : "gray",
        iconBtn: false,
        fab: true,
        "x-small": true,
      };
    },

    toggleIgnore(label) {
      toggle(this.etl.transformations.ignore_attributes, label);
    },
  },
};
</script>

<style scoped lang="scss">
.v-sheet {
  position: relative;

  &.willChange {
    background: #ffecb3;
  }

  &.ignored {
    background: #ddd;
  }
}

.lockIcon {
  position: absolute;
  top: 50%;
  transform: translate(75%, -50%);
  right: 0;
}

.change-sheets {
  padding: 2px;
}
</style>
