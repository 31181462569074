<template>
  <div>
    <h4 class="text-h6 font-weight-bold mb-10">
      Currently mapping
      <v-chip class="ml-2">
        <v-icon left>mdi-table-large</v-icon>
        {{ file.instance.upload_details.sheet_name }}
      </v-chip>
    </h4>

    <v-row dense class="text-subtitle-1 font-weight-bold">
      <v-col cols="3" class="text-right">Swift Bunny Field</v-col>
      <v-col cols="2"></v-col>
      <v-col cols="7">Your Column Name</v-col>
    </v-row>
    <v-divider />
    <field-map-line-item
      v-for="mapping in file.instance.mapping.system_columns.filter(
        x => x.required
      )"
      :key="mapping.id"
      :mapping="mapping"
    />
    <field-map-line-item
      v-for="mapping in file.instance.mapping.system_columns.filter(
        x => !x.required
      )"
      :key="mapping.id"
      :mapping="mapping"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      file: state => state.file,
    }),
  },
};
</script>
