<template>
  <span>{{ item.location || "-" }}</span>
</template>

<script>
export default {
  name: "FullNameCell",
  props: ["item"],
};
</script>
