<template>
  <div>
    <cp-h1>
      <span v-if="inProgress">Import In Progress:</span>
      <span v-else>Import New Data</span>

      <template #subtitle>
        <v-chip v-if="inProgress" small>
          <v-icon left small>mdi-file-table-outline</v-icon>
          {{ $store.getters["file/uploadFilename"] }}
        </v-chip>
      </template>

      <template #rightOfTitle>
        <v-btn
          v-if="inProgress"
          @click="resetImport"
          :loading="file.canceling"
          color="error"
          text
        >
          <v-icon left>mdi-close</v-icon>
          Cancel Import
        </v-btn>
      </template>

      <template #right v-if="userIsSwiftbunny">
        <div class="d-flex align-center">
          <v-btn :href="templateUrl" color="success">
            <v-icon left>mdi-microsoft-excel</v-icon>
            Downnload our Excel template
          </v-btn>
          <v-btn
            :to="{ name: 'importHistory' }"
            color="primary"
            class="ml-3"
            outlined
          >
            <v-icon left>mdi-history</v-icon>
            Import History
          </v-btn>
        </div>
      </template>
    </cp-h1>
    <cp-card dense>
      <template v-if="file.collection.length">
        <template v-if="userIsSwiftbunny">
          <import-preview-dialog v-if="file.importStep == 1" hide-activator />
          <field-map-dialog v-if="file.importStep == 2" />
          <import-review-dialog v-if="file.importStep == 3" />

          <v-stepper v-model="file.importStep" class="elevation-0">
            <v-stepper-header class="elevation-0">
              <v-stepper-step step="1">
                Set data types to import
              </v-stepper-step>
              <v-divider />
              <v-stepper-step step="2">
                Map fields from your spreadsheet to the system
              </v-stepper-step>
              <v-divider />
              <v-stepper-step step="3">
                Review imported data
              </v-stepper-step>
            </v-stepper-header>

            <v-container>
              <h5 class="text-subtitle-1 font-weight-bold pb-4">
                <template v-if="file.importStep == 1">
                  We found
                  {{ file.collection.length }}
                  {{ "sheet" | pluralize(file.collection.length) }} in the
                  uploaded file. First, let us know the type of data we'll be
                  bringing in by selecting from the dropdown menu.
                </template>

                <template v-if="file.importStep == 2">
                  Next, we need to know how we'll be mapping the fields from
                  your spreadsheet into the Data Manager. Click "Edit Field
                  Mapping" to get started.
                </template>

                <template v-if="file.importStep == 3">
                  Finally, as your uploaded data is processed, you'll be able to
                  review and approve or reject the proposed changes to your
                  data.
                </template>
              </h5>

              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  v-for="job in file.collection"
                  :key="job.id"
                >
                  <job-status-summary-card :job="job" />
                </v-col>
              </v-row>

              <v-row>
                <v-col align="left">
                  <v-btn
                    v-if="file.importStep >= 2"
                    rounded
                    @click="
                      file.importStep--;
                      $store.dispatch('file/fetchImportStatus', 1000);
                    "
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                    Previous
                  </v-btn>
                </v-col>
                <v-spacer />
                <v-col align="right">
                  <v-btn
                    v-if="file.importStep < 3"
                    rounded
                    color="primary"
                    :disabled="!$store.getters['file/canAdvance']"
                    @click="advanceButtonClick"
                  >
                    {{ $store.getters["file/advanceButtonLabel"] }}
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-stepper>
        </template>

        <template v-else>
          <p class="text-center text-h6 my-10">
            <span class="d-inline-block">
              <cp-icon-stack
                :icons="[
                  ['mdi-arrow-up-bold', { xLarge: true }],
                  ['mdi-check-circle', { large: true }],
                ]"
                addonColor="success"
              />
            </span>
            Your upload will be processed by a Swift Bunny associate shortly.
          </p>
        </template>
      </template>

      <file-upload v-else />
    </cp-card>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

import { toMDY } from "@cp/utils/dateUtils";

const templateUrl =
  "https://storage.googleapis.com/assets-swiftbunny/public-assets/app-marigold/Swift-Bunny-Data-Template.xlsx";

export default {
  data() {
    return { templateUrl };
  },
  computed: {
    ...mapState({
      file: state => state.file,
      inProgress: state => state.file.collection.length,
    }),
    ...mapGetters("auth", ["userIsSwiftbunny"]),
  },

  mounted() {
    this.$store.dispatch("file/fetchImportStatus", 1000).then(() => {
      this.file.importStep = this.$store.getters["file/highestImportStep"];
      this.checkStatus();
    });

    this.$CpEvent.$on("mapFieldsUpdated", () => {
      this.file.fieldMappingDialogOpen = false;
      this.$store.dispatch("file/fetchImportStatus");
    });
  },

  methods: {
    toMDY,
    ...mapActions("confirm", ["confirm"]),
    advanceButtonClick() {
      this.file.importStep++;
      this.checkStatus();
    },
    checkStatus() {
      if (this.file.importStep != 3) {
        this.$store.dispatch("file/fetchImportStatus", 1000);
      } else {
        this.$store
          .dispatch(
            "file/fetchReviewData",
            this.$store.getters["file/currentJob"]
          )
          .then(() => {
            setTimeout(
              () => this.$store.dispatch("file/fetchImportStatus", 1000),
              1000
            );
          });
      }
    },
    resetImport() {
      this.confirm({
        verb: "abandon",
        noun: "import",
        appendText: "Any unsaved changes will be lost.",
        action: "file/resetImport",
      });
    },
  },

  beforeDestroy() {
    this.$CpEvent.$off("mapFieldsUpdated");
  },
};
</script>
