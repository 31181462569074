<template>
  <main>
    <cp-app :navItems="navItems" :mobileBreakpoint="1023" />
  </main>
</template>

<script>
// const sbSite = process.env.VUE_APP_SITE_PATH;

const navItems = [
  { text: "Dashboard", cpIcon: "dashboard", to: "/", exact: true },
  {
    text: "Employees",
    icon: "account-supervisor-circle",
    to: "/employees",
  },
  { text: "Locations", icon: "map-marker-radius", to: "/locations" },
  { text: "Positions", icon: "account-star", to: "/positions" },
  {
    text: "Company",
    icon: "office-building",
    children: [
      {
        text: "Corporate",
        to: "/company/corporate",
      },
      {
        text: "Areas",
        to: "/company/areas",
      },
      {
        text: "Regions",
        to: "/company/regions",
      },
      {
        text: "Reporting Groups",
        to: "/company/reporting-groups",
      },
    ],
    roleRequired: "swiftbunny-admin",
  },
  {
    text: "Units",
    icon: "home-city",
    to: "/units",
    serviceRequired: "intouch",
    roleRequired: "swiftbunny-admin",
  },
  {
    text: "Leases",
    cpIcon: "file-sign",
    to: "/leases",
    serviceRequired: "intouch",
    roleRequired: "swiftbunny-admin",
  },
  {
    text: "Residents",
    icon: "account-group",
    to: "/residents",
    serviceRequired: "intouch",
    roleRequired: "swiftbunny-admin",
  },
  {
    text: "Import",
    icon: "application-import",
    status: "mini-progress-display",
    to: "/import/current",
  },
  {
    text: "Data Triage",
    icon: "bandage",
    to: "/data-triage",
    roleRequired: "swiftbunny-admin",
  },
  {
    text: "Import History",
    icon: "history",
    to: "/import/history",
    roleRequired: "swiftbunny-admin",
  },
  {
    text: "Audit Log",
    icon: "database-edit",
    to: "/audits",
    roleRequired: "swiftbunny-admin",
  },
  {
    text: "Transforms",
    icon: "database-arrow-right",
    to: "/transforms",
    roleRequired: "swiftbunny-admin",
  },
];

// TODO rebuild documentation page somewhere?
// const profileItems = [
//   {
//     text: "Documentation",
//     to: `${sbSite}/support/documentation`,
//     icon: "chart-tree",
//     target: "_blank",
//   },
// ];

export default {
  name: "Marigold",
  data() {
    return {
      navItems,
    };
  },
};
</script>
