<template>
  <v-container>
    <v-row dense class="mb-5">
      <v-col v-for="({ item, ...props }, i) in singles" :key="i" cols="6">
        <model-card :item="item" v-bind="props" />
      </v-col>
    </v-row>

    <v-expansion-panels v-if="lists.length" multiple accordion>
      <v-expansion-panel v-for="({ title, ...props }, i) in lists" :key="i">
        <v-expansion-panel-header>
          <h3>{{ props.items.length }} {{ title }}</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <relationship-list v-bind="props" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import {
  snakeToTitleCase,
  capitalize,
  pluckCompactAndConcat
} from "carrot-patch-v2/src/utils/stringUtils";
import { attrOrFn, mapAttrOrFn } from "@/utils/transforms";

const hasProperty = (data, key) => {
  if (!data.hasOwnProperty(key)) return false;
  if (Array.isArray(data[key]) && data[key].length > 0) return true;
  return data[key].id;
};

const keys = [
  {
    key: "location",
    title: "name",
    attrs: ["unit_count", "email", "phone", "address", "city", "state"]
  },
  {
    key: "unit",
    title: pluckCompactAndConcat([
      "building_name",
      "floor_number",
      "unit_number"
    ]),
    attrs: [
      "occupants",
      "unit_type",
      "bedrooms",
      "bathrooms",
      "square_feet",
      "max_occupants",
      "max_pets"
    ]
  },
  {
    key: "primary_resident",
    title: pluckCompactAndConcat(["first_name", "last_name"]),
    routeName: "Residents",
    attrs: [
      "primary_email",
      "secondary_email",
      "phone_number",
      "opt_out",
      "locale"
    ]
  },
  {
    key: "lease",
    title: ({ unit_number, lease_to_date }) =>
      `${unit_number} (ends ${lease_to_date})`,
    attrs: [
      "external_id",
      "lease_signed_date",
      "lease_from_date",
      "lease_to_date",
      "move_in_date",
      "move_out_date"
    ]
  }
];
export default {
  props: { model: { type: Object, required: true } },
  computed: {
    singles() {
      return keys
        .filter(({ key }) => hasProperty(this.model.item, key))
        .map(({ key, title, routeName, attrs }) => ({
          attrs,
          item: {
            ...this.model.item[key],
            title: `${snakeToTitleCase(key)}: ${attrOrFn(
              this.model.item[key],
              title
            )}`
          },
          route: { name: routeName || capitalize(key + "s") }
        }));
    },
    lists() {
      return keys
        .map(keyMap => ({ ...keyMap, key: keyMap.key + "s" }))
        .filter(({ key }) => hasProperty(this.model.item, key))
        .map(({ key, title, routeName, attrs }) => ({
          attrs,
          title: snakeToTitleCase(key),
          route: { name: routeName || capitalize(key) },
          items: this.model.item[key].map(mapAttrOrFn("title", title))
        }));
    }
  },
  methods: {
    snakeToTitleCase
  }
};
</script>
