import { ApiDataMixin } from "@cp/store/mixins";
import { slugify } from "@cp/utils/stringUtils";
import pluralize from "pluralize";

const baseUrl = `${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1/dashboard`;

export const dashboard = new ApiDataMixin({
  baseUrl,
  name: "cards",
  initialValue: [],
  jsonApiResponse: false,
  getters: {
    cards({ cards }) {
      return cards.map(x => ({
        ...x,
        model: slugify(x.title),
        viewRoute: { name: x.title },
        addRoute: { name: `add${pluralize(x.title, 1)}` },
      }));
    },
  },
});

export default dashboard.toVuex;
