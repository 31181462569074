import { eventbus } from "@cp/lib";
import Crud from "./generic";
import Vue from "vue";
import { keyBy, partition } from "@cp/utils/arrayUtils";
import { snakeToTitleCase } from "@cp/utils/stringUtils";
import m from "@cp/utils/mustache";

let base = new Crud("file", "files", "imports", "import");

export const topics = [
  {
    title: "Additions",
    slug: "adds",
  },
  {
    title: "Changes",
    slug: "changes",
  },
  {
    title: "Errors",
    slug: "errors",
  },
  {
    title: "Archive",
    slug: "terminations",
  },
];
export const topicTitles = topics.reduce((r, { slug, title }) => {
  r[slug] = title;
  return r;
}, {});
const topicSlugs = topics.map(({ slug }) => slug);

export const transforms = [
  {
    text: "Ignore Record",
    transform_type: "ignore_record",
    editable: false,
    description: "When this record is imported, ignore it",
    use_case:
      "If your system is exporting conflicting data, you can use this to ignore the record in future imports.",
    applied_text: () => "Record Ignored",
    applied_description: () =>
      "When this record is imported, it will be ignored.",
  },
  // {
  //   text: "Merge Record",
  //   transform_type: "merge_record",
  //   description:
  //     "If your system exports multiple versions of the same record, you can use this to merge them into a single record.",
  //   use_case: ""
  // },
  {
    text: "Ignore Attribute",
    transform_type: "ignore_attributes",
    editable: true,
    description: "When this record is imported, ignore the selected attributes",
    use_case:
      "If you've changed the data locally you can use this to prevent your system from overwriting it. Also if you are exporting data from multiple sources, you can use this to select which data to import.",
    applied_text: m(
      "{{transformations.ignore_attributes.length}} Attributes Ignored"
    ),
    applied_description: ({ transformations }) =>
      `The following attributes are being ignored on all imports: ${transformations.ignore_attributes
        .map(
          t =>
            `<span class="ma-1 v-chip v-chip--no-color theme--light v-size--default">${snakeToTitleCase(
              t
            )}</span>`
        )
        .join("")}`,
  },
  // {
  //   text: "Prevent Events",
  //   transform_type: "prevent_events",
  //   description:
  //     "When this record is imported, do not trigger emails to be sent",
  //   use_case:
  //     "When this record gets imported or updated, do not trigger emails or other events."
  // }
];
export const transformsByType = keyBy(transforms, "transform_type");

const instanceDefaults = () => {
  return {
    upload_details: {
      sheet_name: null,
      spawned_from: null,
    },
    load_type: "",
    mapping: {
      sample: {
        headers: [],
        rows: [],
      },
      system_columns: [],
      fieldMap: {
        name: "",
      },
    },
  };
};

const reviewDataDefaults = () => {
  return {
    adds: [],
    errors: [],
    terminations: [],
    changes: [],
    columns: [],
  };
};

const pendingChangeDefaults = () => {
  return {
    pending_errors: {},
    records: {
      new_version: {},
      current_version: {},
      differences: {},
    },
  };
};

const etlFormDefaults = () => ({
  formMode: "",
  transform_type: "",
  transformations: {},
  uuid_load_type: "",
  uuid_load_id: "",
});

const importSteps = [
  "processing",
  "un-mapped",
  "mapped",
  "pre-processed",
  "completed",
  "cancelled",
];

const state = {
  ...base.state,
  instance: instanceDefaults(),
  collection: [],
  statusPollTimeout: null,
  pendingChange: pendingChangeDefaults(),
  reviewData: reviewDataDefaults(),
  fieldMapping: {},
  systemColumns: [],
  importTypes: ["users", "locations", "regions", "positions"],
  importNameDict: {
    users: "employee",
    locations: "location",
    regions: "region",
    positions: "position",
    units: "unit",
    residents: "resident",
    leases: "lease",
    prospects: "prospect",
    move_outs: "move_out",
    move_ins: "move_in",
    completed_work_orders: "completed_work_order",
    managers: "employee",
  },
  importTypeMap: [
    { text: "Employee", value: "users" },
    { text: "Position", value: "positions" },
    { text: "Region", value: "regions" },
    { text: "Location", value: "locations" },
    { text: "Unit", value: "units" },
    { text: "Resident", value: "residents" },
    { text: "Lease", value: "leases" },
    { text: "Prospect", value: "prospects" },
    { text: "Move Out", value: "move_outs" },
    { text: "Move In", value: "move_ins" },
    { text: "Completed Work Order", value: "completed_work_orders" },
  ],
  importType: "",
  etlFormState: etlFormDefaults(),
  selectedImportTypes: {},
  importStep: 1,
  viewPendingChangeDialog: false,
  importPreviewDialogOpen: false,
  fieldMappingDialogOpen: false,
  importReviewDialogOpen: false,
  confirmApproveAllDialogOpen: false,
  confirmRejectAllDialogOpen: false,
  activeImportMapping: {},
  processing: false,
  canceling: false,
  importReviewTab: 0,
};

const getters = {
  ...base.getters,
  jwt(state) {
    return state.client.authorize().axios.defaults.headers.common[
      "Authorization"
    ];
  },

  uploadFilename(state) {
    if (state.collection.length <= 0) return "";
    return state.collection[0].upload_details.name;
  },

  currentJob(state) {
    return (
      state.collection.find(x => {
        const steps = [x.step, ...x.spawned_processes.map(y => y.step)];
        return steps.some(y => !["completed", "cancelled"].includes(y));
      }) || {}
    );
  },

  currentJobBlockedBySpawnedJobs(state, getters) {
    return (
      getters.currentJob.spawned_processes.filter(
        x =>
          !["completed", "cancelled"].includes(x.step) &&
          x.load_order < getters.currentJob.load_order
      ).length > 0
    );
  },

  canAdvance(state) {
    let activeJobs = state.collection.filter(x => x.step != "cancelled");

    switch (state.importStep) {
      case 1:
        return activeJobs.filter(x => x.load_type == null).length <= 0;
      case 2:
        let noUnmapped =
          activeJobs.filter(x => x.step == "un-mapped").length <= 0 || [];
        let activeMapped = activeJobs.filter(x => x.step == "mapped") || [];
        let allRequiredFieldsMapped = true;

        for (var i = 0; i < activeMapped.length; i++) {
          let job = activeMapped[i];

          if (!job.mappings) {
            allRequiredFieldsMapped = false;
            break;
          }

          let required = job.required_mappings.map(x => x.value);
          let mapped = job.mappings.map(x => Object.keys(x)).flat();
          let requiredMappingsPresent = required.every(x => mapped.includes(x));

          if (!requiredMappingsPresent) {
            allRequiredFieldsMapped = false;
            break;
          }
        }

        return noUnmapped && allRequiredFieldsMapped;
    }
  },

  advanceButtonLabel(state, getters) {
    switch (state.importStep) {
      case 1:
        return getters.canAdvance
          ? "Continue"
          : "Finish Selecting Data Types to Continue";
      case 2:
        return getters.canAdvance
          ? "Begin Import"
          : "Finish Mapping Fields to Continue";
    }
  },

  fieldMapAsObject(state) {
    let fields = {};
    state.instance.mapping.import_map.forEach(mapping => {
      Object.assign(fields, mapping);
    });

    return fields;
  },

  fieldMapAsArray(state) {
    return Object.keys(state.instance.mapping.fieldMap)
      .map(x => ({
        [x]: state.instance.mapping.fieldMap[x],
      }))
      .filter(x => x[Object.keys(x)[0]] != null);
  },

  highestImportStep(state) {
    if (state.collection.length <= 0) return 1;

    let statuses = state.collection.map(x => x.step);
    let allTypesSelected =
      state.collection.filter(x => x.load_type == null && x.step != "cancelled")
        .length <= 0;

    if (
      statuses.includes("pre-processed") ||
      statuses.includes("processing") ||
      statuses.includes("completed")
    ) {
      return 3;
    }

    if (statuses.includes("un-mapped")) {
      return allTypesSelected ? 2 : 1;
    }

    if (statuses.includes("mapped")) {
      return 2;
    }

    return 1;
  },

  reviewDataCounts() {
    return topicSlugs.reduce((r, k) => {
      r[k] = state.reviewData[k].length;
      return r;
    }, {});
  },

  reviewDataPresent(state, { reviewDataCounts }) {
    return topicSlugs.reduce((r, k) => {
      r += reviewDataCounts[k];
      return r;
    }, 0);
  },

  fieldMappingOptions(state) {
    let usedFields = state.instance.mapping.fieldMap;
    let availableFields = state.instance.mapping.sample.headers;

    availableFields.forEach(field => {
      let status = Object.values(usedFields).includes(field.label);
      field.disabled = status;
    });

    return availableFields;
  },
};

const actions = {
  ...base.actions,
  fetchImportStatus({ state, commit, dispatch }, interval = 5000) {
    return state.client
      .authorize()
      .get(`/imports/status`)
      .then(data => {
        const [parent_processes, spawned_processes] = partition(
          data.imports,
          x => !x.upload_details.spawned_from || x.is_followup_task
        );
        parent_processes.forEach(proc => (proc.spawned_processes = []));

        // CRUFT managers are on the employee sheet, so mark the job as "spawned task"
        data.imports
          .filter(x => x.load_type === "managers")
          .forEach(proc => {
            proc.jobType = "Followup Task";
          });

        spawned_processes.forEach(proc => {
          const parent = parent_processes.find(
            x => x.id == proc.upload_details.spawned_from
          );
          parent.spawned_processes.push(proc);
        });

        // Store the previous value of collection.length
        // we need this to determine if we just completed the export
        const prevCollectionLength = state.collection.length;
        const prevParents = state.collection;

        commit("setCollection", parent_processes);

        if (state.collection.length <= 0) {
          clearTimeout(state.statusPollTimeout);
          commit("resetInstance");
          state.importStep = 1;

          // if there were items before this update, that means we just finished an import
          if (prevCollectionLength && !state.canceling) {
            eventbus.$emit("snackAlert", {
              message: "Import complete!",
            });
            const id = prevParents[0].parent_upload_id;
            eventbus.$emit("redirect", {
              name: "importSummary",
              params: { id },
            });
          }
          return;
        }

        if (
          state.collection.filter(x => x.step == "processing").length > 0 ||
          spawned_processes.filter(x => x.step == "processing").length > 0
        ) {
          state.processing = true;
          clearTimeout(state.statusPollTimeout);
          state.statusPollTimeout = setTimeout(() => {
            dispatch("fetchImportStatus", interval);
          }, interval);
        } else {
          state.processing = false;
        }
      });
  },

  fetchFieldMapDetails({ commit, state, getters }, job) {
    state.loading = true;
    Object.assign(state.instance, job);

    return state.client
      .authorize()
      .get(`/imports/mappings/${job.id}`)
      .then(data => {
        commit("setInstanceMapping", data);
        commit("setInstanceFieldMap", getters.fieldMapAsObject);
      })
      .finally(() => {
        state.loading = false;
      });
  },

  fetchReviewData({ state, commit, dispatch, getters }, job) {
    state.loading = true;
    Object.assign(state.instance, job);

    return state.client
      .authorize()
      .get(`/imports/reviews/${job.id}`)
      .then(data => {
        commit("setReviewData", data);

        if (!getters.reviewDataPresent) {
          state.importReviewDialogOpen = false;
        }
      })
      .finally(() => {
        state.loading = false;
      });
  },

  cancelJob({ state, dispatch }, id) {
    return state.client
      .authorize()
      .put(`/imports/${id}`, { step: 5 })
      .then(data => {
        eventbus.$emit("snackAlert", { message: "Import cancelled" });
        dispatch("fetchImportStatus", 1000);
      });
  },

  openPendingChangeDialog({ commit }, pendingChange) {
    return state.client
      .authorize()
      .get(`/imports/pending_changes/${pendingChange.id}`)
      .then(data => {
        if (data.pending_errors == null) {
          data.pending_errors = {};
        }
        commit("setPendingChange", data);
        commit("resetEtlForm", data);
        commit("setViewPendingChangeDialog", true);
      });
  },

  updateImportTypeFields({ state, dispatch }, { job, importType }) {
    return state.client
      .authorize()
      .put(`/imports/mappings/${job.id}`, { type: importType })
      .then(data => {
        dispatch("fetchFieldMapDetails", job).then(() => {
          dispatch("fetchImportStatus", 1000);
        });
      });
  },

  submitFieldMapping({ state, dispatch }, { job, fieldMapping, loadType }) {
    state.saving = true;

    return state.client
      .authorize()
      .put(`/imports/mappings/${job.id}`, {
        type: loadType,
        mappings: fieldMapping,
      })
      .then(() => {
        eventbus.$emit("mapFieldsUpdated");
        eventbus.$emit("snackAlert", {
          message: "Field mappings updated",
        });
      })
      .finally(() => {
        state.saving = false;
      });
  },

  resetImport({ state, dispatch, commit }) {
    state.canceling = true;
    return state.client
      .authorize()
      .post(`/imports/clear_imports`)
      .then(data => {
        commit("resetInstance");
        state.importStep = 1;
        eventbus.$emit("snackAlert", {
          message: "Import reset",
        });
        dispatch("fetchImportStatus", 1000).then(
          () => (state.canceling = false)
        );
      });
  },

  resetMapping({ state }) {
    return state.client.authorize().post(`/imports/mappings/clear_mappings`);
  },

  rejectPendingChange({ state, commit }, { ids, importId }) {
    if (ids.length <= 0) return;

    state.saving = true;

    return state.client
      .authorize()
      .put(`/imports/delete_changes/${importId}`, { ids: ids })
      .then(data => {
        commit("setReviewData", data);
        eventbus.$emit("pendingChangesSaved");
        eventbus.$emit("snackAlert", {
          message: "Current version kept",
        });
        return data;
      })
      .finally(() => {
        state.saving = false;
      });
  },

  approvePendingChange({ state, commit, dispatch }, { ids, importId }) {
    if (ids.length <= 0) return;

    state.saving = true;

    return state.client
      .authorize()
      .put(`/imports/commit_changes/${importId}`, { ids: ids })
      .then(data => {
        if (data.deferred) {
          dispatch("fetchImportStatus", 1000);
        } else {
          commit("setReviewData", data);
          eventbus.$emit("pendingChangesSaved");
          eventbus.$emit("snackAlert", {
            message: "Accepted changes for new version",
          });
        }
        return data;
      })
      .finally(() => {
        state.saving = false;
      });
  },

  approveAllChanges({ state, dispatch, getters }, importId) {
    state.saving = true;

    return state.client
      .authorize()
      .put(`/imports/commit_changes/${importId}`, { ids: ["all"] })
      .then(data => {
        dispatch("fetchImportStatus", 1000);
        return data;
      })
      .finally(() => {
        state.saving = false;
      });
  },

  rejectAllChanges({ state, dispatch, getters }, importId) {
    state.saving = true;

    return state.client
      .authorize()
      .put(`/imports/delete_changes/${importId}`, { ids: ["all"] })
      .then(data => {
        dispatch("fetchImportStatus", 1000);
        return data;
      })
      .finally(() => {
        state.saving = false;
      });
  },

  updatePendingChange(
    { state, commit, dispatch, getters },
    { pendingChangeId, properties }
  ) {
    delete properties.approved;

    return state.client
      .authorize()
      .put(`/imports/pending_changes/${pendingChangeId}/edit`, { properties })
      .then(data => {
        commit("setPendingChange", data);
        dispatch("fetchReviewData", state.collection[0]);
      });
  },

  refreshPendingChangeDialog({ state, commit, dispatch }) {
    // commit("setViewPendingChangeDialog", false); // TODO - remove if not necessary
    dispatch("openPendingChangeDialog", state.pendingChange);
  },

  submitEtlForm({ state, dispatch }) {
    const { formMode, ...data } = state.etlFormState;
    const isEditing = formMode === "edit";
    return state.client
      .authorize()
      .request({
        method: isEditing ? "PUT" : "POST",
        url: isEditing ? `/etl_maps/${state.etlFormState.id}` : "/etl_maps",
        data,
        responseType: "json",
      })
      .then(() => {
        dispatch("refreshPendingChangeDialog");
      });
  },

  editTransform({ state }, transform) {
    Object.assign(state.etlFormState, transform);
    state.etlFormState.formMode = "edit";
  },

  deleteTransform({ state, dispatch }, transform_id) {
    return state.client
      .authorize()
      .delete(`etl_maps/${transform_id}`)
      .then(() => {
        dispatch("refreshPendingChangeDialog");
      });
  },
};

const mutations = {
  ...base.mutations,
  setInstance(state, instance) {
    state.instance = instance;
  },

  setCollection(state, collection) {
    state.collection = collection;
  },

  setPendingChange(state, data) {
    state.pendingChange = data;
  },

  resetInstance(state) {
    state.instance = instanceDefaults();
  },

  resetCollection(state) {
    state.collection = [];
  },

  resetReviewInstance(state) {
    state.importReviewDialogOpen = false;
    state.confirmApproveAllDialogOpen = false;
    state.confirmRejectAllDialogOpen = false;
    state.viewPendingChangeDialog = false;
    state.reviewData = reviewDataDefaults();
  },

  resetPendingChange(state) {
    state.pendingChange = pendingChangeDefaults();
  },

  setCurrentStatus(state, data) {
    state.currentStatus = data;
  },

  setFieldMapping(state, data) {
    state.fieldMapping = data;
  },

  setReviewData(state, data) {
    topicSlugs.forEach(slug => {
      if (!data[slug]) return;
      data[slug].forEach(item => {
        item.approved = null;
      });
    });
    Object.assign(state.reviewData, data);
  },

  setSystemColumns(state, columns) {
    Vue.set(state, "systemColumns", columns);
  },

  setViewPendingChangeDialog(state, isOpen = true) {
    state.viewPendingChangeDialog = isOpen;
  },

  setInstanceMapping(state, mapping) {
    Object.assign(state.instance.mapping, mapping);
  },

  setInstanceFieldMap(state, fieldMap) {
    state.instance.mapping.fieldMap = fieldMap;
  },

  resetEtlForm(state, { changeable_type }) {
    state.etlFormState = etlFormDefaults();
    state.etlFormState.extract_type = changeable_type;
  },

  openCreateTransformForm(state, transform_type) {
    state.etlFormState.transform_type = transform_type;
    state.etlFormState.formMode = "create";
    switch (transform_type) {
      case "ignore_attributes":
        state.etlFormState.transformations = { ignore_attributes: [] };
        break;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
