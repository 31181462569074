import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h6',{staticClass:"text-h6 text--secondary mb-3"},[_vm._v(" "+_vm._s(_vm.modelModule.nounTitle)+" Permissions ")]),_c(VRow,{attrs:{"dense":""}},_vm._l((_vm.services),function(service,i){return _c(VCol,{key:i,staticClass:"mb-2",attrs:{"cols":"12","md":"4"}},[_c(VSheet,{staticClass:"text-uppercase text-bold pa-1 px-2 mb-1",attrs:{"color":"#f1f1f1","rounded":""}},[_vm._v(" "+_vm._s(service)+" ")]),(service === 'Engagement System')?_c(VSelect,{attrs:{"items":_vm.engageOptions,"value":_vm.selectedEngageRole},on:{"input":_vm.changeEngageRole},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('strong',{staticClass:"mr-3"},[_vm._v(_vm._s(item.text))]),_c('small',{staticClass:"text-caption text--secondary"},[_vm._v("( "+_vm._s(item.description)+" )")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',[_vm._v(_vm._s(item.text))]),_c('h4',{staticClass:"text-caption text--secondary mb-1"},[_vm._v(" "+_vm._s(item.description)+" ")])])]}}],null,true)}):_vm._l((_vm.serviceRoles[service]),function(role){return [_c(VCheckbox,{key:("checkbox-" + (role.value)),staticClass:"mt-0",attrs:{"value":role.id,"label":role.name,"multiple":"","hide-details":"","dense":""},model:{value:(_vm.selectedRoleIds),callback:function ($$v) {_vm.selectedRoleIds=$$v},expression:"selectedRoleIds"}}),_c('h4',{key:("description-" + (role.value)),staticClass:"text-caption text--secondary mb-1 ml-8"},[_vm._v(" "+_vm._s(role.description || "No description text")+" ")])]})],2)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }