<template>
  <v-row align="center" dense class="my-1">
    <v-col
      cols="3"
      align="right"
      :class="{ 'font-weight-bold': mapping.required }"
    >
      {{ mapping.label }}
      <span v-if="mapping.required" class="error--text">*</span>
    </v-col>
    <v-col cols="2" align="center">
      <v-icon
        v-if="file.instance.mapping.fieldMap[mapping.value]"
        color="success"
        >mdi-check</v-icon
      >
      <v-icon v-else color="#dddddd">mdi-arrow-right</v-icon>
    </v-col>
    <v-col cols="7">
      <v-select
        v-model="file.instance.mapping.fieldMap[mapping.value]"
        :items="$store.getters['file/fieldMappingOptions']"
        :errorMessages="errorMessages"
        :hint="sample"
        :persistent-hint="sample"
        item-value="label"
        item-text="label"
        clearable
        class="pt-1"
      >
        <template #item="{item}">
          <div style="line-height: 1;">
            <p class="mb-0">{{ item.label }}</p>
            <span
              class="text-caption"
              :class="{ 'text--disabled': item.disabled }"
            >
              {{ getSampleForDropdown(item.label) }}
            </span>
          </div>
        </template>
      </v-select>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    mapping: { type: Object, default: () => {} },
  },

  computed: {
    ...mapState({
      file: state => state.file,
    }),

    errorMessages() {
      return (
        this.mapping.required &&
        !this.file.instance.mapping.fieldMap[this.mapping.value] &&
        "required"
      );
    },

    sample() {
      let firstSampleRow = this.file.instance.mapping.sample.rows[0];
      let fieldMapOptions = this.$store.getters["file/fieldMappingOptions"];
      let mappedField = this.file.instance.mapping.fieldMap[this.mapping.value];

      if (!mappedField) return;

      let value;
      if (fieldMapOptions.find(x => x.label == mappedField)) {
        value =
          firstSampleRow[
            fieldMapOptions.find(x => x.label == mappedField).value
          ];
      }

      if (value) return `Sample from your data: "${value}"`;

      return "Could not find data for sample";
    },
  },

  methods: {
    getSampleForDropdown(fieldName) {
      let firstSampleRow = this.file.instance.mapping.sample.rows[0];
      let fieldMapOptions = this.$store.getters["file/fieldMappingOptions"];

      let value;
      if (fieldMapOptions.find(x => x.label == fieldName)) {
        value =
          firstSampleRow[fieldMapOptions.find(x => x.label == fieldName).value];
      }

      if (value) return `Sample: "${value}"`;

      return "Could not find data for sample";
    },
  },
};
</script>

<style lang="scss" scoped>
.mar-field-sample {
  transition: opacity 0.25s ease-in-out;
  height: 20px;
  opacity: 0;

  &.mar-field-sample--showing {
    opacity: 1;
  }
}
</style>
