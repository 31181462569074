<template>
  <div>
    <p>Assign all selected locations to this region</p>
    <v-autocomplete
      v-model="model.bulkAssignRegionTarget"
      label="Region"
      :items="options"
      :loading="model.searching"
      :allow-overflow="false"
      no-data-text="Type to search"
      cache-items
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    model: { type: Object, default: () => {} }
  },

  computed: mapState("region", ["options"]),

  mounted() {
    if (!this.options.length) {
      this.$store.dispatch("region/fetchOptions");
    }
  }
};
</script>
