<template>
  <v-card
    flat
    class="mar-import-summary fill-height pa-2"
    :class="{
      'mar-import-summary--ready': stepCompleted,
      'mar-import-summary--cancelled': job.step == 'cancelled',
      'mar-import-summary--issue':
        !stepCompleted && job.upload_details.messages != null,
    }"
  >
    <v-icon color="success" class="mar-import-summary__icon">
      mdi-check-circle
    </v-icon>

    <v-tooltip bottom v-if="showMessages">
      <template #activator="{ on, attrs }">
        <v-icon
          color="warning"
          class="mar-import-summary__icon--issue"
          v-bind="attrs"
          v-on="on"
        >
          mdi-alert
        </v-icon>
      </template>
      <h4 v-for="(message, i) in job.upload_details.messages" :key="i">
        {{ message }}
      </h4>
    </v-tooltip>

    <p class="mb-0 text-subtitle-1 font-weight-bold">
      {{ job.is_followup_task ? "Task" : "Sheet" }}:
      {{ job.upload_details.sheet_name }}
      <cp-menu
        v-if="!['cancelled', 'completed', 'processing'].includes(job.step)"
        left
        :options="jobMenuOptions(job)"
      >
        <template #activator="{ on, attrs }">
          <cp-btn class="ml-0" icon v-bind="attrs" v-on="on" small>
            <v-icon>mdi-dots-vertical</v-icon>
          </cp-btn>
        </template>
      </cp-menu>
    </p>

    <div v-if="job.step == 'cancelled'" class="text-center">
      <v-icon size="64px">mdi-close-circle-outline</v-icon>
      <h5 class="text-subtitle-2">CANCELLED</h5>
    </div>

    <template v-else>
      Status: {{ job.step | capitalize }}

      <set-data-type v-if="file.importStep == 1" :job="job" />
      <map-fields v-if="file.importStep == 2" :job="job" />
      <process-uploads v-if="file.importStep == 3" :job="job" />
    </template>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { get } from "@cp/utils/collectionUtils";

export default {
  props: {
    job: { type: Object, default: () => {} },
  },

  computed: {
    ...mapState({
      file: state => state.file,
    }),
    ...mapGetters("auth", ["userIsSwiftbunny"]),

    showMessages() {
      const messages =
        get(this.job, "upload_details.messages.length", []) || [];
      return messages.length && !this.stepCompleted;
    },

    stepCompleted() {
      switch (this.file.importStep) {
        case 1:
          return this.job.load_type != null;
        case 2:
          let statusValid = !["processing", "un-mapped"].includes(
            this.job.step
          );

          if (!statusValid || !this.job.mappings) return false;

          let required = this.job.required_mappings.map(x => x.value);
          let mapped = this.job.mappings.map(x => Object.keys(x)).flat();
          let requiredMappingsPresent = required.every(x => mapped.includes(x));

          if (!requiredMappingsPresent) {
            this.job.upload_details.messages = [
              "Not all required fields have been mapped",
            ];
          }

          return requiredMappingsPresent;
        case 3:
          return this.job.step == "completed";
        default:
          return false;
      }
    },

    jobCompleted() {
      // TODO - can we make this a util?
      const steps = [
        this.job.step,
        ...this.job.spawned_processes.map(y => y.step),
      ];

      return (
        this.file.importStep == 3 &&
        steps.every(y => ["completed", "cancelled"].includes(y))
      );
    },
  },

  methods: {
    jobMenuOptions(job) {
      const options = [];
      if (this.userIsSwiftbunny)
        options.push({
          title: `Download File`,
          href: `${job.upload_details.file}`,
          type: `link`,
        });
      options.push({
        title: "Cancel Sheet: " + job.upload_details.sheet_name,
        type: "storeAction",
        action: "file/cancelJob",
        data: job.id,
      });
      return options;
    },
  },

  watch: {
    jobCompleted() {
      if (this.jobCompleted === true) {
        this.$store
          .dispatch(
            "file/fetchReviewData",
            this.$store.getters["file/currentJob"]
          )
          .then(() => {
            this.$store.dispatch("file/fetchImportStatus", 1000);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mar-import-summary {
  transition: background-color 0.25s ease-in-out;
  background-color: $gray-1;

  .mar-import-summary__icon,
  .mar-import-summary__icon--issue {
    transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
    transform: translateY(4px);
    opacity: 0;
    position: absolute;
    top: 8px;
    right: 8px;
  }

  &.mar-import-summary--ready {
    background-color: rgba($success, 0.25);

    .mar-import-summary__icon {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &.mar-import-summary--cancelled {
    background-color: rgba($error, 0.25);

    .mar-import-summary__icon {
      display: none;
    }
  }

  &.mar-import-summary--issue {
    .mar-import-summary__icon--issue {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
</style>
