import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cp-card',{attrs:{"dense":"","color":"#f7f7f7"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm._f("snakeToTitleCase")(_vm.version))+" ")]},proxy:true},(!_vm.editingRecord)?{key:"actions",fn:function(){return [(_vm.version == 'new_version')?_c(VBtn,{attrs:{"color":"grey lighten-2","rounded":"","small":"","depressed":"","loading":_vm.file.saving},on:{"click":function($event){_vm.editingRecord = true}}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-pencil")]),_vm._v(" Edit ")],1):_vm._e(),_c(VBtn,{attrs:{"color":_vm.version == 'new_version' ? 'success' : 'primary',"rounded":"","small":"","depressed":"","loading":_vm.file.saving},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.version == "new_version" ? "Accept" : "Keep")+" "+_vm._s(_vm._f("snakeToTitleCase")(_vm.version))+" ")])]},proxy:true}:{key:"actions",fn:function(){return [_c(VBtn,{attrs:{"color":"error","rounded":"","small":"","text":"","loading":_vm.file.saving},on:{"click":function($event){_vm.editingRecord = false}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"color":"success","rounded":"","small":"","depressed":"","loading":_vm.file.saving},on:{"click":_vm.updateRecord}},[_vm._v(" Save Changes ")])]},proxy:true}],null,true)},[(_vm.editingRecord)?[_c('model-form',{attrs:{"modelModule":_vm.modelModule,"mode":"import","cols":{ md: 12 }}})]:_vm._l((_vm.labels),function(label,i){return _c(VSheet,{key:i,staticClass:"my-1 pa-1 py-0",class:{
        ignored: _vm.showAttributeIgnored(label),
        willChange:
          _vm.version == 'new_version' &&
          _vm.item.records.differences.includes(label),
      },attrs:{"rounded":""}},[(_vm.showAttributeIgnored(label))?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"frozenIcon",attrs:{"x-large":""}},'v-icon',attrs,false),on),[_vm._v("mdi-sync-off")])]}}],null,true)},[_c('span',[_vm._v(" This attribute will be ignored in all future imports of this record ")])]):_vm._e(),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm._f("snakeToTitleCase")(label)))]),_c('br'),(_vm.checkForRemoved(label))?_c('span',{staticClass:"text-subtitle-2"},[_vm._v(" (removed) ")]):(
          _vm.item.records[_vm.version][label] === null ||
            _vm.item.records[_vm.version][label] === ''
        )?_c('span',{staticClass:"text-subtitle-2",staticStyle:{"color":"#aaaaaa"}},[_vm._v(" – ")]):_c('span',{staticClass:"text-subtitle-2"},[_vm._v(" "+_vm._s(_vm.item.records[_vm.version][label])+" ")]),(_vm.showAttributeLock(label))?_c('cp-icon-button',_vm._b({staticClass:"lockIcon",on:{"click":function($event){return _vm.toggleIgnore(label)}}},'cp-icon-button',_vm.lockProps(label),false)):_vm._e()],1)}),_c(VRow,{staticClass:"pt-2"},[_c(VCol,{attrs:{"align":"right"}},[(!_vm.editingRecord)?_c(VBtn,{attrs:{"color":_vm.version == 'new_version' ? 'success' : 'primary',"rounded":"","small":"","depressed":"","loading":_vm.file.saving},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.version == "new_version" ? "Accept" : "Keep")+" "+_vm._s(_vm._f("snakeToTitleCase")(_vm.version))+" ")]):_vm._e()],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }