import { Table } from "@cp/store/mixins";
import { modalAddon } from "@cp/store/addons";
import { required } from "@cp/utils/rules";
import { get } from "@cp/utils/objectUtils";
import { wait } from "@cp/utils/promiseUtils";

import GegraphicalGroupModel from "../mixins/GeographicalGroupModel";
import search from "@/store/modules/search";

const regionSearch = search.register("regions");

export const region = new GegraphicalGroupModel({
  module: "region",
  table: {
    headers: [
      {
        text: "Area",
        value: "area",
        width: "auto",
      },
      {
        text: "Locations",
        value: "locations.length",
        width: "auto",
      },
    ],
    bulkActions: [
      {
        name: "assign_areas",
        label: "Bulk assign area",
        explanation: "Assign a new area to these regions:",
        fields: {
          area_id: { rules: [required] },
        },
        modalComponent: "bluk-set-regions-area",
        successMessage: "Regions updated!",
      },
      {
        name: "assign_managers",
        label: "Bulk assign manager",
        explanation: "Assign a new manager to these regions:",
        fields: {
          manager_ids: { rules: [required], multiple: true },
          update_method: {
            label: "Remove previous assignments",
            initialValue: "append",
          },
        },
        modalComponent: "bluk-set-regions-manager",
        successMessage: "Regional managers updated!",
      },
    ],
  },
  addFields: ["group_manager_ids", "area_id"],
  search: regionSearch,
  detailTabs: ["general", "locations", "history"],
});

export const assignItems = new Table({
  module: "region",
  baseUrl: region.table.client.axios.defaults.baseURL,
  headers: region.table.headers,
  name: "assignItems",
  tableOptions: {
    page: { size: 10 },
  },
  filters: [
    "q_text",
    {
      key: "general",
      type: "checkbox",
      initialValue: "missing_area",
    },
    {
      key: "not_ids",
      type: "select",
      multiple: true,
      initialValue: [],
    },
  ],
  filtersOptions: {
    excludeKeysFromMenu: ["general"],
  },
});
assignItems.add(
  modalAddon({
    modalName: "assignRegionsDialog",
    modalKey: "assignRegionsDialog",
    open({ state, dispatch, rootState }, module) {
      const filterParams = state[assignItems.keys.filterParams];
      const item = get(rootState, module.form.p.s.stateKey);
      filterParams.not_ids = item.regions.map(x => x.id);
      filterParams.general = "missing_area";

      return dispatch(assignItems.keys.fetch);
    },
    async close({ commit }) {
      await wait(500);
      commit(this.keys.reset);
    },
  })
);

region.add(assignItems);

window.$region = region;

export default region.toVuex;
