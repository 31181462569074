<template>
  <cp-chip small class="text-capitalize" :color="statusColor(map[statusId])">
    {{ map[statusId] }}
  </cp-chip>
</template>

<script>
export default {
  props: {
    map: { type: Object, default: () => ({}) },
    statusId: { type: Number, required: true }
  },
  methods: {
    statusColor(status) {
      switch (status) {
        case "active":
          return "success";
        case "archived":
          return "error";
      }
    }
  }
};
</script>
