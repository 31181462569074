<template>
  <div>
    <v-btn @click="openModal(modelModule)" color="primary">
      <v-icon class="mr-1">mdi-plus</v-icon>Assign New Locations
    </v-btn>

    <cp-toolbar-module-modal :modalConf="modalConf" width="900" root-slot>
      <template #title>Assign Locations</template>

      <v-card>
        <v-card-text class="d-flex">
          <cp-filters-menu :module="table" hide-filters />
          <v-checkbox
            v-if="modelModule.mp !== 'reporting_group'"
            v-model="showAllLocations"
            :label="`Show locations that are already assigned`"
            @change="toggleUnassigned"
            hide-details
            dense
          />
        </v-card-text>

        <Table
          v-bind="tableProps"
          v-on="tableListeners"
          :headers="tableHeaders"
          v-model="tableSelected"
          show-select
        />

        <v-card-text>
          <div class="d-flex justify-end mt-6">
            <div v-if="success" class="d-flex align-center">
              <CpSuccessIcon class="mr-4" xLarge />
              {{ "Locations assigned!" }}
            </div>
            <template v-else>
              <v-btn @click="closeModal" class="mr-4">Cancel</v-btn>
              <v-btn @click="assign" color="primary" :loading="submitting">
                Assign Locations
              </v-btn>
            </template>
          </div>
        </v-card-text>
      </v-card>
    </cp-toolbar-module-modal>

    <div class="text-subtitle-2 pa-4">
      {{ item.locations.length }} Locations:
    </div>

    <v-card
      v-for="(location, i) in item.locations"
      :key="i"
      elevation="4"
      class="my-2 mx-4"
    >
      <v-card-title :set="(type = locationType(location))">
        <v-icon color="primary" :title="type" class="mr-4">{{
          locationTypeIcon(location)
        }}</v-icon>
        <span>{{ location.name }}</span>
        <v-btn
          color="primary"
          outlined
          small
          :to="{
            name: 'locationDetail',
            params: { id: location.id },
          }"
          class="ml-2"
        >
          View
          <v-icon small right>mdi-open-in-new</v-icon>
        </v-btn>
        <v-btn
          color="error"
          outlined
          small
          @click="remove(location.id)"
          :loading="removing"
          class="ml-auto"
        >
          <v-icon small left>mdi-trash-can-outline</v-icon>
          Remove
        </v-btn>
      </v-card-title>
    </v-card>

    <div class="py-2"></div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import { capitalize } from "@cp/utils/stringUtils";
import { get } from "@cp/utils/objectUtils";

import { assignItems, location } from "@/store/modules/location";

const SUB = location.table.subModuleRefs.bulkAssignRegion;
const locationForm = location.form;
const dataKeyMap = {
  corporate: "region_id",
  area: "area_id",
  region: "region_id",
  reporting_group: "group_ids",
};

export default {
  mixins: [assignItems.mixin, assignItems.filtersMixin, assignItems.modalMixin],
  props: { modelModule: { type: Object, required: true } },
  data() {
    return {
      showAllLocations: false,
    };
  },
  computed: {
    item() {
      return get(this.$store.state, this.modelModule.form.p.s.stateKey);
    },
    success() {
      return get(this.$store.state, SUB.p.s.success);
    },
    submitting() {
      return get(this.$store.state, SUB.p.s.submitting);
    },
    removing() {
      const removeSubmitting = get(
        this.$store.state,
        locationForm.p.s.submitting
      );
      const removeSuccess = get(this.$store.state, locationForm.p.s.success);
      return removeSubmitting || removeSuccess;
    },
    dataKey() {
      return dataKeyMap[this.modelModule.mp];
    },
    tableHeaders() {
      const typeSpecificHeaders =
        this.item.location_type_id === 1
          ? [
              {
                text: "Unit Count",
                value: "unit_count",
                width: 140,
              },
            ]
          : [
              {
                text: "Employees",
                value: "user_count",
                width: 140,
              },
            ];
      return [
        {
          text: "Type",
          value: "location_type_id",
          width: "20px",
          sortable: false,
        },
        {
          text: "Name",
          value: "name",
          linkCell: true,
          width: "auto",
        },
        {
          text: "Address",
          value: "address",
          width: "15%",
        },
        {
          text: "City",
          value: "city",
          width: "15%",
        },
        {
          text: "State",
          value: "state",
          width: "10%",
        },
        ...typeSpecificHeaders,
      ];
    },
  },

  methods: {
    capitalize,
    ...mapActions(SUB.mp, ["confirm"]),
    ...mapActions(assignItems.mp, {
      toggleFilter: assignItems.keys.toggleFilter,
    }),
    locationType(location) {
      return location.location_type_id === 1 ? "Property" : "Office";
    },
    locationTypeIcon(location) {
      return location.location_type_id === 1
        ? "mdi-home-map-marker"
        : "mdi-office-building-marker";
    },
    toggleUnassigned(value) {
      this.toggleFilter({ general: "missing_region", value: !value });
    },
    async assign() {
      const locationIds = this.selected.map(x => x.id);
      const geographical_group_ids = [this.item.id];
      const success = await this.confirm({
        locations: locationIds.map(location_id => ({
          geographical_group_ids,
          location_id,
        })),
      });
      if (success) {
        this.closeModal();
        this.refetch();
      }
    },
    async remove(id) {
      await this.$store.dispatch(locationForm.p.a.submit, {
        id,
        method: "PUT",
        data: { id, [this.dataKey]: null },
      });
      this.refetch();
      this.$store.commit(locationForm.p.m.reset);
    },
    refetch() {
      const { id } = this.$route.params;
      this.$store.dispatch(this.modelModule.form.p.a.fetch, { id });
    },
  },
};
</script>

<style scoped>
::v-deep .CpFiltersMenu {
  margin-left: 0;
  margin-right: 16px;
}
</style>
