<template>
  <div>
    <p>Assign all selected regions to this manager</p>
    <v-autocomplete
      v-model="model.bulkAssignRegionTarget"
      label="Managers"
      :items="model.locationManagers"
      :loading="model.searching"
      :allow-overflow="false"
      no-data-text="Type to search"
      :search-input.sync="search"
      cache-items
      multiple
    />

    <v-checkbox
      v-model="model.bulkAssignManagerOverride"
      label="Remove previous assignments"
    />
  </div>
</template>

<script>
export default {
  props: {
    model: { type: Object, default: () => {} }
  },

  data() {
    return {
      apiSearchTimeout: null,
      search: null
    };
  },

  watch: {
    search(newValue, oldValue) {
      if (newValue == null || newValue.trim() == "" || newValue == oldValue) {
        this.model.searching = false;
        return;
      }

      this.model.searching = true;
      clearTimeout(this.apiSearchTimeout);
      this.apiSearchTimeout = setTimeout(() => {
        this.$store.dispatch("region/fetchLocationManagers", this.search);
      }, 300);
    }
  }
};
</script>
