import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.openModal(_vm.modelModule)}}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-plus")]),_vm._v("Assign New Regions ")],1),_c('cp-toolbar-module-modal',{attrs:{"modalConf":_vm.modalConf,"width":"900","root-slot":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Assign Regions")]},proxy:true}])},[_c(VCard,[_c(VCardText,{staticClass:"d-flex"},[_c('cp-filters-menu',{attrs:{"module":_vm.table,"hide-filters":""}})],1),_c('Table',_vm._g(_vm._b({attrs:{"headers":_vm.tableHeaders,"show-select":""},model:{value:(_vm.tableSelected),callback:function ($$v) {_vm.tableSelected=$$v},expression:"tableSelected"}},'Table',_vm.tableProps,false),_vm.tableListeners)),_c(VCardText,[_c('div',{staticClass:"d-flex justify-end mt-6"},[(_vm.success)?_c('div',{staticClass:"d-flex align-center"},[_c('CpSuccessIcon',{staticClass:"mr-4",attrs:{"xLarge":""}}),_vm._v(" "+_vm._s("Regions assigned!")+" ")],1):[_c(VBtn,{staticClass:"mr-4",on:{"click":_vm.closeModal}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"primary","loading":_vm.submitting},on:{"click":_vm.assign}},[_vm._v(" Assign Regions ")])]],2)])],1)],1),_c('div',{staticClass:"text-subtitle-2 pa-4"},[_vm._v(_vm._s(_vm.item.regions.length)+" Regions:")]),_vm._l((_vm.item.regions),function(region,i){return _c(VCard,{key:i,staticClass:"my-2 mx-4",attrs:{"elevation":"4"}},[_c(VCardTitle,[_c('span',[_vm._v(_vm._s(region.name))]),_c(VBtn,{staticClass:"ml-2",attrs:{"color":"primary","outlined":"","small":"","to":{
          name: 'regionDetail',
          params: { id: region.id },
        }}},[_vm._v(" View "),_c(VIcon,{attrs:{"small":"","right":""}},[_vm._v("mdi-open-in-new")])],1),_c(VBtn,{staticClass:"ml-auto",attrs:{"color":"error","outlined":"","small":"","loading":_vm.removing},on:{"click":function($event){return _vm.remove(region.id)}}},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v("mdi-trash-can-outline")]),_vm._v(" Remove ")],1)],1)],1)}),_c('div',{staticClass:"py-2"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }