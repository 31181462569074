<template>
  <component
    v-if="header.component"
    :is="header.component"
    :header="header"
    v-bind="$attrs"
  />
</template>

<script>
export default {
  name: "ComponentCell",
  props: ["header"],
};
</script>
