<template>
  <div>
    <div class="d-flex mb-2">
      <h2
        v-if="nLinks"
        style="color: #666666"
        class="text-subtitle-1 font-weight-bold mr-auto"
      >
        Linked {{ otherType }}
      </h2>
      <span v-if="warning">
        <v-icon>{{ warning.icon }}</v-icon>
        <span v-html="warning.html"></span>
      </span>
    </div>

    <v-btn v-if="showLinkButton" @click="openModal" color="primary">
      <v-icon class="mr-1">mdi-link</v-icon>Link to {{ otherText }}
    </v-btn>
    <cp-toolbar-module-modal :modalConf="modalConf" width="900" root-slot>
      <template #title>Link to {{ otherText }}</template>

      <v-card>
        <v-card-text class="d-flex py-0">
          <cp-filters-menu :module="table" hide-filters />
          <v-checkbox
            v-model="showLinkedLocations"
            :label="`Show ${otherType.toLowerCase()} that are already linked`"
            @change="toggleUnlinked"
          />
        </v-card-text>

        <Table
          v-bind="tableProps"
          v-on="tableListeners"
          :headers="tableHeaders"
          v-model="tableSelected"
          show-select
          single-select
        />

        <v-card-text>
          <div class="d-flex justify-end mt-6">
            <div v-if="success" class="d-flex align-center">
              <CpSuccessIcon class="mr-4" xLarge />
              {{ successMessage || "Success!" }}
            </div>
            <template v-else>
              <v-btn @click="closeModal" class="mr-4">Cancel</v-btn>
              <v-btn @click="link" color="primary" :loading="submitting">
                Link Locations
              </v-btn>
            </template>
          </div>
        </v-card-text>
      </v-card>
    </cp-toolbar-module-modal>

    <v-card
      v-for="(linkedLoc, i) in item.linked_locations"
      :key="i"
      elevation="4"
      class="my-2 mx-4"
    >
      <v-card-title>
        <v-icon color="primary" :title="otherType">{{ otherTypeIcon }}</v-icon>
        <strong class="mx-2">{{ otherType | capitalize }}:</strong>
        <span>{{ linkedLoc.name }}</span>
        <v-btn
          color="primary"
          outlined
          small
          :to="{
            name: 'locationDetail',
            params: { id: linkedLoc.id },
          }"
          class="ml-2"
        >
          View
          <v-icon small right>mdi-open-in-new</v-icon>
        </v-btn>
        <v-btn
          color="error"
          outlined
          small
          @click="unlink(linkedLoc.id)"
          :loading="unlinking"
          class="ml-auto"
        >
          <v-icon class="mr-2" small>mdi-link-off</v-icon>
          Unlink
        </v-btn>
      </v-card-title>
    </v-card>

    <div class="py-2"></div>
  </div>
</template>

<script>
import pluralize from "pluralize";
import { mapActions, mapState } from "vuex";

import { get } from "@cp/utils/objectUtils";

import { linkItems, location } from "@/store/modules/location";

const SUB = location.table.subModuleRefs.bulkLink;
const linkBA = location.table.bulkActions.find(x => x.name === "link");
const unlinkSUB = location.table.subModuleRefs.bulkUnlink;
const successMessage = linkBA.successMessage;

export default {
  mixins: [linkItems.mixin, linkItems.filtersMixin, linkItems.modalMixin],
  data() {
    return {
      successMessage,
      showLinkedLocations: false,
    };
  },
  computed: {
    ...mapState("location", ["item"]),
    nLinks() {
      return (
        (this.item.linked_locations && this.item.linked_locations.length) || 0
      );
    },
    success() {
      return get(this.$store.state, SUB.p.s.success);
    },
    submitting() {
      return get(this.$store.state, SUB.p.s.submitting);
    },
    unlinking() {
      const unlinkSubmitting = get(this.$store.state, unlinkSUB.p.s.submitting);
      const unlinkSuccess = get(this.$store.state, unlinkSUB.p.s.success);
      return unlinkSubmitting || unlinkSuccess;
    },
    otherType() {
      const type = this.item.location_type_id === 1 ? "Property" : "Office";
      return pluralize(type, 2);
    },
    otherText() {
      return this.item.location_type_id === 1 ? "a property" : "an office";
    },
    otherTypeIcon() {
      return this.item.location_type_id === 1
        ? "mdi-home-map-marker"
        : "mdi-office-building-marker";
    },
    tableHeaders() {
      const typeSpecificHeaders =
        this.item.location_type_id === 1
          ? [
              {
                text: "Unit Count",
                value: "unit_count",
                width: 140,
              },
            ]
          : [
              {
                text: "Employees",
                value: "user_count",
                width: 140,
              },
            ];
      return [
        {
          text: "Type",
          value: "location_type_id",
          width: "20px",
          sortable: false,
        },
        {
          text: "Name",
          value: "name",
          linkCell: true,
          width: "auto",
        },
        {
          text: "Address",
          value: "address",
          width: "15%",
        },
        {
          text: "City",
          value: "city",
          width: "15%",
        },
        {
          text: "State",
          value: "state",
          width: "10%",
        },
        ...typeSpecificHeaders,
      ];
    },

    warning() {
      if (this.item.location_type_id === 2) {
        if (!this.nLinks)
          return {
            icon: "mdi-alert",
            html: "This property hasn't been linked to an office yet.",
          };

        return {
          icon: "mdi-information-outline",
          html: `This property is linked to an office. Changes to <strong>${this.item.linked_locations[0].name}</strong> may overwrite the fields here`,
        };
      }

      if (this.item.location_type_id === 1) {
        if (!this.nLinks) return false;

        return {
          icon: "mdi-information-outline",
          html: `This office is linked to ${pluralize(
            "property",
            this.nLinks,
            true
          )}. Changes to the office may overwrite those fields in the properties.`,
        };
      }
    },

    showLinkButton() {
      // this is until Michael does one-to-many office->propeties
      // if (this.item.location_type_id === 1)
      //   return true;

      if (!this.nLinks) return true;
    },
  },

  methods: {
    ...mapActions("location", ["fetchItem"]),
    ...mapActions(SUB.mp, ["confirm"]),
    ...mapActions(linkItems.mp, {
      toggleFilter: linkItems.keys.toggleFilter,
    }),
    toggleUnlinked(value) {
      this.toggleFilter({ general: "unlinked_view", value: !value });
    },
    async link() {
      const selectedIds = this.selected.map(x => x.id);
      const success = await this.confirm({
        ids: [this.item.id, ...selectedIds],
      });
      if (success) {
        this.closeModal();
        this.refetch();
      }
    },
    async unlink(linkedId) {
      const success = await this.$store.dispatch(unlinkSUB.p.a.confirm, {
        ids: [this.item.id, linkedId],
      });
      if (success) {
        this.$store.dispatch(unlinkSUB.p.m.reset);
        this.refetch();
      }
    },
    refetch() {
      const { id } = this.$route.params;
      this.fetchItem({ id });
    },
  },
};
</script>

<style scoped>
::v-deep .CpFiltersMenu {
  margin-left: 0;
  margin-right: 16px;
}
</style>
