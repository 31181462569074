<template>
  <div>
    <cp-h1>
      Create New Reporting Group
    </cp-h1>

    <v-card>
      <v-card-text>
        <model-form :modelModule="reportingGroup" mode="create">
          <template #buttons>
            <cp-form-modal-success-or-buttons
              v-bind="successOrButtonsProps"
              @submit="submit"
              @createAndReset="createAndReset"
            />
          </template>
        </model-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";

import { toMDY } from "@cp/utils/dateUtils";
import { findByObj } from "@cp/utils/arrayUtils";
import { capitalize } from "@cp/utils/stringUtils";
import { get, getFirst } from "@cp/utils/objectUtils";
import { wait } from "@cp/utils/promiseUtils";
import { parentRoute } from "@cp/mixins";

import { reportingGroup } from "@/store/modules/reportingGroup";

const buttonConf = {
  createAndReset: {
    action: "createAndReset",
    text: "Create, then create another",
    icon: "mdi-plus-box-multiple-outline",
  },
};

export default {
  mixins: [parentRoute],
  data() {
    return {
      reportingGroup,
    };
  },
  computed: {
    ...mapState(reportingGroup.mp, ["item"]),
    ...mapState(reportingGroup.mp, {
      touched: "itemTouched",
      loading: "itemLoading",
    }),
    ...mapGetters(reportingGroup.mp, ["tabs"]),
    hasArchiveBA() {
      return findByObj(reportingGroup.table.bulkActions, { name: "restore" });
    },
    hasRestoreBA() {
      return findByObj(reportingGroup.table.bulkActions, { name: "archive" });
    },
    success() {
      return get(this.$store.state, reportingGroup.form.p.s.success);
    },
    successOrButtonsProps() {
      const $vm = this;
      return {
        modalConf: {
          close: () =>
            $vm.parentRoute
              ? $vm.$router.push($vm.parentRoute)
              : $vm.$router.go(-1),
        },
        formConf: {
          submitting: get(
            this.$store.state,
            reportingGroup.form.p.s.submitting
          ),
          success: this.success,
        },
        buttons: ["cancel", "createAndReset", "create"],
        buttonConf,
      };
    },
  },
  methods: {
    toMDY,
    ...mapMutations(reportingGroup.mp, ["resetItem"]),
    async create() {
      const response = await this.$store.dispatch(
        reportingGroup.form.p.a.submit,
        {
          method: "POST",
        }
      );

      if (this.success) {
        const name = getFirst(response, ["data.full_name", "data.name"]);
        this.$CpEvent.$emit("snackAlert", {
          message: `${capitalize(reportingGroup.noun)} ${name} Created!`,
          color: "success",
        });
      }

      return response;
    },
    async submit() {
      const response = await this.create();

      if (!this.success) return;

      await wait(1500);
      this.$router.push({
        name: "reportingGroupDetail",
        params: { id: response.data.id },
      });
    },
    async createAndReset() {
      await this.create();

      if (!this.success) return;

      await wait(1500);
      this.$store.commit(reportingGroup.form.p.m.reset);
    },
  },
  mounted() {
    this.resetItem();
  },
};
</script>
