// import { Dashboard, DataTriage, Units, Residents, Leases } from "../views";
import Dashboard from "@/views/Dashboard";
import DataTriage from "@/views/DataTriage.vue";
import Units from "@/views/Units";
import Residents from "@/views/Residents";
import Leases from "@/views/Leases";

import employeeRoutes from "@/views/employee/routes";
import locationRoutes from "@/views/location/routes";
import positionRoutes from "@/views/position/routes";
import companyRoutes from "@/views/company/routes";
import importRoutes from "@/views/import/routes";

import { clearTableOnRouteChange } from "./hooks.js";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      title: "Dashboard",
    },
  },
  ...employeeRoutes,
  ...locationRoutes,
  ...positionRoutes,
  ...companyRoutes,
  ...importRoutes,
  {
    path: "/units/:uuid?",
    name: "Units",
    component: Units,
    meta: {
      title: "Units",
    },
    beforeEnter: clearTableOnRouteChange,
  },
  {
    path: "/residents/:uuid?",
    name: "Residents",
    component: Residents,
    meta: {
      title: "Residents",
    },
    beforeEnter: clearTableOnRouteChange,
  },
  {
    path: "/leases/:uuid?",
    name: "Leases",
    component: Leases,
    meta: {
      title: "Leases",
    },
    beforeEnter: clearTableOnRouteChange,
  },
  {
    path: "/data-triage",
    name: "DataTriage",
    component: DataTriage,
    meta: {
      title: "Data Triage",
    },
    beforeEnter: clearTableOnRouteChange,
  },
];

export default routes.map(route => {
  if (!route.props) {
    route.props = route => ({
      ...route.query,
      ...route.params,
    });
  }
  return route;
});
