import Vue from "vue";
import App from "./App.vue";
import { CarrotPatchV2, router, store, vuetify } from "@cp/install";
import "./styles/marigold-ui.scss";

import PortalVue from "portal-vue";
import VuePluralize from "vue-pluralize";
import {
  capitalize,
  snakeToTitleCase,
  kebabToTitleCase,
} from "@cp/utils/stringUtils";

Vue.use(CarrotPatchV2);
Vue.use(VuePluralize);
Vue.use(PortalVue);

Vue.filter("capitalize", capitalize);
Vue.filter("snakeToTitleCase", snakeToTitleCase);
Vue.filter("kebabToTitleCase", kebabToTitleCase);

// initialize search modules
import "@/store/dynamicModules";

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount("#app");
