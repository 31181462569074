<template>
  <div>
    <template v-if="!file.loading">
      <v-tabs
        v-model="file.importReviewTab"
        background-color="transparent"
        grow
      >
        <v-tab v-for="(topic, i) in topics" :key="i">
          {{ topic.title }}
          ({{
            file.reviewData[topic.slug]
              ? file.reviewData[topic.slug].length
              : 0
          }})
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="file.importReviewTab">
        <v-tab-item v-for="(topic, i) in topics" :key="i">
          <import-review-table :topic="topic.slug" />
        </v-tab-item>
      </v-tabs-items>
    </template>

    <v-skeleton-loader v-else type="table-row, table" />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      topics: [
        {
          title: "Additions",
          slug: "adds",
        },
        {
          title: "Changes",
          slug: "changes",
        },
        {
          title: "Errors",
          slug: "errors",
        },
        {
          title: "Archive",
          slug: "terminations",
        },
      ],
    };
  },

  computed: {
    ...mapState({
      file: state => state.file,
    }),
  },
};
</script>
