import store from "@cp/store/appStore";
import { eventbus } from "carrot-patch-v2/src/lib";

import { mergeMixins, ItemForm, ItemsTable, FormMixin } from "@cp/store/mixins";
import { modalAddon } from "@cp/store/addons";
import { get, getFirst, deepMerge } from "@cp/utils/objectUtils";
import { wait } from "@cp/utils/promiseUtils";
import { required } from "@cp/utils/rules";

import { employee } from "@/store/modules/employee";
import { location } from "@/store/modules/location";
import { position } from "@/store/modules/position";
import { region } from "@/store/modules/region";

const loadTypeModuleMap = {
  users: employee,
  managers: employee,
  locations: location,
  regions: region,
  positions: position,
};

export const actions = ["error", "create", "update", "restore", "delete"];

const create = {
  title: "Add",
  color: "success",
  icon: "mdi-plus-circle",
};
const update = {
  title: "Update",
  color: "neutral",
  icon: "mdi-pencil-circle",
};
const restore = {
  title: "Restore",
  color: "info",
  icon: "mdi-refresh-circle",
};
const archive = {
  title: "Archive",
  color: "warning",
  icon: "mdi-archive",
};
const deleteAction = {
  title: "Delete",
  color: "error",
  icon: "mdi-trash-can-outline",
};
const error = {
  title: "Error",
  color: "error",
  icon: "mdi-alert",
};
const etl = {
  title: "Override",
  color: "#4AE786",
  icon: "mdi-database-import",
};

export const actionsObj = {
  create,
  update,
  restore,
  delete: archive,
  archive,
  archived: archive,
  destroy: deleteAction,
  restored: restore,
  termination: deleteAction,
  error,
  etl,
  etls: etl,
};

class PendingChangesPolyTable extends ItemsTable {
  constructor(config) {
    super({
      ...config,
      tableOptions: {
        sort_desc: undefined,
        sort_by: undefined,
        page: undefined,
      },
    });
    this.add({
      keys: ["columns", "import", "actionCounts", "refetchItems"],
      state: {
        // stop the table fetching on render:
        [this.keys.tableHasFetched]: true,
        initialLoad: true,
        submitting: false,
        columns: [],
        import: {
          id: "",
          step: "",
          etl_id: 0,
          etl_confidence: 0,
          upload_details: {
            mime: "",
            name: "",
            headers: {},
            messages: [],
            for_client: "",
            import_ids: [],
            ip_address: "",
            sheet_name: "",
            full_signiture: "",
            uploading_user: [],
            contains_content: true,
            header_signiture: "",
            invoked_etl_rules: [],
          },
        },
      },
      getters: {
        actionCounts({ items }) {
          return items.reduce((r, i) => {
            if (r[i.action]) r[i.action] += 1;
            else r[i.action] = 1;
            return r;
          }, {});
        },
      },
      actions: {
        [this.keys.fetch]: this.fetch,
        refetchItems({ state, dispatch }) {
          const id = state.import.id;
          let closeAndContinue = false;
          if (id)
            return dispatch(this.keys.fetch, { id })
              .then(({ data }) => {
                if (!data.length) {
                  closeAndContinue = true;
                }
              })
              .catch(errors => {
                const msg = getFirst(errors, ["response.data.error"]);
                const completeMessages = [
                  "Record Found but not ready for review.",
                ];
                if (completeMessages.includes(msg)) {
                  closeAndContinue = true;
                }
              })
              .finally(async () => {
                if (closeAndContinue) {
                  eventbus.$emit("snackAlert", {
                    message: `All changes in ${state.import.upload_details.sheet_name} processed`,
                  });
                  await wait(500);
                  store.dispatch(pendingChangesTable.p.a.modalClose);
                }
              });
        },
        async submit({ state, dispatch }) {
          state.submitting = true;
          try {
            const importId = state.import.id;
            const rejectIds = state.items
              .filter(x => x.approved === 0)
              .map(x => x.id);
            const approveIds = state.items
              .filter(x => x.approved === 1)
              .map(x => x.id);
            const promises = [];
            if (rejectIds.length) {
              promises.push(
                store.dispatch("file/rejectPendingChange", {
                  ids: rejectIds,
                  importId,
                })
              );
            }
            if (approveIds.length) {
              promises.push(
                store.dispatch("file/approvePendingChange", {
                  ids: approveIds,
                  importId,
                })
              );
            }

            await Promise.all(promises);
            await dispatch("refetchItems");
          } catch (errors) {
            console.log(errors);
          } finally {
            state.submitting = false;
          }
        },
      },
    });

    this.add(
      modalAddon({
        modalName: "reviewPendingChangesDialog",
        modalKey: "reviewPendingChangesDialog",
        async open({ state, dispatch, commit }, { id }) {
          commit(this.keys.reset);
          await dispatch(this.keys.fetch, { id });
          await wait(1000);
          state.initialLoad = false;
        },
        async close({ commit, state }) {
          await wait(500);
          // re-check overall import status
          store.dispatch("file/fetchImportStatus");

          // wait for modal to close
          await wait(500);
          commit(this.keys.reset);
          // reset initialLoad
          state.initialLoad = true;
          // also close the pendingChange sub-modal
          store.dispatch(pendingChange.p.a.modalClose);
        },
      })
    );
  }

  mapData(response) {
    store.state.pendingChange.columns = get(response, "data.columns", []);
    store.state.pendingChange.import = get(response, "data.import", {});
    const items = get(response, "data.items", []) || [];
    return items.map(x => ({ ...x, approved: null }));
  }
}

export const pendingChangesTable = new PendingChangesPolyTable({
  module: "pendingChange",
  baseUrl: `${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1/imports/reviews`,
  jsonApiResponse: false,
  url: "/:id?consolidate_list=true",
  urlTemplate: true,
});

window.$pendingChangesTable = pendingChangesTable;

// PendingChangePolyModule needs access to the other store modules
// so it needs to be dynamically added after store exists.
// TODO - document this, lol
class PendingChangePolyModule extends ItemForm {
  constructor(config) {
    super(config);
    this.add(
      modalAddon({
        modalName: "form",
        async open({ dispatch, commit }, { id } = {}) {
          if (id) return dispatch("fetchItem", { id });
          else commit(this.keys.reset);
        },
        async close({ commit, getters }) {
          await wait(500);
          commit(this.keys.reset);
          const PM = getters.polyModule;
          if (PM) {
            commit(PM.form.p.m.reset, {}, { root: true });
          }
        },
      })
    );

    this.add({
      keys: [
        "polyModule",
        "update",
        "refetchItem",
        "editingRecord",
        "editRecord",
        "cancelEditRecord",
      ],
      state: {
        editingRecord: false,
      },
      getters: {
        polyModule(state, getters, rootState) {
          return loadTypeModuleMap[rootState.file.instance.load_type];
        },
      },
      mutations: {
        reset: this.reset,
      },
      actions: {
        [this.keys.fetch]: this.fetch,
        editRecord: this.editRecord,
        cancelEditRecord: this.cancelEditRecord,

        refetchItem({ state, dispatch }) {
          return dispatch(this.keys.fetch, { id: state.item.id });
        },

        async acceptChanges({ state, dispatch }) {
          const importId = state.item.import_id;
          const ids = [state.item.id];
          await store.dispatch("file/approvePendingChange", { importId, ids });
          dispatch(this.keys.modalClose);
          dispatch(pendingChangesTable.keys.refetchItems);
        },

        async rejectChanges({ state, dispatch }) {
          const importId = state.item.import_id;
          const ids = [state.item.id];
          await store.dispatch("file/rejectPendingChange", { importId, ids });
          dispatch(this.keys.modalClose);
          dispatch(pendingChangesTable.keys.refetchItems);
        },

        async update({ state, rootState, dispatch, getters }) {
          const id = state.item.id;
          const properties = get(
            rootState,
            getters.polyModule.form.p.s.formData
          );
          delete properties.approved;
          await dispatch(this.keys.submit, {
            method: "PUT",
            url: "/:id/edit",
            id,
            data: { properties },
          });

          if (state.itemSuccess) {
            dispatch(this.keys.cancelEditRecord);
            store.commit(etlForm.p.m.reset);
            await dispatch(this.keys.refetchItem);
          }
        },
      },
    });
  }

  async fetch(ctx, args) {
    const response = await super.fetch(ctx, args);

    // reset and set-up etlForm
    store.commit(etlForm.p.m.reset);
    if (response.pending_action === "create") {
      // debugger;
    } else {
      store.commit(etlForm.p.m.setTypeAndId, response.data);
    }

    const PM = ctx.getters.polyModule;
    if (PM && response.data) {
      store.commit(PM.form.p.m.reset);
      const formData = get(store.state, PM.form.p.s.formData);
      deepMerge(formData, response.data.records.new_version);
    }
    return response;
  }

  async reset(state, args) {
    super.reset(state, args);
    state.editingRecord = false;
  }

  editRecord({ state, rootState, getters }) {
    store.commit(getters.polyModule.form.p.m.reset);
    const itemData = get(rootState, getters.polyModule.form.p.s.stateKey);
    const formData = get(rootState, getters.polyModule.form.p.s.formData);
    Object.assign(itemData, state.item.records.new_version);
    Object.assign(formData, state.item.records.new_version);
    state.editingRecord = true;
  }

  cancelEditRecord({ state, getters }) {
    state.editingRecord = false;
    store.commit(getters.polyModule.form.p.m.reset);
  }
}

export const pendingChange = new PendingChangePolyModule({
  module: "pendingChange",
  baseUrl: `${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1/imports/pending_changes`,
  url: "/:id",
  urlTemplate: true,
  jsonApiResponse: false,
  initialValue: {
    id: 0,
    import_id: "",
    client_id: "",
    changeable_type: "",
    changeable_id: "",
    pending_action: "",
    status: null,
    records: {
      current_version: {},
      new_version: {},
      differences: [],
    },
    options: [],
    transformation_options: {
      import: [],
      edit: [],
      details: {
        ignore_record: {
          ignore_by: [],
        },
        ignore_attributes: {
          ignorable: [],
        },
      },
    },
    editable_fields: [],
    errors: {},
    transformations: [],
  },
});

class ETLForm extends FormMixin {
  constructor(config) {
    const initialValue = {
      id: "",
      transform_type: "",
      transformations: {},
      extract_type: "",
      extract_id: 0,
      uuid_load_type: "",
      uuid_load_id: "",
    };
    super({ ...config, initialValue, name: "etl" });
    this.add({
      keys: ["open", "delete", "submit", "setTypeAndId"],
      state: {
        open: false,
      },
      mutations: {
        setTypeAndId(state, { changeable_type, changeable_id }) {
          state.etl.extract_type = changeable_type;
          state.etl.extract_id = changeable_id;
        },
      },
      actions: {
        submit: this.submit,
        async delete(ctx, id) {
          await this.request({ method: "DELETE", id });
          store.dispatch(pendingChange.p.a.refetchItem);
        },
        open({ state }, transform) {
          Object.assign(state.etl, transform);

          if (transform.transform_type === "ignore_attributes" && !transform.id)
            state.etl.transformations = { ignore_attributes: [] };
        },
      },
    });
  }

  async submit(ctx) {
    const { id, ...etlConfig } = ctx.state.etl;
    const formData = ctx.state.etlFormData;
    const data = deepMerge({}, etlConfig, formData);
    const reqConf = {
      data,
      id,
      method: id ? "PUT" : "POST",
    };
    const response = await super.submit(ctx, reqConf);
    store.dispatch(pendingChange.p.a.refetchItem);
  }
}

export const etlForm = new ETLForm({
  parent: pendingChange,
  module: "etlForm",
  baseUrl: `${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1`,
  url: "/etl_maps/:id",
  urlTemplate: true,
  fields: {
    extract_id: { rules: [required] },
  },
});
pendingChange.add({ modules: { etlForm } });

window.$pendingChange = pendingChange;
window.$etlForm = etlForm;

store.registerModule(
  pendingChange.modulePath,
  mergeMixins(pendingChange, pendingChangesTable)
);
