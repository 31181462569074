import GegraphicalGroupModel from "../mixins/GeographicalGroupModel";

export const corporate = new GegraphicalGroupModel({
  module: "corporate",
  noun: "corporate locations",
  table: {
    headers: [
      {
        text: "Locations",
        value: "locations.length",
        width: "auto",
      },
    ],
  },
  addFields: ["group_manager_ids"],
  detailTabs: ["general", "locations", "history"],
});

window.$corporate = corporate;

export default corporate.toVuex;
