import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.sheets),function(sheet){return _c(VTooltip,{key:sheet.id,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBadge,_vm._b({},'v-badge',sheet.badgeProps,false),[_c(VBtn,_vm._g(_vm._b({},'v-btn',Object.assign({}, attrs, sheet.btnProps),false),on),[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-file-table-outline")]),_vm._v(" "+_vm._s(sheet.sheetName)+" "),(sheet.icon)?_c(VIcon,{attrs:{"right":""}},[_vm._v(_vm._s(sheet.icon))]):_vm._e(),(sheet.iconExplained)?_c('small',[_c('em',[_vm._v("("+_vm._s(sheet.iconExplained)+")")])]):_vm._e()],1)],1)]}}],null,true)},[_c('div',{staticClass:"summaryText"},[_vm._v(_vm._s(sheet.summary))])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }