<template>
  <cp-chip small class="text-capitalize" :color="statusColor">
    {{ status }}
  </cp-chip>
</template>

<script>
import { statusMap } from "@/store/modules/generic.js";

export default {
  props: ["item"],
  computed: {
    statusId() {
      return this.item.status_id;
    },
    status() {
      return statusMap[this.statusId];
    },
    statusColor() {
      switch (this.status) {
        case "active":
          return "success";
        case "archived":
          return "error";
      }
    },
  },
};
</script>
