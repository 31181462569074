<template>
  <cp-loading :loading="loading">
    <cp-h1>
      {{ item.name }}

      <template #subtitle>
        <model-details-dates :item="item" />
      </template>

      <template #right>
        <v-btn
          @click="update"
          :disabled="submitting || !touched.length"
          :loading="loading"
          color="primary"
          text
        >
          <v-icon left>mdi-content-save</v-icon>
          Update
        </v-btn>

        <v-divider vertical class="mx-4" />
        <v-btn
          v-if="hasArchiveBA && item.status_id === 1"
          @click="archive"
          :disabled="submitting"
          color="error"
          text
        >
          <v-icon left>mdi-archive</v-icon>
          Archive
        </v-btn>
        <v-btn
          v-else-if="hasRestoreBA && item.status_id === 2"
          @click="restore"
          :disabled="submitting"
          color="green"
        >
          Restore
        </v-btn>
      </template>
    </cp-h1>
    <model-tabs :modelModule="location" />
  </cp-loading>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import { toMDY } from "@cp/utils/dateUtils";
import { findByObj } from "@cp/utils/arrayUtils";

import { location } from "@/store/modules/location";

export default {
  data() {
    return {
      location,
    };
  },
  computed: {
    ...mapState(location.mp, ["item"]),
    ...mapState(location.mp, {
      touched: "itemTouched",
      loading: "itemLoading",
      submitting: "itemSubmitting",
    }),
    ...mapGetters(location.mp, ["tabs"]),
    hasArchiveBA() {
      return findByObj(location.table.bulkActions, { name: "restore" });
    },
    hasRestoreBA() {
      return findByObj(location.table.bulkActions, { name: "archive" });
    },
  },
  methods: {
    toMDY,
    ...mapMutations(location.mp, ["resetItem"]),
    ...mapActions(location.mp, ["fetchItem", "update", "archive", "restore"]),
  },
  mounted() {
    const { id } = this.$route.params;
    if (id) {
      this.resetItem();
      this.fetchItem({ id });
    }
  },
};
</script>
