<template>
  <cp-dialog
    :show-model.sync="file.importPreviewDialogOpen"
    header-background
    max-width="1000"
    @modalClosed="$store.commit('file/resetInstance')"
  >
    <template #activator v-if="!hideActivator">
      <cp-btn color="primary" slot="activator" text>
        View Data Sample
      </cp-btn>
    </template>

    <template #title>
      Data Sample
    </template>

    <template #body>
      <p class="text-subtitle-1">
        The following are the first several rows of data from the associated
        sheet.
      </p>
      <v-skeleton-loader v-if="file.loading" type="table-thead, table-tbody" />
      <v-data-table
        v-else-if="file.instance.mapping.sample"
        :headers="file.instance.mapping.sample.headers"
        :items="file.instance.mapping.sample.rows"
        hide-default-footer
      />
    </template>

    <template #actions="{close}">
      <v-row class="ma-2">
        <v-col cols="auto">
          <cp-btn color="primary" @click="close">
            Close
          </cp-btn>
        </v-col>
      </v-row>
    </template>
  </cp-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    hideActivator: { type: Boolean, default: false }
  },

  computed: {
    ...mapState({
      file: state => state.file
    })
  }
};
</script>
