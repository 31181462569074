<template>
  <cp-card dense color="#f7f7f7">
    <template #title>
      {{ version | snakeToTitleCase }}
    </template>

    <template v-if="!editingRecord" #actions>
      <v-btn
        v-if="version == 'new_version'"
        color="grey lighten-2"
        rounded
        small
        depressed
        @click="editingRecord = true"
        :loading="file.saving"
      >
        <v-icon small class="mr-2">mdi-pencil</v-icon> Edit
      </v-btn>

      <v-btn
        :color="version == 'new_version' ? 'success' : 'primary'"
        rounded
        small
        depressed
        @click="submit"
        :loading="file.saving"
      >
        {{ version == "new_version" ? "Accept" : "Keep" }}
        {{ version | snakeToTitleCase }}
      </v-btn>
    </template>

    <template v-else #actions>
      <v-btn
        color="error"
        rounded
        small
        text
        @click="editingRecord = false"
        :loading="file.saving"
      >
        Cancel
      </v-btn>

      <v-btn
        color="success"
        rounded
        small
        depressed
        @click="updateRecord"
        :loading="file.saving"
      >
        Save Changes
      </v-btn>
    </template>

    <template v-if="editingRecord">
      <model-form :modelModule="modelModule" mode="import" :cols="{ md: 12 }" />
    </template>

    <template v-else>
      <v-sheet
        v-for="(label, i) in labels"
        :key="i"
        rounded
        class="my-1 pa-1 py-0"
        :class="{
          ignored: showAttributeIgnored(label),
          willChange:
            version == 'new_version' &&
            item.records.differences.includes(label),
        }"
      >
        <v-tooltip v-if="showAttributeIgnored(label)" left>
          <template #activator="{on, attrs}">
            <v-icon v-bind="attrs" v-on="on" x-large class="frozenIcon"
              >mdi-sync-off</v-icon
            >
          </template>
          <span>
            This attribute will be ignored in all future imports of this record
          </span>
        </v-tooltip>
        <span class="text-caption">{{ label | snakeToTitleCase }}</span>
        <br />
        <span v-if="checkForRemoved(label)" class="text-subtitle-2">
          (removed)
        </span>
        <span
          v-else-if="
            item.records[version][label] === null ||
              item.records[version][label] === ''
          "
          class="text-subtitle-2"
          style="color: #aaaaaa;"
        >
          –
        </span>
        <span v-else class="text-subtitle-2">
          {{ item.records[version][label] }}
        </span>
        <cp-icon-button
          v-if="showAttributeLock(label)"
          class="lockIcon"
          v-bind="lockProps(label)"
          @click="toggleIgnore(label)"
        />
      </v-sheet>
    </template>

    <v-row class="pt-2">
      <v-col align="right">
        <v-btn
          v-if="!editingRecord"
          :color="version == 'new_version' ? 'success' : 'primary'"
          rounded
          small
          depressed
          @click="submit"
          :loading="file.saving"
        >
          {{ version == "new_version" ? "Accept" : "Keep" }}
          {{ version | snakeToTitleCase }}
        </v-btn>
      </v-col>
    </v-row>
  </cp-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { toggle } from "carrot-patch-v2/src/utils/arrayUtils";
import { get } from "carrot-patch-v2/src/utils/objectUtils";

import { pendingChange } from "@/store/modules/pendingChange";

export default {
  props: {
    version: { type: String, default: "current_version" },
    record: { type: Object, default: () => {} },
  },

  data() {
    return {
      editingRecord: false,
    };
  },

  computed: {
    ...mapState({
      file: state => state.file,
    }),
    ...mapState(pendingChange.mp, ["item", "etlFormState"]),
    ...mapGetters(pendingChange.mp, { modelModule: "polyModule" }),

    labels() {
      return Object.keys(this.item.records[this.version]);
    },
  },

  methods: {
    checkForRemoved(label) {
      return (
        this.version == "new_version" &&
        this.item.records["current_version"][label] !== null &&
        this.item.records["new_version"][label] === null
      );
    },

    submit() {
      const methodName =
        this.version == "current_version"
          ? "file/rejectPendingChange"
          : "file/approvePendingChange";

      this.$store.dispatch(methodName, {
        ids: [this.item.id],
        importId: this.item.import_id,
      });
    },

    updateRecord() {
      this.$refs.modelFormSet.validate();

      if (this.record.formIsValid) {
        this.$store
          .dispatch("file/updatePendingChange", {
            pendingChangeId: this.item.id,
            properties: this.record.item,
          })
          .then(() => {
            this.editingRecord = false;
          });
      }
    },

    showAttributeLock(label) {
      // can only lock when the user is adding an ignore_attributes ETL
      if (
        this.etlFormState.transform_type !== "ignore_attributes" ||
        this.version !== "current_version"
      )
        return false;

      const lockableAttributes = get(
        this.file,
        "pendingChange.transformation_options.details.ignore_attributes.ignorable",
        []
      );
      // allow them to lock attributes in
      return lockableAttributes.includes(label);
    },

    showAttributeIgnored(label) {
      if (this.version === "current_version") return false;

      // show frozen when the user is adding an ignore_attributes ETL
      if (this.etlFormState.transform_type !== "ignore_attributes")
        return false;

      // only show frozen if the attribute is in the etlForm
      return this.etlFormState.transformations.ignore_attributes.includes(
        label
      );
    },

    lockProps(label) {
      const isLocked = this.etlFormState.transformations.ignore_attributes.includes(
        label
      );

      return {
        icon: isLocked ? "mdi-lock" : "mdi-lock-open-variant",
        color: isLocked ? "primary" : "gray",
        iconBtn: false,
        fab: true,
        "x-small": true,
      };
    },

    toggleIgnore(label) {
      toggle(this.etlFormState.transformations.ignore_attributes, label);
    },
  },
};
</script>

<style scoped lang="scss">
.v-sheet {
  position: relative;

  &.willChange {
    background: #ffecb3;
  }

  &.ignored {
    background: #ddd;
  }
}
.lockIcon {
  position: absolute;
  top: 50%;
  transform: translate(75%, -50%);
  right: 0;
}

.frozenIcon {
  float: right;
  margin: 2px 0 -8px 12px;
}
</style>
