<template>
  <div>
    <cp-search-autocomplete v-bind="searchProps" @input="input" />

    <div class="d-flex justify-end mt-6">
      <div v-if="success" class="d-flex align-center">
        <CpSuccessIcon class="mr-4" xLarge />
        {{ action.successMessage || "Success!" }}
      </div>
      <template v-else>
        <v-btn @click="action.modalConf.close" class="mr-4">Cancel</v-btn>
        <v-btn @click="confirm" color="primary" :loading="submitting">
          Assign selected manager
        </v-btn>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import { get } from "@cp/utils/objectUtils";

import { area } from "@/store/modules/area";
import { region } from "@/store/modules/region";

const SUB = region.table.subModuleRefs.bulkAssignAreas;

export default {
  name: "BulkSetEmployeesManager",
  props: {
    selected: { type: Array, required: true },
    action: { type: Object, required: true },
  },
  computed: {
    ...mapState(area.search.mp, {
      items: area.search.keys.stateKey,
      loading: area.search.keys.loading,
    }),
    errors() {
      return this.$store.getters[SUB.p.g.errors];
    },
    formConf() {
      return {
        submitting: this.submitting,
        success: this.success,
        unhandledErrors: this.errors.unhandledErrors,
      };
    },
    selectedIds() {
      return this.selected.map(x => x.id);
    },
    searchProps() {
      return {
        form: SUB,
        name: "area_id",
        label: "Select Managers",
        searchModule: area.search,
        params: { filters: { not_ids: this.selectedIds } },
        errorMessages: this.errors.area_id,
      };
    },
    managerIds() {
      return;
    },
    success() {
      return get(this.$store.state, SUB.p.s.success);
    },
    submitting() {
      return get(this.$store.state, SUB.p.s.submitting);
    },
  },
  methods: {
    ...mapActions(area.search.mp, {
      search: area.search.keys.search,
    }),
    input(value) {
      this.$store.commit(SUB.p.m.input, { area_id: value });
      this.$store.commit(SUB.p.m.setTouched, "area_id");
    },
    async confirm() {
      const area_id = get(this.$store.state, SUB.p.s.formData + ".area_id");
      const data = {
        regions: this.selected.map(x => ({
          area_id,
          region_id: x.id,
        })),
      };
      this.$store.dispatch(SUB.p.a.confirm, data);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-label {
  display: block;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  color: $gray-4;
}

.v-card__actions {
  padding: 0 24px 20px !important;
}
</style>
