<template>
  <cp-icon-stack
    v-if="isUnlinkedProperty"
    title="This property is not linked"
    direction="up-right"
    addon-color="error"
    :icons="[
      { name: 'mdi-home-map-marker', props: { color: 'primary' } },
      { name: 'mdi-alert' },
    ]"
  />
  <v-badge
    v-else-if="isLinked"
    icon="mdi-link"
    color="primary"
    label="Linked to another location"
    class="linked-badge"
    bordered
  >
    <v-icon
      :title="item.location_type_id === 1 ? 'office' : 'property'"
      color="primary"
    >
      {{
        item.location_type_id === 1
          ? "mdi-office-building-marker"
          : "mdi-home-map-marker"
      }}
    </v-icon>
  </v-badge>
  <v-icon
    v-else
    :title="item.location_type_id === 1 ? 'office' : 'property'"
    color="primary"
  >
    {{
      item.location_type_id === 1
        ? "mdi-office-building-marker"
        : "mdi-home-map-marker"
    }}
  </v-icon>
</template>

<script>
export default {
  props: {
    header: { type: Object },
    item: { type: Object },
  },
  computed: {
    isUnlinkedProperty() {
      return (
        this.item.location_type_id === 2 &&
        (!this.item.linked_locations || !this.item.linked_locations.length)
      );
    },
    isLinked() {
      return this.item.linked_locations.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.linked-badge::v-deep .v-badge__badge {
  padding: 0;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0 0 auto auto !important;
  transform: translate(50%, -50%);

  .v-icon {
    margin: 0;
    font-size: 12px;
    height: 12px;
    width: 12px;
    line-height: 12px;
    transform: rotate(-45deg);
  }
}
</style>
