<template>
  <v-card>
    <div class="d-flex align-center">
      <div class="count-title text-center pa-3">
        <v-skeleton-loader v-if="loading" type="chip" />
        <h3 v-else class="text-h3">{{ total }}</h3>
        <h6 class="text-subtitle-1">Active {{ card.pluralNameCaps }}</h6>
      </div>

      <v-divider vertical />
      <v-spacer />

      <div class="d-flex flex-column align-end">
        <cp-menu
          left
          :options="[
            {
              title: `Import ${card.pluralName}`,
              href: `/import`,
              type: `link`,
            },
            {
              title: `Export ${card.pluralName}`,
              type: 'storeAction',
              action: `${card.model}/exportList`,
            },
          ]"
        >
          <template #activator="{ on, attrs }">
            <cp-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </cp-btn>
          </template>
        </cp-menu>

        <div class="pa-3">
          <issue-counter :model-name="card.model" />

          <v-btn color="primary" class="mx-3" :href="card.pluralName">
            View
          </v-btn>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    card: { type: Object, default: () => {} },
  },

  computed: {
    loading() {
      return this.$store.state.dashboard[this.card.keys.loading];
    },
    total() {
      return this.$store.getters[`dashboard/${this.card.keys.count}`];
    },
    ...mapState({
      issues: state => state.issues,
    }),
  },
};
</script>

<style scoped>
.count-title {
  width: 160px;
}
</style>
