<template>
  <div>
    This will make the {{ noun }} the location manager for the location they are
    linked to.
  </div>
</template>

<script>
import pluralize from "pluralize";

export default {
  props: {
    model: { type: Object, required: true }
  },
  computed: {
    noun() {
      return pluralize("employee", this.model.bulkSelection.length);
    }
  }
};
</script>
