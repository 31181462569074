<template>
  <p>
    This will send a welcome email to all selected employees, inviting them to
    join. Are you sure you want to do this?
  </p>
</template>

<script>
export default {
  props: {
    model: { type: Object, default: () => {} }
  }
};
</script>
