<template>
  <div class="text-center">
    <template v-if="!currentJobBlockedBySpawnedJobs">
      <v-progress-circular
        class="my-2"
        size="100"
        width="12"
        :color="progressColor"
        :value="progress"
      >
        <v-icon v-if="job.step == 'completed'" color="success" large>
          mdi-check
        </v-icon>
        <v-icon v-else-if="job.step == 'cancelled'" color="error" large>
          mdi-close
        </v-icon>
        <span
          v-else-if="job.id != this.$store.getters['file/currentJob'].id"
          class="text-caption"
        >
          Waiting...
        </span>
        <span v-else class="text-subtitle-1 font-weight-bold">
          <span v-if="job.step !== 'pre-processed'">
            {{ animatedProgress }}
          </span>
          <span v-else>
            Ready
          </span>
        </span>
      </v-progress-circular>
      <br />
      <h6 v-if="job.step == 'processing'" class="text-h6 text--secondary">
        Working...
      </h6>
      <v-btn
        v-else-if="!['completed', 'cancelled'].includes(job.step)"
        class="my-2"
        color="primary"
        :disabled="job.step != 'pre-processed'"
        @click="openModal({ id: job.id })"
      >
        {{
          job.step == "pre-processed"
            ? "Review Proposed Changes"
            : "Complete Previous First"
        }}
      </v-btn>
      <h6 v-else-if="job.step == 'completed'" class="text-h6 text--secondary">
        Done
      </h6>
      <h6 v-else-if="job.step == 'cancelled'" class="text-h6 text--secondary">
        Cancelled
      </h6>
    </template>

    <cp-card v-if="job.spawned_processes.length" flat dense class="mt-2">
      <div
        class="font-weight-bold text-uppercase my-2"
        v-if="currentJobBlockedBySpawnedJobs"
      >
        <v-icon small>mdi-alert</v-icon> Found additional data to import
      </div>
      <div v-for="proc in job.spawned_processes" :key="proc.id">
        <div class="my-2" v-if="proc.step == 'processing'">
          <v-progress-circular
            size="20"
            width="3"
            indeterminate
            color="primary"
          />
          Importing {{ proc.upload_details.sheet_name }}...
        </div>
        <div class="my-2" v-else-if="proc.step == 'completed'">
          <v-icon small color="success">mdi-check-circle</v-icon>
          {{ proc.upload_details.sheet_name }} Resolved
        </div>
        <v-btn
          v-else
          class="my-1"
          color="primary"
          width="100%"
          @click="openModal({ id: proc.id })"
        >
          Review {{ proc.upload_details.sheet_name }}
        </v-btn>
      </div>
    </cp-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import { pendingChangesTable } from "@/store/modules/pendingChange";

export default {
  mixins: [pendingChangesTable.modalMixin],

  props: {
    job: { type: Object, default: () => {} },
  },

  data() {
    return {
      animatedProgress: 0,
      animProgIntervalFunc: null,
    };
  },

  computed: {
    ...mapState({
      file: state => state.file,
    }),

    ...mapGetters("file", ["currentJobBlockedBySpawnedJobs"]),

    processing() {
      return this.job.step == "processing";
    },

    progress() {
      return this.job.step == "processing"
        ? Math.round(this.job.process_percentage) || 0
        : 100;
    },

    progressColor() {
      if (this.job.step == "completed") {
        return "success";
      }

      if (this.job.step == "cancelled") {
        return "error";
      }

      if (["processing", "pre-processed"].includes(this.job.step)) {
        return "primary";
      }

      return "#dddddd";
    },
  },

  watch: {
    progress() {
      clearInterval(this.animProgIntervalFunc);

      if (this.progress == null) return;

      let delta = Math.abs(this.animatedProgress - this.progress);

      if (delta == 0) return;

      this.animProgIntervalFunc = setInterval(() => {
        if (this.animatedProgress < this.progress) {
          this.animatedProgress++;
        } else if (this.animatedProgress > this.progress) {
          this.animatedProgress--;
        }
      }, 500 / delta);
    },

    animatedProgress() {
      if (this.progress == null) return;

      if (this.animatedProgress == this.progress) {
        clearInterval(this.animProgIntervalFunc);
      }
    },

    currentJobBlockedBySpawnedJobs() {
      if (!this.currentJobBlockedBySpawnedJobs) {
        this.$store
          .dispatch(
            "file/fetchReviewData",
            this.$store.getters["file/currentJob"]
          )
          .then(() => {
            this.$store.dispatch("file/fetchImportStatus", 1000);
          });
      }
    },
  },
};
</script>
