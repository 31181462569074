<template>
  <div>
    <cp-h1>
      {{ title }}

      <template #subtitle>Total: {{ meta.pagination.records }}</template>

      <template #right v-if="!hideFilters">
        <cp-filters-menu :module="table" />
      </template>
    </cp-h1>

    <v-card>
      <Table
        v-bind="tableProps"
        v-on="tableListeners"
        v-model="tableSelected"
        class="clickable-rows"
        @click:row="
          ({ id }) => $router.push({ name: 'transformDetail', params: { id } })
        "
      >
        <template #item.transform_type="{value}">
          {{ snakeToSentenceCase(value) }}
        </template>
        <template #item.extract_type="{value, item}">
          {{ capitalize(pluralize(value, 1)) }}
          <span :set="(label = get(item, 'system_record.label'))">
            <span v-if="label">- {{ label }}</span>
          </span>
        </template>
        <template #item.created_at="{value}">
          {{ toMDYTime(value) }}
        </template>
        <template #item.explanation="{value}">
          <cp-explain>{{ value }}</cp-explain>
        </template>
        <template #item.details="{item}">
          <span v-if="item.transform_type === 'ignore_attributes'">
            <div class="text-caption">Fields Ignored:</div>
            <span>
              {{
                get(item, "transformations.ignore_attributes", [])
                  .map(snakeToSentenceCase)
                  .join(", ")
              }}
            </span>
          </span>

          <span v-if="item.transform_type === 'ignore_record'">
            Whole record skipped
          </span>
        </template>
      </Table>
    </v-card>

    <bulk-action-modals v-bind="bulkActionsMenuProps" />
  </div>
</template>

<script>
import pluralize from "pluralize";
import onWindowFocus from "@cp/mixins/onWindowFocus";
import { toMDYTime } from "@cp/utils/dateUtils";
import { get } from "@cp/utils/objectUtils";
import { snakeToSentenceCase, capitalize } from "@cp/utils/stringUtils";

import { transform } from "@/store/modules/transform";

export default {
  mixins: [
    transform.table.mixin,
    onWindowFocus(function() {
      this.fetchItems();
    }),
  ],
  props: {
    title: { type: String, default: "Data Transforms" },
    hideFilters: { type: Boolean, default: false },
  },
  methods: {
    toMDYTime,
    snakeToSentenceCase,
    pluralize,
    capitalize,
    get,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.refreshTable());
  },
};
</script>
