import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cp-dialog',{attrs:{"show-model":_vm.file.fieldMappingDialogOpen,"header-background":"","scrollable":"","max-width":"640"},on:{"update:showModel":function($event){return _vm.$set(_vm.file, "fieldMappingDialogOpen", $event)},"update:show-model":function($event){return _vm.$set(_vm.file, "fieldMappingDialogOpen", $event)},"modalClosed":function($event){return _vm.$store.commit('file/resetInstance')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Field Mapping ")]},proxy:true},{key:"body",fn:function(){return [(_vm.file.loading)?_vm._l((4),function(n){return _c(VRow,{key:n,staticClass:"py-1",attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"3","align":"right"}},[_c(VSkeletonLoader,{attrs:{"type":"text"}})],1),_c(VCol,{attrs:{"cols":"2","align":"center"}},[_c(VIcon,[_vm._v("mdi-arrow-right")])],1),_c(VCol,{attrs:{"cols":"7"}},[_c(VSkeletonLoader,{attrs:{"type":"text"}})],1)],1)}):_c('import-field-mapping')]},proxy:true},{key:"actions",fn:function(ref){
var close = ref.close;
return [_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c('cp-btn',{attrs:{"color":"primary","rounded":"","loading":_vm.file.saving},on:{"click":function($event){return _vm.$store.dispatch('file/submitFieldMapping', {
            job: _vm.file.instance,
            fieldMapping: _vm.$store.getters['file/fieldMapAsArray'],
            loadType: _vm.file.instance.load_type
          })}}},[_vm._v(" Save Field Mappings ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }