import pluralize from "pluralize";

import { eventbus } from "@cp/lib";
import { deepMerge } from "@cp/utils/collectionUtils";
import { required } from "@cp/utils/rules";

import { Model } from "./Model";
import search from "@/store/modules/search";

const areaSearch = search.register("areas");
const employeeSearch = search.register("employees");

export default class GegraphicalGroupModel extends Model {
  constructor({
    listPathName: listPathNameArg,
    addFields = [],
    table: tableArg,
    form: formArg,
    ...config
  }) {
    const urlKey = "geographical_groups";
    const table = deepMerge(
      {
        useGlobalBulkActions: false,
        params: { filters: { depth: config.module } },
        headers: [
          { text: "", value: "alert", sortable: false, width: "20px" },
          {
            text: "Name",
            value: "name",
            width: "auto",
            cellClass: "linkedCell",
          },
          {
            text: "Managers",
            value: "group_managers",
            width: "auto",
          },
        ],
        filters: [
          "q_text",
          {
            label: "General",
            type: "select",
            key: "general",
            items: [
              {
                text: "No Locations Assigned",
                value: "missing_locations",
              },
            ],
          },
        ],
      },
      tableArg
    );
    const form = deepMerge(
      {
        initialValue: {
          depth: config.module,
          area_id: "",
          area: {},
          client_id: "",
          created_at: "",
          deficiencies: [],
          depth: "",
          group_manager_ids: [],
          group_managers: [],
          id: "",
          is_corporate: true,
          locations: [],
          name: "",
          reference_id: null,
          regions: [],
          slug: "",
          total_active_users: 0,
          type: null,
          updated_at: "",
        },
        fields: {
          depth: {
            initialValue: config.module,
          },
          name: {
            rules: [required],
          },
          reference_id: {
            label: "Region ID",
          },
          group_manager_ids: {
            label: "Managers",
            type: "api-autocomplete",
            searchModule: employeeSearch,
            multiple: true,
          },
          area_id: {
            label: "Area",
            type: "api-autocomplete",
            searchModule: areaSearch,
          },
        },
        fieldOrder: ["name", "reference_id", ...addFields],
      },
      formArg
    );
    super({ ...config, table, form, urlKey });

    const listPathName = listPathNameArg || pluralize(this.nounTitle, 2);
    this.instantiate({ listPathName });

    this.add({
      keys: ["delete"],
      actions: {
        async delete({ state, dispatch }) {
          const { id } = state.item;
          const { errors } = await dispatch(this.form.keys.submit, {
            id,
            method: "DELETE",
            data: { delete: true }, // have to pass to get past form validation
          });
          if (state.itemSuccess) {
            eventbus.$emit("redirect", { name: this.listPathName });
            eventbus.$emit("snackAlert", {
              message: `${this.nounTitle} ${state.item.name} deleted!`,
              color: "success",
            });
          } else {
            console.log(errors);
            eventbus.$emit("snackAlert", {
              message: `Delete failed`,
              color: "error",
            });
          }
        },
      },
    });
  }
}
