<template>
  <div>
    <h6 class="text-h6 text--secondary mb-3">
      {{ modelModule.nounTitle }} Change History
    </h6>
    <div v-for="(auditsOnDay, index) in auditsByDate" :key="`audit-${index}`">
      <v-sheet color="#f7f7f7" rounded class="pa-1 px-2 mb-2">
        <span style="color: #666666" class="text-subtitle-1 font-weight-bold">
          {{ auditsOnDay.date }}
        </span>
      </v-sheet>
      <audits
        v-for="(audit, ai) in auditsOnDay.audits"
        :audit="audit"
        :key="ai"
      />
    </div>

    <div v-intersect="loadMore">
      <div v-if="canLoadMore">
        <v-progress-circular indeterminate color="primary" />
        Loading More...
      </div>
    </div>
  </div>
</template>

<script>
import intersect from "vuetify/lib/directives/intersect";

import { capitalize } from "@cp/utils/stringUtils";

export default {
  directives: { intersect },
  props: {
    modelModule: { type: Object, required: true },
  },
  computed: {
    auditsByDate() {
      return this.$store.getters[this.modelModule.audit.p.g.auditsByDate];
    },
    canLoadMore() {
      return this.$store.getters[this.modelModule.audit.p.g.canLoadMore];
    },
  },
  methods: {
    capitalize,
    loadMore() {
      const id = this.$router.currentRoute.params.id;
      this.$store.dispatch(this.modelModule.audit.p.a.loadMore, { id });
    },
  },
  mounted() {
    const id = this.$router.currentRoute.params.id;
    this.$store.dispatch(this.modelModule.audit.p.a.fetch, { id });
  },
};
</script>
