import Crud from "./generic";
let base = new Crud("resident", "residents", "residents", "resident");
import m from "carrot-patch-v2/src/utils/mustache";

export default {
  namespaced: true,

  state: {
    ...base.state,
    url: "/residents",
    recordDetailTabs: ["General", "Related Items", "History"],
    listTableHeaders: [
      { text: "", value: "alert", sortable: false, width: "20px" },
      {
        text: "Name",
        value: "full_name",
        width: "auto",
        linkCell: true
      },
      {
        text: "Location",
        value: "location_name",
        width: "auto"
      },
      {
        text: "Unit #",
        value: "unit_number",
        width: "auto"
      },
      {
        text: "Lease Start",
        value: "lease_from_date",
        width: "auto"
      },
      {
        text: "Lease Ends",
        value: "lease_to_date",
        width: "auto"
      }
    ]
  },

  getters: {
    ...base.getters,

    formFields(state) {
      return [
        "resident_id",
        "resident_status",
        "first_name",
        "last_name",
        "primary_email",
        "secondary_email",
        "phone_number",
        "opt_out"
      ].map(id => ({
        text: id,
        value: id,
        type: "text",
        importEditable: true,
        meta: { disabled: true }
      }));
    },

    instanceActions(state, getters) {
      return [...getters.globalInstanceActions];
    },

    bulkActions(state, getters) {
      return [...getters.globalBulkActions];
    }
  },

  actions: {
    ...base.actions
  },

  mutations: {
    ...base.mutations
  }
};
