<template>
  <div>
    <vue-dropzone
      id="file-upload"
      class="file-upload"
      :options="options"
      :use-custom-slot="true"
      @vdropzone-success="handleSuccess"
      @vdropzone-sending="sendingEvent"
    >
      <span class="icon icon-cloud-upload" />
      <div class="instructions">
        Drag and drop file here to upload
      </div>
      <div class="or">
        Or
      </div>
      <v-btn color="primary" large>
        <v-icon left>mdi-upload</v-icon>
        Select File
      </v-btn>
    </vue-dropzone>
  </div>
</template>

<script>
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import VueDropzone from "vue2-dropzone";
import { mapGetters } from "vuex";
// import router from "@cp/router";

export default {
  components: {
    VueDropzone,
  },
  data() {
    let prefix = "";
    if (process.env.VUE_APP_MARIGOLD_API_PATH) {
      prefix = process.env.VUE_APP_MARIGOLD_API_PATH;
    }
    const url = `${prefix}/en/v1/imports/upload`;
    return {
      uploading: false,
      options: {
        url: url,
        maxFiles: 1,
        acceptedFiles: ".csv,.xlsx,.xls",
        headers: {},
      },
    };
  },
  computed: {
    ...mapGetters("file", ["jwt"]),
  },
  methods: {
    sendingEvent(file, xhr, formData) {
      formData.append("upload[file]", file);
    },
    handleSuccess(file, response) {
      this.uploading = false;
      setTimeout(
        () => this.$store.dispatch("file/fetchImportStatus", 5000),
        2000
      );
      // router.push("/import/status")
    },
  },
  mounted() {
    this.options.headers["Authorization"] = this.jwt;
  },
};
</script>
