<template>
  <cp-module-modal :modalConf="modalConf" :persistent="true">
    <v-toolbar dark color="primary" ref="toolbar" dense>
      <v-toolbar-title>
        Review Proposed Changes
      </v-toolbar-title>

      <v-spacer />

      <v-btn
        @click="modalConf.close"
        large
        text
        tile
        class="justify-self-start"
      >
        <v-icon left>mdi-close</v-icon> Close
      </v-btn>
    </v-toolbar>

    <v-card>
      <cp-loading v-if="initialLoad" />
      <review-pending-changes-table v-else />
    </v-card>
  </cp-module-modal>
</template>

<script>
import { mapState } from "vuex";
import { pendingChangesTable } from "@/store/modules/pendingChange";

export default {
  mixins: [pendingChangesTable.mixin, pendingChangesTable.modalMixin],
  computed: mapState(pendingChangesTable.mp, ["initialLoad"]),
};
</script>

<style lang="scss" scoped>
.v-toolbar::v-deep {
  background: linear-gradient(90deg, #007dba 0%, #009a44 100%);
  + .v-card {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .v-toolbar__content {
    padding-right: 0 !important;
  }

  .v-btn.v-btn--tile {
    height: 64px;
  }
}

.v-toolbar__title {
  font-weight: 700;
}
</style>
