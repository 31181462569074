import List from "./List.vue";
import Detail from "./Detail.vue";
import Create from "./Create.vue";

export default [
  {
    path: "/employees",
    name: "Employees",
    component: List,
    meta: {
      title: "Employees",
    },
  },
  {
    path: "/employees/create",
    name: "addEmployee",
    component: Create,
    meta: {
      title: "Add Employee",
      parent: "Employees",
    },
  },
  {
    path: "/employees/:id",
    name: "employeeDetail",
    component: Detail,
    meta: {
      title: "Edit Employee",
      mode: "edit",
      parent: "Employees",
    },
  },
];
