<template>
  <cp-dialog header-background max-width="640">
    <template #activator>
      <v-btn color="error" slot="activator" text>
        <v-icon class="mr-2">mdi-close</v-icon>
        Cancel Import
      </v-btn>
    </template>

    <template #title>
      Cancel Import
    </template>

    <template #body>
      <p class="text-subtitle-1 font-weight-bold">
        Are you sure you want to abandon this import? Any unsaved changes will
        be lost.
      </p>
    </template>

    <template #actions="{close}">
      <v-spacer />
      <cp-btn text @click="close">
        Cancel
      </cp-btn>
      <cp-btn
        color="error"
        class="pa-4 mb-2"
        @click="
          resetMapping();
          close();
        "
      >
        <v-icon class="mr-2">mdi-alert</v-icon>
        Confirm Reset
      </cp-btn>
    </template>
  </cp-dialog>
</template>

<script>
export default {
  methods: {
    resetMapping() {
      this.$store.dispatch("file/resetImport").then(() => {
        this.$CpEvent.$emit("snackAlert", { message: "Import reset" });
      });
    }
  }
};
</script>
