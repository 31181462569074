import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cp-module-modal',{attrs:{"modalConf":_vm.modalConf,"persistent":true}},[_c(VToolbar,{ref:"toolbar",attrs:{"dark":"","color":"primary","dense":""}},[_c(VToolbarTitle,[_vm._v(" Review Proposed Changes ")]),_c(VSpacer),_c(VBtn,{staticClass:"justify-self-start",attrs:{"large":"","text":"","tile":""},on:{"click":_vm.modalConf.close}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v(" Close ")],1)],1),_c(VCard,[(_vm.initialLoad)?_c('cp-loading'):_c('review-pending-changes-table')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }