import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cp-dialog',{attrs:{"show-model":_vm.file.importPreviewDialogOpen,"header-background":"","max-width":"1000"},on:{"update:showModel":function($event){return _vm.$set(_vm.file, "importPreviewDialogOpen", $event)},"update:show-model":function($event){return _vm.$set(_vm.file, "importPreviewDialogOpen", $event)},"modalClosed":function($event){return _vm.$store.commit('file/resetInstance')}},scopedSlots:_vm._u([(!_vm.hideActivator)?{key:"activator",fn:function(){return [_c('cp-btn',{attrs:{"slot":"activator","color":"primary","text":""},slot:"activator"},[_vm._v(" View Data Sample ")])]},proxy:true}:null,{key:"title",fn:function(){return [_vm._v(" Data Sample ")]},proxy:true},{key:"body",fn:function(){return [_c('p',{staticClass:"text-subtitle-1"},[_vm._v(" The following are the first several rows of data from the associated sheet. ")]),(_vm.file.loading)?_c(VSkeletonLoader,{attrs:{"type":"table-thead, table-tbody"}}):(_vm.file.instance.mapping.sample)?_c(VDataTable,{attrs:{"headers":_vm.file.instance.mapping.sample.headers,"items":_vm.file.instance.mapping.sample.rows,"hide-default-footer":""}}):_vm._e()]},proxy:true},{key:"actions",fn:function(ref){
var close = ref.close;
return [_c(VRow,{staticClass:"ma-2"},[_c(VCol,{attrs:{"cols":"auto"}},[_c('cp-btn',{attrs:{"color":"primary"},on:{"click":close}},[_vm._v(" Close ")])],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }