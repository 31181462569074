import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"count-title text-center pa-3"},[(_vm.loading)?_c(VSkeletonLoader,{attrs:{"type":"chip"}}):_c('h3',{staticClass:"text-h3"},[_vm._v(_vm._s(_vm.total))]),_c('h6',{staticClass:"text-subtitle-1"},[_vm._v("Active "+_vm._s(_vm.card.pluralNameCaps))])],1),_c(VDivider,{attrs:{"vertical":""}}),_c(VSpacer),_c('div',{staticClass:"d-flex flex-column align-end"},[_c('cp-menu',{attrs:{"left":"","options":[
          {
            title: ("Import " + (_vm.card.pluralName)),
            href: "/import",
            type: "link",
          },
          {
            title: ("Export " + (_vm.card.pluralName)),
            type: 'storeAction',
            action: ((_vm.card.model) + "/exportList"),
          } ]},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('cp-btn',_vm._g(_vm._b({attrs:{"icon":""}},'cp-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}])}),_c('div',{staticClass:"pa-3"},[_c('issue-counter',{attrs:{"model-name":_vm.card.model}}),_c(VBtn,{staticClass:"mx-3",attrs:{"color":"primary","href":_vm.card.pluralName}},[_vm._v(" View ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }