<template>
  <v-form v-model="model.formIsValid" lazy-validation ref="modelForm">
    <v-container>
      <v-alert v-if="unhandledErrors" color="error" text dense>
        <template #prepend>
          <v-icon class="mr-3" color="error">mdi-alert-circle-outline</v-icon>
        </template>
        <table class="errorTable">
          <thead>
            <th>Field</th>
            <th>Error</th>
          </thead>
          <tbody
            v-for="([key, errors], index) in unhandledErrors"
            :key="`error-message-${index}`"
          >
            <tr
              v-for="(error, errIndex) in errors"
              :key="`${key}-error-${errIndex}`"
            >
              <td v-if="index === 0" :rowspan="errors.length" class="fieldName">
                {{ key }}
              </td>
              <td>{{ error }}</td>
            </tr>
          </tbody>
        </table>
        <div></div>
      </v-alert>

      <v-row dense>
        <v-col :cols="showInfoFields ? 8 : 12">
          <v-row dense>
            <v-col
              v-for="(field, i) in filteredFormFields"
              cols="12"
              md="6"
              :key="i"
            >
              <v-text-field
                v-if="['text', 'integer'].includes(field.type)"
                v-model="model.item[field.value]"
                :label="field.text"
                :rules="field.rules"
                :type="field.type == 'integer' ? 'number' : 'text'"
                :error-messages="model.errors[field.value]"
                @input="delete model.errors[field.value]"
                v-bind="field.meta"
              />

              <v-select
                v-else-if="field.type == 'single-select'"
                v-model="model.item[field.value]"
                :label="field.text"
                :items="field.items"
                :rules="field.rules"
                :error-messages="model.errors[field.value]"
                @input="delete model.errors[field.value]"
              />

              <v-autocomplete
                v-else-if="field.type == 'autocomplete'"
                v-model="model.item[field.value]"
                :label="field.text"
                :items="field.items"
                :rules="field.rules"
                :multiple="field.multiple"
                :error-messages="model.errors[field.value]"
                @input="delete model.errors[field.value]"
              />

              <v-autocomplete
                v-else-if="field.type == 'api-autocomplete'"
                v-model="model.item[field.value]"
                :label="field.text"
                :items="field.items"
                :rules="field.rules"
                :loading="model.searching"
                :allow-overflow="false"
                no-data-text="Type to search"
                :search-input.sync="searchFields[field.value]"
                :multiple="field.multiple"
                cache-items
                :error-messages="model.errors[field.value]"
                @input="delete model.errors[field.value]"
              />

              <v-checkbox
                v-else-if="field.type == 'checkbox'"
                v-model="model.item[field.value]"
                :label="field.text"
                :rules="field.rules"
                :error-messages="model.errors[field.value]"
                @input="delete model.errors[field.value]"
              />

              <v-menu
                v-else-if="field.type == 'date'"
                transition="scale-transition"
                offset-y
                min-width="100"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="model.item[field.value]"
                    :label="field.text"
                    :rules="field.rules"
                    append-outer-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    :error-messages="model.errors[field.value]"
                    @input="delete model.errors[field.value]"
                  />
                </template>

                <v-date-picker
                  no-title
                  :value="model.item[field.value]"
                  @input="model.item[field.value] = $event"
                />
              </v-menu>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="4" v-if="showInfoFields">
          <imported-fields v-if="context != 'importing'" :model="model" />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
  props: {
    model: { type: Object, default: () => {} },
    context: { type: String, default: null },
    fieldsGetter: { type: String, default: "formFields" },
  },

  data() {
    return {
      apiSearchTimeout: null,
      searchFields: {
        actions: {},
      },
    };
  },

  computed: {
    fields() {
      return this.$store.getters[`${this.model.singularName}/formFields`];
    },
    filteredFormFields() {
      if (this.context === "importing")
        return this.fields.filter(({ importEditable }) => importEditable);
      if (this.model.showInfoFieldsInForm) return this.fields;
      return this.fields.filter(({ importOnly }) => !importOnly);
    },

    unhandledErrors() {
      if (!this.model.errors) debugger;
      // if the error key isn't in filteredFormFields, we need to show it
      const handledKeys = this.filteredFormFields.map(({ value }) => value);
      const notHandledKeys = Object.keys(this.model.errors).filter(
        // sometimes the BE sends back keys with empty arrays, ignore those
        key => !handledKeys.includes(key) && this.model.errors[key].length
      );
      if (!notHandledKeys.length) return false;
      return notHandledKeys.map(key => {
        let errors = this.model.errors[key];
        if (typeof errors === "string") errors = [errors];
        return [key, errors];
      });
    },
    showInfoFields() {
      if (this.context === "importing") return false;
      return this.fields.filter(({ importOnly }) => importOnly).length;
    },
  },

  methods: {
    reset() {
      this.$refs.modelForm.reset();
    },

    validate() {
      this.$refs.modelForm.validate();
    },
  },

  mounted() {
    this.$CpEvent.$on(`resetModelForm`, this.reset);
    this.$store.dispatch(`${this.model.singularName}/fetchRelatedOptions`);

    // attach watchers for api searchable autocompletes
    this.$store.getters[`${this.model.singularName}/formFields`]
      .filter(x => x.type == "api-autocomplete")
      .map(x => ({ value: x.value, action: x.action }))
      .forEach(({ value, action }) => {
        this.searchFields.actions[value] = action;
        this.$watch(`searchFields.${value}`, (newValue, oldValue) => {
          if (
            newValue == null ||
            newValue.trim() == "" ||
            newValue == oldValue
          ) {
            this.model.searching = false;
            return;
          }

          this.model.searching = true;
          clearTimeout(this.apiSearchTimeout);
          this.apiSearchTimeout = setTimeout(() => {
            this.$store.dispatch(action, this.searchFields[value]);
          }, 300);
        });
      });
  },

  beforeDestroy() {
    this.$CpEvent.$off("resetModelForm");
  },
};
</script>

<style>
.v-window.v-item-group {
  border-radius: 4px;
}
</style>
<style scoped lang="scss">
.errorTable {
  th {
    border-bottom: 1px solid;
  }
  td {
    padding: 0 1rem;
  }
  .fieldName {
    border-right: 1px solid;
    font-style: italic;
  }
}
</style>
