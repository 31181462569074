<template>
  <div :class="{ open, expandable }" @click="open = !open">
    <changes :action="item.action" :changes="item.changes" ref="changes" />
  </div>
</template>

<script>
export default {
  props: {
    header: { type: Object },
    item: { type: Object }
  },
  data() {
    return {
      open: false,
      expandable: true // my guess is that most will be large, so default to expandable
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.changes.$el.scrollHeight < 50) this.expandable = false;
    });
  }
};
</script>

<style lang="scss">
.expandable {
  max-height: 50px;
  overflow: hidden;
  position: relative;

  &.open {
    max-height: none;
  }

  &:before {
    content: "...click to expand";
    position: absolute;
    inset: auto 0 0 auto;
    background: #fff;
    padding: 3px;
    height: 1.25rem;
    line-height: 1.25rem;
    color: $gray-5;
    z-index: 3;
    font-size: 10px;
  }
  &.open:before {
    content: "click to close";
  }

  &:not(.open):after {
    content: "";
    position: absolute;
    inset: auto 0 0;
    height: 1.5em;
    z-index: 2;
    box-shadow: inset 0 -2em 0.75em -1.5em white;
  }
}
</style>
