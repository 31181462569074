import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanels,{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"mb-6",attrs:{"disabled":_vm.isEditing}},_vm._l((_vm.item.transformations),function(transform){return _c(VExpansionPanel,{key:transform.id},[_c(VExpansionPanelHeader,[_c('cp-data-to-text',{attrs:{"data":transform,"getter":_vm.getTransformValue(transform.transform_type, 'applied_text')}})],1),_c(VExpansionPanelContent,[_c('cp-data-to-text',{attrs:{"data":transform,"getter":_vm.getTransformValue(transform.transform_type, 'applied_description')}}),_c('div',{staticClass:"text-right mt-3"},[(_vm.isEditing)?_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.submit}},[_c(VIcon,[_vm._v("mdi-content-save")]),_vm._v(" Update ")],1):_vm._e(),(!_vm.isEditing)?_c(VBtn,{staticClass:"ml-2",attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteEtl(transform.id)}}},[_c(VIcon,[_vm._v("mdi-delete")]),_vm._v(" Delete ")],1):_vm._e(),(
            _vm.getTransformValue(transform.transform_type, 'editable') &&
              !_vm.isEditing
          )?_c(VBtn,{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.open(transform)}}},[_c(VIcon,[_vm._v("mdi-pencil")]),_vm._v(" Edit ")],1):_vm._e()],1)],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }