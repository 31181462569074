import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"my-1",attrs:{"align":"center","dense":""}},[_c(VCol,{class:{ 'font-weight-bold': _vm.mapping.required },attrs:{"cols":"3","align":"right"}},[_vm._v(" "+_vm._s(_vm.mapping.label)+" "),(_vm.mapping.required)?_c('span',{staticClass:"error--text"},[_vm._v("*")]):_vm._e()]),_c(VCol,{attrs:{"cols":"2","align":"center"}},[(_vm.file.instance.mapping.fieldMap[_vm.mapping.value])?_c(VIcon,{attrs:{"color":"success"}},[_vm._v("mdi-check")]):_c(VIcon,{attrs:{"color":"#dddddd"}},[_vm._v("mdi-arrow-right")])],1),_c(VCol,{attrs:{"cols":"7"}},[_c(VSelect,{staticClass:"pt-1",attrs:{"items":_vm.$store.getters['file/fieldMappingOptions'],"errorMessages":_vm.errorMessages,"hint":_vm.sample,"persistent-hint":_vm.sample,"item-value":"label","item-text":"label","clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"line-height":"1"}},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.label))]),_c('span',{staticClass:"text-caption",class:{ 'text--disabled': item.disabled }},[_vm._v(" "+_vm._s(_vm.getSampleForDropdown(item.label))+" ")])])]}}]),model:{value:(_vm.file.instance.mapping.fieldMap[_vm.mapping.value]),callback:function ($$v) {_vm.$set(_vm.file.instance.mapping.fieldMap, _vm.mapping.value, $$v)},expression:"file.instance.mapping.fieldMap[mapping.value]"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }