<template>
  <div>
    <p>Assign all selected employees to this position</p>
    <v-autocomplete
      v-model="model.bulkAssignPositionTarget"
      label="Positions"
      :items="model.positions"
      :loading="model.searching"
      :allow-overflow="false"
      no-data-text="Type to search"
      cache-items
      @focus="getPositions"
    />
  </div>
</template>

<script>
export default {
  props: {
    model: { type: Object, default: () => {} }
  },

  methods: {
    getPositions() {
      if (this.model.positions.length <= 0) {
        this.$store.dispatch("employee/fetchPositions");
      }
    }
  }
};
</script>
