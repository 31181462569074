<template>
  <div>
    <v-select
      v-model="job.load_type"
      solo
      :items="file.importTypeMap"
      label="Select Data Type"
      class="mt-6 mb-4"
      hide-details
      clearable
      @input="
        importType =>
          $store.dispatch('file/updateImportTypeFields', {
            job: job,
            importType: importType
          })
      "
    />
    <v-btn
      rounded
      block
      text
      color="primary"
      class="mb-2"
      @click="
        file.importPreviewDialogOpen = true;
        $store.dispatch('file/fetchFieldMapDetails', job);
      "
    >
      <v-icon class="mr-2">mdi-table-eye</v-icon> Preview Sample Data
    </v-btn>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    job: { type: Object, default: () => {} }
  },

  computed: {
    ...mapState({
      file: state => state.file
    })
  }
};
</script>
