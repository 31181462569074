import Crud from "./generic";
let base = new Crud("lease", "leases", "leases", "lease");
import m from "carrot-patch-v2/src/utils/mustache";

export default {
  namespaced: true,

  state: {
    ...base.state,
    url: "/leases",
    recordDetailTabs: ["General", "Related Items", "History"],
    listTableHeaders: [
      { text: "", value: "alert", sortable: false, width: "20px" },
      {
        text: "Primary Resident",
        value: "primary_resident_name",
        linkCell: true,
        width: "auto"
      },
      {
        text: "Lease end date",
        value: "lease_to_date",
        width: "auto"
      },
      {
        text: "Location",
        value: "location_name",
        width: "auto"
      },
      {
        text: "unit",
        value: "unit_number",
        width: "auto"
      },
      {
        text: "Move In Date",
        value: "move_in_date",
        width: "auto"
      },
      {
        text: "Lease to Date",
        value: "lease_to_date",
        width: "auto"
      }
    ]
  },

  getters: {
    ...base.getters,

    formFields(state) {
      return [
        "reference_id",
        "unit_id",
        "lease_status",
        "is_previous",
        "is_next",
        "primary_resident_id",
        "secondary_resident_id",
        "application_date",
        "lease_signed_date",
        "lease_from_date",
        "lease_to_date",
        "move_in_date",
        "move_out_date"
      ].map(id => ({
        text: id,
        value: id,
        type: "text",
        importEditable: true,
        meta: { disabled: true }
      }));
    },

    instanceActions(state, getters) {
      return [...getters.globalInstanceActions];
    },

    bulkActions(state, getters) {
      return [...getters.globalBulkActions];
    }
  },

  actions: {
    ...base.actions
  },

  mutations: {
    ...base.mutations
  }
};
