<template>
  <span v-html="text" :class="{ truncate: header.truncate }"></span>
</template>

<script>
import { get } from "carrot-patch-v2/src/utils/collectionUtils";

export default {
  props: {
    header: { type: Object },
    item: { type: Object }
  },
  computed: {
    text() {
      if (typeof this.header.format === "function")
        return this.header.format(this.item);
      else if (typeof this.header.format === "string")
        return get(this.item, this.header.format, "");
      else return `TODO - add format to ${this.header.text}`;
    }
  }
};
</script>

<style scoped lang="scss">
span.truncate {
  display: inline-block;
  max-height: 3.1em;
  overflow-y: auto;
  width: 100%;
  &:active,
  &:focus {
    max-height: none;
    overflow: auto;
  }
}
</style>
