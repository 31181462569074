<template>
  <div>
    <h6 class="text-h6 text--secondary mb-3">
      {{ modelModule.nounTitle }}
      Permissions
    </h6>

    <v-row dense>
      <v-col
        cols="12"
        md="4"
        v-for="(service, i) in services"
        :key="i"
        class="mb-2"
      >
        <v-sheet
          color="#f1f1f1"
          rounded
          class="text-uppercase text-bold pa-1 px-2 mb-1"
        >
          {{ service }}
        </v-sheet>

        <v-select
          v-if="service === 'Engagement System'"
          :items="engageOptions"
          :value="selectedEngageRole"
          @input="changeEngageRole"
        >
          <template #selection="{item}">
            <strong class="mr-3">{{ item.text }}</strong>
            <small class="text-caption text--secondary"
              >( {{ item.description }} )</small
            >
          </template>
          <template #item="{item}">
            <div>
              <div>{{ item.text }}</div>
              <h4 class="text-caption text--secondary mb-1">
                {{ item.description }}
              </h4>
            </div>
          </template>
        </v-select>

        <template v-else v-for="role in serviceRoles[service]">
          <v-checkbox
            v-model="selectedRoleIds"
            :value="role.id"
            :key="`checkbox-${role.value}`"
            :label="role.name"
            multiple
            hide-details
            class="mt-0"
            dense
          />
          <h4
            class="text-caption text--secondary mb-1 ml-8"
            :key="`description-${role.value}`"
          >
            {{ role.description || "No description text" }}
          </h4>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";

import { toggle } from "@cp/utils/arrayUtils";
import { capitalize } from "@cp/utils/stringUtils";

import { roles } from "@/store/modules/employee";

export default {
  props: {
    modelModule: { type: Object, required: true },
  },
  computed: {
    ...mapState(roles.mp, ["itemFormData"]),
    ...mapGetters(roles.mp, ["services", "serviceRoles"]),
    rolesObj() {
      return this.itemFormData.role_ids.reduce((r, id) => {
        const found = this.$store.state.employee.roles.find(x => x.id === id);
        if (found) r[id] = found.name || found.text || "WAT";
        return r;
      }, {});
    },
    engageRoles() {
      return this.serviceRoles["Engagement System"];
    },
    engageOptions() {
      return [
        ...this.engageRoles,
        {
          value: null,
          text: "None",
          description: "No access to Engage",
        },
      ];
    },
    selectedEngageRole() {
      const userRoles = this.itemFormData.role_ids || [];
      const selectedRole = this.engageRoles.find(x =>
        userRoles.includes(x.value)
      );
      // if no role found, it's none, so show last item
      return selectedRole || this.engageOptions[this.engageOptions.length - 1];
    },
    selectedRoleIds: {
      get() {
        return this.itemFormData.role_ids;
      },
      set(newValues) {
        // console.log(this.rolesObj);
        this.itemFormData.role_ids = newValues;
        // console.log(this.rolesObj);
        this.setItemTouched("role_ids");
      },
    },
  },
  methods: {
    capitalize,
    ...mapMutations(roles.mp, ["setItemTouched"]),
    shouldBeChecked(value) {
      return this.itemFormData.role_ids.includes(value);
    },
    changeEngageRole(newValue) {
      // console.log(this.rolesObj);

      // remove any other engage roles from role_ids first
      const engageRoleIds = this.engageRoles.map(x => x.id);
      toggle(this.itemFormData.role_ids, engageRoleIds, false);

      // console.log(this.rolesObj);

      // add the new role
      this.itemFormData.role_ids.push(newValue);
      // console.log(this.rolesObj);

      // turn on the new engage role, and remove all others

      this.setItemTouched("role_ids");
    },
  },
  mounted() {
    this.$store.dispatch(roles.p.a.fetch);
  },
};
</script>
