import { ApiDataWithLoadMore } from "@cp/store/mixins";

export class Audit extends ApiDataWithLoadMore {
  constructor({
    initialValue = [],
    url = "/:id/audit",
    urlTemplate = true,
    ...config
  } = {}) {
    const name = "audits";
    const compoundId = false;
    super({ ...config, compoundId, name, initialValue, url, urlTemplate });

    this.add({
      keys: ["auditsByDate"],
      getters: {
        auditsByDate({ audits = [] }) {
          return audits.reduce((r, audit) => {
            const date = audit.audit_date;
            const found = r.find(x => x.date === date);
            if (found) found.audits.push(audit);
            else
              r.push({
                date,
                audits: [audit],
              });
            return r;
          }, []);
        },
      },
    });
  }
}
