<template>
  <div v-if="userIsSwiftbunny && show">
    <v-tooltip v-if="progress >= 100" bottom>
      <template #activator="{on}">
        <v-icon v-on="on">
          mdi-check-circle
        </v-icon>
      </template>
      Import step complete!
    </v-tooltip>
    <v-icon v-else-if="progress == 0" color="warning">
      mdi-information
    </v-icon>

    <v-tooltip v-else-if="progress != null" bottom>
      <template #activator="{on}">
        <v-progress-circular v-on="on" :value="progress" size="24" />
      </template>

      {{ progress }}% Complete
    </v-tooltip>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  computed: {
    ...mapState("file", {
      show: file => file.collection.length > 0
    }),
    ...mapGetters("file", ["currentJob"]),
    ...mapGetters("auth", ["userIsSwiftbunny"]),
    progress() {
      return this.currentJob.step == "pre-processed"
        ? 100
        : Math.round(this.currentJob.process_percentage);
    }
  }
};
</script>

<style lang="scss">
// When this is in the nav drawer, and the item is the current page, use $primary rather than white
.NavDrawer {
  .v-list-item:not(.v-list-item--active) .v-progress-circular__underlay {
    stroke: rgba(225, 225, 225, 0.3);
  }
  .v-list-item--active .v-progress-circular__overlay {
    stroke: $primary;
  }
}
</style>
