<template>
  <cp-loading :loading="loading">
    <cp-h1>
      {{ item.name }}

      <template #subtitle>
        <model-details-dates :item="item" />
      </template>

      <template #right>
        <v-btn
          @click="update"
          :disabled="!touched.length"
          :loading="loading"
          color="primary"
          text
        >
          <v-icon left>mdi-content-save</v-icon>
          Update
        </v-btn>

        <v-divider vertical class="mx-4" />

        <v-btn @click="confirmDelete" color="error" text>
          <v-icon left>mdi-trash-can-outline</v-icon>
          Delete
        </v-btn>
      </template>
    </cp-h1>
    <model-tabs :modelModule="corporate" />
  </cp-loading>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import { corporate } from "@/store/modules/corporate";

export default {
  data() {
    return {
      corporate,
    };
  },
  computed: {
    ...mapState(corporate.mp, ["item"]),
    ...mapState(corporate.mp, {
      touched: "itemTouched",
      loading: "itemLoading",
      submitting: "itemSubmitting",
    }),
    ...mapGetters(corporate.mp, ["tabs"]),
  },
  methods: {
    ...mapMutations(corporate.mp, ["resetItem"]),
    ...mapActions(corporate.mp, ["fetchItem", "update"]),
    ...mapActions("confirm", ["confirm"]),
    confirmDelete() {
      this.confirm({
        verb: "delete",
        noun: corporate.noun,
        alertText: `This will delete the ${corporate.noun} permanently!`,
        action: corporate.p.a.delete,
      });
    },
  },
  mounted() {
    const { id } = this.$route.params;
    if (id) {
      this.resetItem();
      this.fetchItem({ id });
    }
  },
};
</script>
