import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cp-dialog',{attrs:{"header-background":"","max-width":"640"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VBtn,{attrs:{"slot":"activator","color":"error","text":""},slot:"activator"},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-close")]),_vm._v(" Cancel Import ")],1)]},proxy:true},{key:"title",fn:function(){return [_vm._v(" Cancel Import ")]},proxy:true},{key:"body",fn:function(){return [_c('p',{staticClass:"text-subtitle-1 font-weight-bold"},[_vm._v(" Are you sure you want to abandon this import? Any unsaved changes will be lost. ")])]},proxy:true},{key:"actions",fn:function(ref){
var close = ref.close;
return [_c(VSpacer),_c('cp-btn',{attrs:{"text":""},on:{"click":close}},[_vm._v(" Cancel ")]),_c('cp-btn',{staticClass:"pa-4 mb-2",attrs:{"color":"error"},on:{"click":function($event){_vm.resetMapping();
        close();}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-alert")]),_vm._v(" Confirm Reset ")],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }