import {
  toggle,
  includesObject,
  findByHas,
  removeByKey,
  findByObj
} from "carrot-patch-v2/src/utils/arrayUtils";
import { slugify } from "carrot-patch-v2/src/utils/stringUtils";

const defaultState = () => {
  return {
    itemsPerPage: 10,
    page: 1,
    sortBy: null,
    sortDesc: null,
    q_text: null,
    filters: [],
    toggles: []
  };
};

export default {
  namespaced: true,

  state: defaultState,

  mutations: {
    clear(state) {
      Object.assign(state, defaultState());
    },

    setFilters(state, filters) {
      state.filters = filters;
    },

    registerToggles(state, toggles) {
      // if we pass a value when we register, set the fitler accordingly
      toggles.forEach(({ value, filter, exclusive }) => {
        if (exclusive) {
          const filterKey = Object.keys(filter)[0];
          removeByKey(state.filters, filterKey);
        }
        if (typeof value != "undefined") {
          toggle(state.filters, filter, value);
        }
      });
      state.toggles = toggles.map(toggle => ({
        ...toggle,
        slug: toggle.slug || slugify(toggle.label)
      }));
    }
  },

  actions: {
    clear({ commit }) {
      commit("clear");
    },

    clearFiltersAndToggles({ state }) {
      state.filters = [];
      state.toggles = [];
    },

    toggleFilter({ state }, slug) {
      const filter = state.toggles.find(t => t.slug === slug);
      const filterKey = Object.keys(filter.filter)[0];
      const clearOtherFilters =
        filter.exclusive &&
        !findByObj(state.filters, filter.filter) &&
        findByHas(state.filters, filterKey);
      if (clearOtherFilters) {
        removeByKey(state.filters, filterKey);
      }
      toggle(state.filters, filter.filter);
    }
  },

  getters: {
    toggles({ toggles, filters }) {
      return toggles.map(toggle => ({
        ...toggle,
        value: includesObject(filters, toggle.filter)
      }));
    }
  }
};
