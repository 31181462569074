import { render, staticRenderFns } from "./LocationTypeCell.vue?vue&type=template&id=04ed55ed&scoped=true&"
import script from "./LocationTypeCell.vue?vue&type=script&lang=js&"
export * from "./LocationTypeCell.vue?vue&type=script&lang=js&"
import style0 from "./LocationTypeCell.vue?vue&type=style&index=0&id=04ed55ed&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04ed55ed",
  null
  
)

export default component.exports