<template>
  <div>
    <h4 v-if="action == 'create'" class="font-weight-bold">
      Created with the Following
    </h4>
    <h4 class="formattedChange" v-html="formattedChange" />
  </div>
</template>

<script>
import { snakeToTitleCase } from "carrot-patch-v2/src/utils/stringUtils";

function formatIt(value) {
  if (typeof value === "number") return value.toString();
  if (!value) return "<em>empty</em>";
  if (typeof value === "string") return value;
  if (Array.isArray(value)) {
    value = value.map(x => (x ? x : "<em>empty</em>"));
    // value 1 => value 2
    if (value.length === 2) return value.join(" &rarr; ");
    // otherwise \n
    return value.join("\n");
    // maybe in the future...
    // return value.map(formatIt).join("\n");
  }
  if (typeof value === "object") {
    return Object.keys(value)
      .map(key => {
        const title = snakeToTitleCase(key);
        let detail = formatIt(value[key]);
        if (detail.includes("\n")) {
          detail = "\n    " + detail.replace("\n", "\n    ");
        }
        return `${title}: ${detail}`;
      })
      .join("\n");
  }
  // we shouldn't ever get here... but if we do...
  console.error("Audits.vue/formatIt() couldn't format:");
  console.error(value);
}

export default {
  props: {
    action: { type: String, required: true },
    changes: { type: Object, required: true }
  },
  computed: {
    formattedChange() {
      if (this.action === "destroy") return "Archived";
      return formatIt(this.changes);
    }
  }
};
</script>

<style scoped>
.formattedChange {
  white-space: pre;
}
</style>
