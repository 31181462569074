<template>
  <cp-confirmation-dialog
    :show.sync="model.actionConfirmation.show"
    :store-action-callback="model.actionConfirmation.successCallback"
    :store-action-callback-data="model.actionConfirmation.successCallbackData"
    max-width="480"
    @modalClosed="
      $store.commit(`${model.singularName}/setConfirmationDialog`, {
        isOpen: false
      })
    "
  >
    <template v-if="model.actionConfirmation.confirmationTitle" #title>
      {{ model.actionConfirmation.confirmationTitle }}
    </template>

    <template v-if="model.actionConfirmation.confirmationBody" #body>
      {{ model.actionConfirmation.confirmationBody }}
    </template>
  </cp-confirmation-dialog>
</template>

<script>
export default {
  props: {
    model: { type: Object, default: {} }
  }
};
</script>
