import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"text-h6 font-weight-bold mb-10"},[_vm._v(" Currently mapping "),_c(VChip,{staticClass:"ml-2"},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-table-large")]),_vm._v(" "+_vm._s(_vm.file.instance.upload_details.sheet_name)+" ")],1)],1),_c(VRow,{staticClass:"text-subtitle-1 font-weight-bold",attrs:{"dense":""}},[_c(VCol,{staticClass:"text-right",attrs:{"cols":"3"}},[_vm._v("Swift Bunny Field")]),_c(VCol,{attrs:{"cols":"2"}}),_c(VCol,{attrs:{"cols":"7"}},[_vm._v("Your Column Name")])],1),_c(VDivider),_vm._l((_vm.file.instance.mapping.system_columns.filter(
      function (x) { return x.required; }
    )),function(mapping){return _c('field-map-line-item',{key:mapping.id,attrs:{"mapping":mapping}})}),_vm._l((_vm.file.instance.mapping.system_columns.filter(
      function (x) { return !x.required; }
    )),function(mapping){return _c('field-map-line-item',{key:mapping.id,attrs:{"mapping":mapping}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }