import GegraphicalGroupModel from "../mixins/GeographicalGroupModel";
import search from "@/store/modules/search";

const areaSearch = search.register("areas");

export const area = new GegraphicalGroupModel({
  module: "area",
  table: {
    headers: [
      {
        text: "Regions",
        value: "regions.length",
      },
    ],
  },
  search: areaSearch,
  addFields: ["group_manager_ids"],
  detailTabs: ["general", "regions", "history"],
});

window.$area = area;

export default area.toVuex;
