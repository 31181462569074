<template>
  <div>
    <cp-h1>
      {{ title }}

      <template #subtitle>Total: {{ meta.pagination.records }}</template>

      <template #right>
        <cp-filters-menu :module="table" />
      </template>
    </cp-h1>

    <v-card>
      <Table
        v-bind="tableProps"
        v-on="tableListeners"
        v-model="tableSelected"
        class="clickable-rows"
        @click:row="
          ({ id }) => $router.push({ name: 'auditDetail', params: { id } })
        "
      >
        <template #item.editor="{value}">
          <span>
            <span v-if="value.editor_first_name || value.editor_last_name"
              >{{ value.editor_first_name }} {{ value.editor_last_name }}</span
            >
            <span v-else>{{ value.editor_note }}</span>
          </span>
        </template>

        <template #item.created_at="{value}">
          {{ toMDYTime(value) }}
        </template>

        <template #item.name="{item}">
          <action-chip
            v-if="item.auditable_type === 'EtlMap'"
            action="etl"
            small
          />
          <span v-else-if="item.name">{{ item.name }}</span>
        </template>

        <template #item.action="{value}">
          <action-chip :action="value" small class="pl-2" />
        </template>

        <template #item.summary="{item}">
          <div v-if="item.action === 'update' && nonEmpty(item.changes)">
            <div
              v-for="([p, n], key) in item.changes"
              :key="key"
              class="text-truncate"
            >
              <span class="mr-3">{{ key }}:</span>
              <span class="text-caption">
                <span v-if="p">{{ p }}</span>
                <span v-else class="font-italic">empty</span>
                &rarr;
                <span v-if="n">{{ n }}</span>
                <span v-else class="font-italic">empty</span>
              </span>
            </div>
          </div>
          <div v-if="item.action === 'create'">
            <div v-if="item.auditable_type === 'User'">
              New user:&nbsp;<span class="text-caption">{{ item.name }}</span>
              <div v-if="item.position" class="text-truncate">
                Position: <span class="text-caption">{{ item.position }}</span>
              </div>
              <div v-if="item.location" class="text-truncate">
                Location: <span class="text-caption">{{ item.location }}</span>
              </div>
            </div>
            <div v-else-if="item.auditable_type === 'EtlMap'">
              New transform for
              <span class="text-caption">{{ item.changes.extract_id }}</span>
            </div>
          </div>
        </template>
      </Table>
    </v-card>

    <bulk-action-modals v-bind="bulkActionsMenuProps" />
  </div>
</template>

<script>
import onWindowFocus from "@cp/mixins/onWindowFocus";
import { toMDYTime } from "@cp/utils/dateUtils";
import { nonEmpty } from "@cp/utils/itertees";

import { audit } from "@/store/modules/audit";

export default {
  mixins: [
    audit.table.mixin,
    onWindowFocus(function() {
      this.fetchItems();
    }),
  ],
  props: {
    title: { type: String, default: "Change History" },
  },
  methods: { toMDYTime, nonEmpty },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.refreshTable());
  },
};
</script>
