<template>
  <v-card color="#e7eaf4">
    <v-card-title class="pa-1 d-flex align-center">
      <span class="mr-auto">Changes</span>

      <template v-if="editingRecord">
        <v-btn
          @click="cancelEditRecord"
          :loading="file.saving"
          color="white"
          rounded
          small
        >
          Cancel
        </v-btn>

        <v-btn
          color="success"
          rounded
          small
          depressed
          @click="update"
          :loading="file.saving"
        >
          <v-icon left>mdi-content-save</v-icon>
          Save Changes
        </v-btn>
      </template>

      <template v-else>
        <v-btn
          @click="editRecord"
          :loading="file.saving"
          color="white"
          rounded
          small
          depressed
        >
          <v-icon left>mdi-pencil</v-icon>
          Edit
        </v-btn>
        <v-btn
          @click="acceptChanges"
          :loading="file.saving"
          color="success"
          rounded
          small
          depressed
        >
          <v-icon left>mdi-check</v-icon>
          Approve Changes
        </v-btn>
      </template>
    </v-card-title>

    <v-divider />

    <div
      v-if="item.records.differences.includes('last_archived_at')"
      class="text-center mt-1"
    >
      <span class="mr-2">{{ pluralize(item.changeable_type, 1) }} will be</span>
      <v-chip :color="item.pending_action !== 'delete' ? 'success' : 'error'">
        {{ item.pending_action !== "delete" ? "Active" : "Archived" }}
      </v-chip>
    </div>

    <div v-if="editingRecord" class="ModelForm">
      <model-form
        :modelModule="modelModule"
        :filter-fields="item.editable_fields"
        :cols="{ md: 12 }"
        :fieldProps="{ hideDetails: 'auto' }"
        mode="import"
      />
    </div>

    <div v-else class="change-sheets">
      <v-sheet
        v-for="(label, i) in orderedLabels"
        :key="i"
        rounded
        class="my-1 pa-1 py-0"
        :class="{
          ignored: showAttributeIgnored(label),
          willChange: item.records.differences.includes(label),
        }"
      >
        <v-tooltip v-if="showAttributeIgnored(label)" left>
          <template #activator="{on, attrs}">
            <v-icon v-bind="attrs" v-on="on" x-large class="frozenIcon"
              >mdi-sync-off</v-icon
            >
          </template>
          <span>
            This attribute will be ignored in all future imports of this record
          </span>
        </v-tooltip>
        <span class="text-caption">{{ snakeToTitleCase(label) }}</span>
        <br />
        <span v-if="checkForRemoved(label)" class="text-subtitle-2">
          (removed)
        </span>
        <span
          v-else-if="version[label] === null || version[label] === ''"
          class="text-subtitle-2"
          style="color: #aaaaaa;"
        >
          –
        </span>
        <span v-else class="text-subtitle-2">
          {{ version[label] }}
        </span>
      </v-sheet>
    </div>

    <div class="pa-1 d-flex justify-end">
      <template v-if="editingRecord">
        <v-btn
          @click="cancelEditRecord"
          :loading="file.saving"
          color="white"
          rounded
          small
        >
          Cancel
        </v-btn>

        <v-btn
          color="success"
          rounded
          small
          depressed
          @click="update"
          :loading="file.saving"
        >
          <v-icon left>mdi-content-save</v-icon>
          Save Changes
        </v-btn>
      </template>

      <template v-else>
        <v-btn
          @click="editRecord"
          :loading="file.saving"
          color="white"
          rounded
          small
          depressed
        >
          <v-icon left>mdi-pencil</v-icon>
          Edit
        </v-btn>
        <v-btn
          @click="acceptChanges"
          :loading="file.saving"
          color="success"
          rounded
          small
          depressed
        >
          <v-icon left>mdi-check</v-icon>
          Approve Changes
        </v-btn>
      </template>
    </div>
  </v-card>
</template>

<script>
import pluralize from "pluralize";
import { mapState, mapGetters, mapActions } from "vuex";

import { get } from "@cp/utils/objectUtils";
import { snakeToTitleCase } from "@cp/utils/stringUtils";

import { pendingChange, etlForm } from "@/store/modules/pendingChange";
import orderedLabels from "@/mixins/pendingChangeOrderedLabels";

export default {
  computed: {
    ...mapState({
      file: state => state.file,
    }),
    ...mapState(pendingChange.mp, ["item"]),
    ...mapState(etlForm.mp, ["etl"]),
    ...mapGetters(pendingChange.mp, { modelModule: "polyModule" }),
    version() {
      return this.item.records.new_version;
    },
    prevVersion() {
      return this.item.records.current_version;
    },
    orderedLabels,
    showStatusChange() {
      return ["delete", "restore", ""].includes(this.item.pending_action);
    },
    editingRecord: {
      get() {
        return get(this.$store.state, pendingChange.p.s.editingRecord);
      },
      set(v) {
        const state = get(this.$store.state, pendingChange.mp);
        state.editingRecord = v;
      },
    },
  },

  methods: {
    pluralize,
    snakeToTitleCase,
    ...mapActions("file", ["updatePendingChange"]),
    ...mapActions(pendingChange.mp, [
      "update",
      "fetchItem",
      "editRecord",
      "cancelEditRecord",
      "acceptChanges",
    ]),
    checkForRemoved(label) {
      return this.prevVersion[label] !== null && this.version[label] === null;
    },

    showAttributeIgnored(label) {
      // show frozen when the user is adding an ignore_attributes ETL
      if (this.etl.transform_type !== "ignore_attributes") return false;

      // only show frozen if the attribute is in the etlForm
      return this.etl.transformations.ignore_attributes.includes(label);
    },
  },
};
</script>

<style scoped lang="scss">
.v-sheet {
  position: relative;

  &.willChange {
    background: #ffecb3;
  }

  &.ignored {
    background: #ddd;
  }
}

.frozenIcon {
  float: right;
  margin: 2px 0 -8px 12px;
}

.change-sheets {
  padding: 2px;
}

.ModelForm::v-deep {
  .row.row--dense {
    margin: 2px;

    & > div {
      margin: 2px 0;
      background: #fff;
      border-radius: 2px;
    }
  }
}
</style>
