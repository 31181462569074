<template>
  <div class="etl-form text-center">
    <cp-card v-if="isCreating">
      <template #title>Adding: {{ selectedOption.text }}</template>

      <h2>What will this do</h2>
      <p v-if="etl.transform_type === 'ignore_record'">
        Ignore future imports of this
        <strong>{{ modelModule.noun }}</strong>
        <extract-id-form />
      </p>

      <div v-if="etl.transform_type === 'merge_record'">
        TODO -- merge record form
      </div>

      <div v-if="etl.transform_type === 'ignore_attributes'">
        <p>
          When importing
          <v-tooltip top>
            <template #activator="{on, attrs}">
              <span v-bind="attrs" v-on="on">
                this <strong>{{ record.singularName | capitalize }}</strong>

                <sup>
                  <v-icon x-small>
                    mdi-information-outline
                  </v-icon>
                </sup>
              </span>
            </template>
            Remote ID: {{ item.reference_id }}
          </v-tooltip>
          the import will ignore
          <template v-if="ignoredAttributes && ignoredAttributes.length">
            these fields:<br />
            <v-chip
              v-for="attribute in ignoredAttributes"
              :key="attribute"
              class="ma-1"
            >
              {{ attribute | snakeToTitleCase }}
            </v-chip>
          </template>
          <template v-else>
            the fields you select.
          </template>
        </p>
      </div>

      <div v-if="etl.transform_type === 'prevent_events'">
        TODO -- ignore record form
      </div>

      <template v-if="selectedOption.use_case">
        <h2>When to use this</h2>
        <p>{{ selectedOption.use_case }}</p>
      </template>

      <v-btn color="primary" @click="submit" :disabled="submitDisabled">
        <v-icon class="mr-2">mdi-plus</v-icon>
        Add
      </v-btn>

      <v-btn class="ml-4" @click="resetEtl">
        <v-icon>mdi-cancel</v-icon>
        Cancel
      </v-btn>
    </cp-card>

    <div v-else-if="options.length" class="d-flex align-center justify-center">
      <v-menu offset-y>
        <template #activator="{on, attrs}">
          <v-btn outlined color="warning" v-bind="attrs" v-on="on">
            <v-icon left>mdi-plus</v-icon>
            Transformation
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="{ id, text, description, transform_type } in options"
            :key="id"
            :title="description"
            @click="open({ transform_type })"
          >
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import { findByObj } from "carrot-patch-v2/src/utils/arrayUtils";
import { get } from "carrot-patch-v2/src/utils/collectionUtils";
import { transforms } from "@/store/modules/file.js";

import { pendingChange, etlForm } from "@/store/modules/pendingChange";

export default {
  props: {
    context: { type: String, default: "import" },
  },
  computed: {
    ...mapState(pendingChange.mp, ["item"]),
    ...mapState(etlForm.mp, ["etl"]),
    ...mapGetters(pendingChange.mp, {
      modelModule: pendingChange.keys.polyModule,
    }),
    isCreating() {
      return !this.etl.id && this.etl.transform_type;
    },
    isEditing() {
      return !!this.etl.id;
    },
    options() {
      const alreadyAppliedTypes = get(this.item, "transformations", []).map(
        t => t.transform_type
      );
      const applicableTypes = get(
        this.item,
        `transformation_options.${this.context}`,
        []
      );
      const availableOptions = applicableTypes.filter(
        t => !alreadyAppliedTypes.includes(t)
      );
      if (!availableOptions || !availableOptions.length) return false;
      return transforms.filter(t =>
        availableOptions.includes(t.transform_type)
      );
    },
    selectedOption() {
      const transform_type = this.etl.transform_type;
      if (!transform_type) return false;
      return findByObj(transforms, { transform_type });
    },
    referenceId() {
      return this.item.reference_id;
    },
    submitDisabled() {
      switch (this.etl.transform_type) {
        case "ignore_attributes":
          return !this.etl.transformations.ignore_attributes.length;
        case "merge_record":
          return !this.etl.relatedId;
      }
      return false;
    },
    ignoredAttributes() {
      return get(this.etl, "transformations.ignore_attributes");
    },
  },
  methods: {
    ...mapActions(etlForm.mp, ["submit", "open"]),
    ...mapMutations(etlForm.mp, ["resetEtl"]),
  },
};
</script>

<style scoped lang="scss">
h2 {
  color: $gray;
  font-size: 18px;
  border-bottom: 1px solid $gray-2;
}
</style>
