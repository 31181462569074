<template>
  <div>
    <div class="d-flex align-stretch justify-center">
      <linked-location-card :location="office" />

      <div class="text-center mx-5 align-self-center">
        <v-icon class="mx-5" huge>mdi-link-plus</v-icon><br />
        Create Link
      </div>

      <linked-location-card :location="property" />
    </div>

    <div class="d-flex justify-end mt-6">
      <div v-if="success" class="d-flex align-center">
        <CpSuccessIcon class="mr-4" xLarge />
        {{ action.successMessage || "Success!" }}
      </div>
      <template v-else>
        <v-btn @click="action.modalConf.close" class="mr-4">Cancel</v-btn>
        <v-btn @click="() => confirm()" color="primary" :loading="submitting">
          Link Locations
        </v-btn>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { get } from "@cp/utils/objectUtils";

import { location } from "@/store/modules/location";

const SUB = location.table.subModuleRefs.bulkLink;

export default {
  props: {
    selected: { type: Array, required: true },
    action: { type: Object, required: true },
  },

  computed: {
    office() {
      return this.selected.find(
        ({ location_type }) => location_type === "office"
      );
    },
    property() {
      return this.selected.find(
        ({ location_type }) => location_type === "property"
      );
    },
    success() {
      return get(this.$store.state, SUB.p.s.success);
    },
    submitting() {
      return get(this.$store.state, SUB.p.s.submitting);
    },
  },

  methods: mapActions(SUB.mp, ["confirm"]),
};
</script>
