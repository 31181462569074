import { Model } from "@/store/mixins/Model";

export const audit = new Model({
  module: "audit",
  noun: "data change",
  table: {
    exportOptions: { downloadXlsx: false },
    headers: [
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Data Type",
        value: "auditable_type",
        sortable: false,
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
      },
      {
        text: "Summary",
        value: "summary",
        sortable: false,
      },
      {
        text: "Date",
        value: "created_at",
      },
      {
        text: "Editor",
        value: "editor",
      },
    ],
    filters: [
      "q_text",
      {
        label: "General",
        type: "select",
        key: "general",
        items: [
          {
            label: "Creates",
            value: "create",
          },
          {
            label: "Changes",
            value: "changed",
          },
          {
            label: "Archives",
            value: "archived",
          },
          {
            label: "Restores",
            value: "restored",
          },
        ],
      },
      {
        label: "Data Type",
        type: "select",
        key: "auditable_classes",
        items: [
          {
            label: "Employees",
            value: "User",
          },
          {
            label: "Locations",
            value: "Location",
          },
          {
            label: "Positions",
            value: "Position",
          },
          {
            label: "Geographical Groups",
            value: "GeographicalGroup",
          },
          {
            label: "Units",
            value: "Unit",
          },
          {
            label: "Leases",
            value: "Lease",
          },
          {
            label: "Residents",
            value: "Resident",
          },
        ],
      },
      {
        label: "Import",
        type: "sub_query",
        key: "imports",
      },
      {
        label: "Employee",
        type: "sub_query",
        key: "employees",
      },
      {
        label: "Location",
        type: "sub_query",
        key: "locations",
      },
      {
        label: "Position",
        type: "sub_query",
        key: "positions",
      },
      {
        label: "Geographical Group",
        type: "sub_query",
        key: "geographical_groups",
      },
    ],
    useGlobalBulkActions: false,
  },
});

window.$audit = audit;

export default audit.toVuex;
