import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VTabs,{attrs:{"slider-color":"primary","background-color":"#f1f1f1"},on:{"change":_vm.changeTab},model:{value:(_vm.currentIndex),callback:function ($$v) {_vm.currentIndex=$$v},expression:"currentIndex"}},[_c(VTabsSlider),_vm._l((_vm.tabs),function(tab){return _c(VTab,{key:tab},[_vm._v(_vm._s(_vm.kebabToSentenceCase(tab)))])})],2),_c(VCardText,[_c(VTabsItems,{model:{value:(_vm.currentIndex),callback:function ($$v) {_vm.currentIndex=$$v},expression:"currentIndex"}},_vm._l((_vm.tabs),function(tab){return _c(VTabItem,{key:tab},[_c('cp-loading',{attrs:{"loading":_vm.loading}},[(tab !== 'general')?_c('cp-unhandled-errors',{attrs:{"formConf":_vm.formConf}}):_vm._e(),_c((tab + "-tab"),_vm._b({tag:"component"},'component',_vm.$props,false))],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }