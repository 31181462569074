import List from "./List.vue";
import Detail from "./Detail.vue";
import Create from "./Create.vue";

export default [
  {
    path: "regions",
    name: "Regions",
    component: List,
    meta: {
      title: "Regions",
    },
  },
  {
    path: "/regions/create",
    name: "addRegion",
    component: Create,
    meta: {
      title: "Add Region",
      parent: "Regions",
    },
  },
  {
    path: "/regions/:id",
    name: "regionDetail",
    component: Detail,
    meta: {
      title: "Edit Region",
      mode: "edit",
      parent: "Regions",
    },
  },
];
