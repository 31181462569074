import Crud from "./generic";
let base = new Crud("unit", "units", "units", "unit");

export default {
  namespaced: true,

  state: {
    ...base.state,
    url: "/units",
    recordDetailTabs: ["General", "Related Items", "History"],
    listTableHeaders: [
      { text: "", value: "alert", sortable: false, width: "20px" },
      {
        text: "Unit #",
        value: "unit_number",
        width: "auto",
        linkCell: true,
      },
      {
        text: "Location",
        value: "location_name",
        width: "auto",
      },
      {
        text: "Building Name",
        value: "building_name",
        width: "auto",
      },
      {
        text: "Floor #",
        value: "floor_number",
        width: "auto",
      },
      {
        text: "Type",
        value: "unit_type",
        width: "auto",
      },
      {
        text: "Availability",
        value: "availability_type",
        width: "auto",
      },
      {
        text: "Lease End Date",
        value: "lease_to_date",
        width: "auto",
      },
    ],
  },

  getters: {
    ...base.getters,

    formFields() {
      return [
        "external_id",
        "unit_status",
        "location_id",
        "unit_number",
        "building_name",
        "floor_number",
        "unit_type",
        "availability_type",
        "occupants",
        "bedrooms",
        "bathrooms",
        "square_feet",
        "max_occupants",
        "max_pets",
        "address",
        "address_2",
        "city",
        "state",
        "zip",
        "affordable",
        "conventional",
        "min_rent",
        "max_rent",
      ].map(id => ({
        text: id,
        value: id,
        type: "text",
        importEditable: true,
        meta: { disabled: true },
      }));
    },

    bulkActions(state, getters) {
      return [...getters.globalBulkActions];
    },

    instanceActions(state, getters) {
      return [...getters.globalInstanceActions];
    },
  },

  actions: {
    ...base.actions,

    openEditDialog({ state, commit, dispatch }, id) {
      this._vm.$CpEvent.$emit("resetModelForm");
      dispatch("fetchPositionCategories");
      dispatch("fetchItem", id);
      dispatch("fetchAudits", { id });
      commit("setEditDialogWindow", true);
    },

    openCreateDialog({ state, commit, dispatch, getters }) {
      this._vm.$CpEvent.$emit("resetModelForm");
      dispatch("fetchPositionCategories");
      commit("setCreateDialogWindow", true);
      commit(
        "setExistingRecordHash",
        state.trackChangesHash(getters.trackedProperties, state.item)
      );
    },

    fetchPositionCategories({ state, commit }) {
      return state.client
        .authorize()
        .get("/position_categories/autocomplete")
        .then(({ data }) => {
          commit(
            "setPositionCategories",
            data.map(x => ({ text: x.attributes.name, value: x.id }))
          );
        });
    },

    massAssignCategory({ state, dispatch }, { category_id, position_ids }) {
      state.saving = true;

      return state.client
        .authorize()
        .put("/positions/assign_categories", {
          positions: position_ids.map(x => ({
            position_id: x,
            generalized_position_category_id: category_id,
          })),
        })
        .then(data => {
          this._vm.$CpEvent.$emit(`bulkActionSuccess`);
          this._vm.$CpEvent.$emit(`snackAlert`, {
            message: "Positions have had their category updated",
          });
        })
        .finally(() => {
          state.saving = false;
        });
    },
  },

  mutations: {
    ...base.mutations,

    setPositionCategories(state, categories) {
      state.positionCategories = categories;
    },
  },
};
