import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3"},[_c(VBtn,{staticClass:"mb-3",attrs:{"color":"primary"},on:{"click":_vm.openAssignRegions}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-plus")]),_vm._v("Assign New Regions ")],1),_c(VExpansionPanels,{attrs:{"multiple":""}},_vm._l((_vm.model.item.regions),function(region){return _c(VExpansionPanel,{key:region.id},[_c(VExpansionPanelHeader,[_c('span',[_c(VBtn,{attrs:{"icon":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.removeRegion(region.id)}}},[_c(VIcon,[_vm._v("mdi-close")])],1),_vm._v(" "+_vm._s(region.name)+" ")],1)]),_c(VExpansionPanelContent,[_vm._v(" Employees: "+_vm._s(region.user_count)+" ")])],1)}),1),_c('cp-dialog',{attrs:{"show-model":_vm.area.regionAssignmentDialogOpen,"max-width":"1100","header-background":"","scrollable":""},on:{"update:showModel":function($event){return _vm.$set(_vm.area, "regionAssignmentDialogOpen", $event)},"update:show-model":function($event){return _vm.$set(_vm.area, "regionAssignmentDialogOpen", $event)},"modalClosed":function($event){_vm.tableOptions.q_text = null}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Assign Regions ")]},proxy:true},{key:"body",fn:function(){return [_c('list-table',{attrs:{"static-headers":_vm.locHeaders,"model-name":"region","hide-header":"","hide-filters":"","hide-bulk-actions":"","flat":""}})]},proxy:true},{key:"actions",fn:function(){return [_c(VBtn,{attrs:{"color":"primary","disabled":_vm.region.saving || _vm.region.bulkSelection.length <= 0,"loading":_vm.region.saving},on:{"click":_vm.assignRegions}},[_vm._v(" Assign Regions to Area ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }