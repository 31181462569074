<template>
  <div>
    <p>Assign all selected positions to this category</p>
    <v-autocomplete
      v-model="model.BulkAssignPositionCategoryTarget"
      label="Category"
      :items="model.positionCategories"
      :loading="model.searching"
      :allow-overflow="false"
      no-data-text="Type to search"
      cache-items
      @focus="getPositionCategories"
    />
  </div>
</template>

<script>
export default {
  props: {
    model: { type: Object, default: () => {} }
  },

  methods: {
    getPositionCategories() {
      if (this.model.positionCategories.length <= 0) {
        this.$store.dispatch("position/fetchPositionCategories");
      }
    }
  }
};
</script>
