import Crud from "./generic";
let base = new Crud("etl_map", "etl_maps", "etl_maps", "etl_map");

const modelToRouteMap = {
  User: "Employees",
  Location: "Locations",
  Position: "Positions",
  GeographicalGroup: ({ depth, id }) => ({
    name: "Company",
    params: { depth, uuid: id }
  }),
  Unit: "Units",
  Lease: "Leases",
  Resident: "Residents"
};

export default {
  namespaced: true,

  state: {
    ...base.state,
    noun: "Record",
    listTableHeaders: [
      {
        text: "Name",
        value: "name",
        linkCell: true
      }
    ]
  },

  actions: {
    ...base.actions
  },

  getters: {
    ...base.getters,
    getEditModel(state, getters, rootState) {
      return "users";
    },
    bulkActions: () => [],
    autocompleteUrl(state, getters, rootState) {
      const uuid = rootState.import.item.id;
      return `import_history/${uuid}/audit`;
    },
    itemRoute() {
      return ({ auditable_type, audited_object }) => {
        const route = modelToRouteMap[auditable_type];
        if (!route) return false;
        if (typeof route === "string")
          return {
            name: route,
            params: { uuid: audited_object.id }
          };
        if (typeof route === "function") return route(audited_object);
      };
    },
    listTableHeaders({ listTableHeaders }, getters, rootState, rootGetters) {
      const columns = [...listTableHeaders];

      const slug = rootGetters["import/currentTab"].slug;
      switch (slug) {
        case "create":
          columns.push({
            text: "New Entry",
            value: "component_cell",
            component: "import-audit-cell"
          });
          break;
        case "update":
          columns.push({
            text: "Changes Made",
            value: "component_cell",
            component: "import-audit-cell"
          });
          break;
        case "delete":
        case "archived":
        case "restored":
        case "restore":
          columns.push({
            text: "Status",
            value: "component_cell",
            component: "import-audit-status-cell",
            width: "100px"
          });
          break;
      }
      return columns;
    }
  },

  mutations: {
    ...base.mutations
  }
};
