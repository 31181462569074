<template>
  <div class="d-flex">
    <component v-if="statusComponent" :is="statusComponent" :model="model" />
    <div v-if="actions" class="d-flex">
      <div v-for="action in actions" :key="action.title">
        <v-divider v-if="action.showDividerBefore" vertical class="mx-5 ml-7" />
        <cp-btn
          @click="$emit('action', action)"
          :disabled="action.disabled"
          :loading="model.saving"
          :color="action.color || 'primary'"
          text
        >
          <v-icon v-if="action.icon != null" class="mr-2" small>
            mdi-{{ action.icon }}
          </v-icon>
          {{ action.title }}
        </cp-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: { type: Object, required: true }
  },
  computed: {
    actions() {
      const actions = this.$store.getters[
        `${this.model.singularName}/instanceActions`
      ];
      if (!actions || !actions.length) return false;
      return actions.filter(a => a.shouldShow);
    },
    statusComponent() {
      return this.model.statusComponent || false;
    }
  }
};
</script>
