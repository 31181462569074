import List from "./List.vue";
import Detail from "./Detail.vue";

export default [
  {
    path: "/audits",
    name: "Audits",
    component: List,
    meta: {
      title: "Data Changes",
    },
  },
  {
    path: "/audits/:id",
    name: "auditDetail",
    component: Detail,
    meta: {
      title: "Data Change Detail",
    },
  },
];
