import format from "./FormatFnCell.vue";
import component_cell from "./ComponentCell.vue";

import DeficiencyAlert from "./DeficiencyAlert.vue";
import StatusId from "./StatusId.vue";
import ManagersCell from "./ManagersCell.vue";
import GroupManagersCell from "./GroupManagersCell.vue";
import LocationTypeCell from "./LocationTypeCell.vue";
import FullNameCell from "./FullNameCell.vue";
import LocationCell from "./LocationCell.vue";

export default {
  format,
  component_cell,
  "item.alert": DeficiencyAlert,
  "item.status_id": StatusId,
  "item.managers": ManagersCell,
  "item.group_managers": GroupManagersCell,
  "item.location_type_id": LocationTypeCell,
  "item.full_name": FullNameCell,
  "item.location": LocationCell,
};
