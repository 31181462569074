import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.userIsSwiftbunny && _vm.show)?_c('div',[(_vm.progress >= 100)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({},on),[_vm._v(" mdi-check-circle ")])]}}],null,false,683668570)},[_vm._v(" Import step complete! ")]):(_vm.progress == 0)?_c(VIcon,{attrs:{"color":"warning"}},[_vm._v(" mdi-information ")]):(_vm.progress != null)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VProgressCircular,_vm._g({attrs:{"value":_vm.progress,"size":"24"}},on))]}}],null,false,712241699)},[_vm._v(" "+_vm._s(_vm.progress)+"% Complete ")]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }