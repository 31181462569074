<template>
  <div>
    <cp-h1>
      Dashboard
    </cp-h1>

    <v-row class="mb-10">
      <v-col v-for="card in cards" :key="card.model">
        <v-card class="StatsCard">
          <v-card-text>
            <div class="primaryBlock">
              <div class="primaryValue">{{ card.stats[0].value }}</div>
              <div class="primaryLabel">{{ card.title }}</div>
              <div class="buttons">
                <v-btn color="primary" :to="card.viewRoute">View</v-btn>
                <v-btn color="primary" :to="card.addRoute">Add</v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <CurrentImport />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import CurrentImport from "./import/CurrentImport.vue";

export default {
  name: "Dashboard",
  components: { CurrentImport },
  computed: {
    ...mapState("file", { hasImport: file => file.collection.length }),
    ...mapGetters("dashboard", ["cards"]),
    ...mapGetters("auth", ["userIsSwiftbunny"]),
  },

  mounted() {
    this.$store.dispatch("file/fetchImportStatus");
    this.$store.dispatch(`dashboard/fetchCards`);
  },
};
</script>

<style lang="scss" scoped>
.cards {
  display: flex;
  gap: 15px;
  justify-content: stretch;
  align-items: center;
}
.StatsCard {
  .primaryBlock {
    display: flex;
    flex-direction: column;
    min-height: 0;
    max-height: 100%;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .primaryValue {
    font-size: 2.5rem;
    line-height: 3.5rem;
    font-weight: lighter;
  }
  .primaryLabel {
    line-height: 1em;
    overflow: hidden;
    min-height: 0;
  }
  .buttons {
    margin-top: 15px;
    .v-btn + .v-btn {
      margin-left: 15px;
    }
  }
}
</style>
