<template>
  <div>
    <p>Assign all selected locations to these groups</p>
    <v-autocomplete
      v-model="model.bulkAssignGroupTarget"
      label="Groups"
      :items="options"
      :allow-overflow="false"
      no-data-text="Type to search"
      multiple
      cache-items
    />

    <v-checkbox
      v-model="model.bulkAssignGroupOverride"
      label="Remove previous assignments"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    model: { type: Object, default: () => {} }
  },

  computed: mapState("reporting_group", ["options"]),

  mounted() {
    if (!this.options.length) {
      this.$store.dispatch("reporting_group/fetchOptions");
    }
  }
};
</script>
