import GegraphicalGroupModel from "../mixins/GeographicalGroupModel";
import search from "@/store/modules/search";

const reportingGroupSearch = search.register("reporting_group");

export const reportingGroup = new GegraphicalGroupModel({
  module: "reporting_group",
  noun: "reporting group",
  table: {
    headers: [
      {
        text: "Locations",
        value: "locations.length",
        width: "auto",
      },
    ],
  },
  search: reportingGroupSearch,
  detailTabs: ["general", "locations", "history"],
});

window.$reportingGroup = reportingGroup;

export default reportingGroup.toVuex;
