import List from "./List.vue";
import Detail from "./Detail.vue";

export default [
  {
    path: "history",
    name: "importHistory",
    component: List,
    meta: {
      title: "Import History",
    },
  },
  {
    path: "/summary/:id",
    name: "importSummary",
    component: Detail,
    meta: {
      title: "Import Summary",
      parent: "importHistory",
    },
  },
];
