export function attrOrFn(item, strOrFn) {
  return typeof strOrFn === "function" ? strOrFn(item) : item[strOrFn];
}

export function mapAttrOrFn(attributeName, strOrFn) {
  return function(i) {
    return {
      ...i,
      [attributeName]: attrOrFn(i, strOrFn)
    };
  };
}
