<template>
  <v-tooltip right v-if="item.deficiencies.length > 0">
    <template #activator="{ on, attrs }">
      <v-icon
        small
        class="icon icon-warning"
        color="warning"
        v-bind="attrs"
        v-on="on"
      >
        mdi-alert
      </v-icon>
    </template>
    <h4 v-for="(deficiency, i) in item.deficiencies" :key="i">
      {{ deficiency | snakeToTitleCase }}
    </h4>
  </v-tooltip>
</template>

<script>
export default {
  name: "DeficiencyAlertCell",
  props: ["item"],
};
</script>
