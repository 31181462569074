<template>
  <cp-chip class="slightly-rounded" v-if="!issues.loaded">
    Checking for issues...
    <v-progress-circular indeterminate class="ml-2" :size="18" :width="3" />
  </cp-chip>

  <span v-else>
    <v-btn
      v-if="$store.getters['issues/uniqueRecordCount'][modelName] > 0"
      color="warning"
      href="/data-triage"
    >
      <v-icon small class="mr-1">mdi-alert</v-icon>
      {{ $store.getters["issues/uniqueRecordCount"][modelName] }}
      Issues
    </v-btn>

    <cp-chip class="slightly-rounded text-button" v-else>
      Status OK
      <v-icon color="success">mdi-check</v-icon>
    </cp-chip>
  </span>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    modelName: { type: String, default: "" },
  },

  computed: {
    ...mapState({
      issues: state => state.issues,
    }),
  },
};
</script>
