<template>
  <v-data-iterator
    v-if="items.length > cols"
    :items="items"
    :items-per-page="-1"
    hide-default-footer
    :custom-filter="filter"
    :search="search"
  >
    <template v-slot:header>
      <v-toolbar dark color="blue darken-3" class="mb-4">
        <v-text-field
          v-model="search"
          clearable
          flat
          solo-inverted
          hide-details
          prepend-inner-icon="mdi-magnify"
          label="Search"
        />

        <v-spacer></v-spacer>
        <v-btn @click="toggleAll" large depressed color="blue">
          {{ showAll ? "Hide" : "Show" }} all
        </v-btn>
      </v-toolbar>
    </template>

    <template v-slot:default="props">
      <v-row>
        <v-col v-for="item in props.items" :key="item.name" :cols="cols">
          <model-card :item="item" v-bind="$attrs" ref="cards" />
        </v-col>
      </v-row>
    </template>
  </v-data-iterator>
  <v-row v-else>
    <v-col cols="6" v-for="(item, i) in items" :key="i">
      <model-card :item="item" v-bind="$attrs" default-open />
    </v-col>
  </v-row>
</template>

<script>
import { mapAttrOrFn } from "@/utils/transforms";

export default {
  props: {
    items: { type: Array, required: true },
    cols: { type: Number, default: 4 }
  },
  data() {
    return {
      search: "",
      sortBy: "title",
      showAll: false
    };
  },
  computed: {
    itemsWithTitles() {
      return this.items.map(mapAttrOrFn("title"));
    }
  },
  methods: {
    toggleAll() {
      this.showAll = !this.showAll;
      this.$refs.cards.forEach(c => c.force(this.showAll));
    },
    filter(is, s) {
      if (!s) return is;
      return is.filter(i => i.title.includes(s));
    }
  }
};
</script>
