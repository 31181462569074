<template>
  <v-card>
    <v-card-title>
      <router-link
        v-if="item.id"
        icon
        :to="{ ...route, params: { uuid: item.id } }"
      >
        {{ item.title }}
      </router-link>
      <span v-else>{{ item.title }}</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="show = !show">
        <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>

    <v-list v-show="show" dense>
      <v-list-item v-for="({ title, value }, i) in attrMap" :key="i">
        <v-list-item-content>{{ title }}:</v-list-item-content>
        <v-list-item-content>
          <div class="text-truncate text-right" :title="value">
            {{ value }}
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { snakeToTitleCase } from "carrot-patch-v2/src/utils/stringUtils";
import { nonEmpty } from "carrot-patch-v2/src/utils/itertees";

export default {
  props: {
    item: { type: Object, required: true },
    route: { type: Object },
    attrs: { type: Array, default: () => [] },
    defaultOpen: { type: Boolean, default: false }
  },
  data() {
    return {
      show: this.defaultOpen
    };
  },
  computed: {
    attrMap() {
      return this.attrs
        .filter(attr => nonEmpty(this.item[attr]))
        .map(attr => ({
          title: snakeToTitleCase(attr),
          value: this.item[attr]
        }));
    }
  },
  methods: {
    force(open) {
      this.show = open;
    }
  }
};
</script>
