import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cp-loading',{attrs:{"loading":_vm.countsLoading}},[_c(VRow,[_c(VCol,{attrs:{"cols":"3"}},[_c('cp-h1',{scopedSlots:_vm._u([(_vm.hasIssues)?{key:"subtitle",fn:function(){return [_vm._v(" We've found "+_vm._s(_vm.pluralize("issue", _vm.hasIssues, true))+" ")]},proxy:true}:null],null,true)},[_vm._v(" Data triage ")]),_c(VCard,[_c(VList,{attrs:{"dense":""}},[_vm._l((_vm.models),function(ref){
var model = ref.model;
var label = ref.label;
var counts = ref.counts;
return [_c(VSubheader,{key:(model + "-header")},[_vm._v(_vm._s(label))]),_vm._l((counts),function(typeCount,type){return _c(VListItem,{key:(model + "__" + type),attrs:{"to":{ name: 'DataTriage', query: { model: model, type: type } },"exact":""}},[_c(VListItemContent,{staticClass:"text-subtitle"},[_c(VListItemTitle,[_vm._v(" "+_vm._s(typeCount.label)+" ")]),_c(VListItemSubtitle,{staticClass:"warning--text"},[(typeCount.count > 0)?_c('span',[_vm._v(_vm._s(typeCount.count))]):_c('span',{staticClass:"success--text"},[_vm._v(" No issues ")])])],1),_c(VListItemAction,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-chevron-right")])],1)],1)})]})],2)],1)],1),_c(VCol,{attrs:{"cols":"9"}},[_c('cp-h1',{scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [(_vm.selected.length)?_c('span',[_c('bulk-action-modals',_vm._b({},'bulk-action-modals',_vm.bulkActionsMenuProps,false)),_c('bulk-actions-menu',_vm._b({},'bulk-actions-menu',_vm.bulkActionsMenuProps,false))],1):_c('span',[_vm._v("Total: "+_vm._s(_vm.meta.pagination.records))])]},proxy:true},(_vm.selected.length)?{key:"rightOfTitle",fn:function(){return undefined},proxy:true}:null],null,true)},[_vm._v(" "+_vm._s(_vm.modelLabelMap[_vm.model])+" ")]),_c('Table',_vm._g(_vm._b({staticClass:"clickable-rows",attrs:{"headers":_vm.modelClass.table.headers,"show-select":""},on:{"click:row":_vm.navigateToItem},model:{value:(_vm.tableSelected),callback:function ($$v) {_vm.tableSelected=$$v},expression:"tableSelected"}},'Table',_vm.tableProps,false),_vm.tableListeners))],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }