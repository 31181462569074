<template>
  <v-sheet class="mt-4" elevation="3">
    <v-container>
      <h2 style="color: #666666" class="text-subtitle-1 font-weight-bold">
        Imported Information
      </h2>

      <v-list dense>
        <v-list-item v-for="({ text, value }, i) in importedFields" :key="i">
          <v-list-item-content>{{ text }}:</v-list-item-content>
          <v-list-item-content>
            {{ model.item[value] }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  props: {
    model: { type: Object, default: () => {} }
  },
  computed: {
    importedFields() {
      return this.$store.getters[
        `${this.model.singularName}/formFields`
      ].filter(({ importOnly }) => importOnly);
    }
  }
};
</script>
