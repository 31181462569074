<template>
  <div class="d-flex">
    <v-dialog v-if="transforms && transforms.length">
      <template #activator="{on, attrs}">
        <v-btn text color="primary" v-on="on" v-bind="attrs">
          {{ pluralize("transformation", transforms.length, true) }}
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          {{ pluralize("transformation", transforms.length, true) }}
        </v-card-title>

        <v-divider></v-divider>

        <v-simple-table dense>
          <template #default>
            <tbody>
              <tr v-for="(transform, i) in transforms" :key="i">
                <td>{{ i + 1 }}.</td>
                <td style="white-space: pre; font-family: monospace;">
                  {{ JSON.stringify(transform) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-dialog>

    <v-dialog v-if="messages && messages.length">
      <template #activator="{on, attrs}">
        <v-btn text color="warning" v-on="on" v-bind="attrs">
          {{ pluralize("warning", messages.length, true) }}
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          {{ pluralize("warning", messages.length, true) }}
        </v-card-title>

        <v-divider></v-divider>

        <v-simple-table dense>
          <template #default>
            <tbody>
              <tr v-for="(message, i) in messages" :key="i">
                <td>{{ i + 1 }}.</td>
                <td>{{ message }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import pluralize from "pluralize";
import { get } from "carrot-patch-v2/src/utils/objectUtils";
export default {
  props: { model: { type: Object, required: true } },
  data() {
    return {
      transformsDialogOpen: false,
      messagesDialogOpen: false
    };
  },
  computed: {
    messages() {
      return get(this.model, "item.upload_details.messages");
    },
    transforms() {
      return get(this.model, "item.upload_details.invoked_etl_rules");
    }
  },
  methods: {
    pluralize
  }
};
</script>
