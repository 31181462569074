<template>
  <div class="px-3 pb-3">
    <div class="text-bold">
      <v-icon small color="primary">mdi-chevron-right</v-icon>
      {{ timeOfEdit }}
      <span v-if="editorName || editorNote">by</span>
      <span v-if="editorName">
        <v-icon small>mdi-account-circle</v-icon>
        {{ editorName }}
      </span>
      {{ editorNote }}
    </div>
    <div class="mar-audit-border">
      <changes :action="audit.action" :changes="audit.changes" />
    </div>
  </div>
</template>

<script>
import { get } from "carrot-patch-v2/src/utils/objectUtils";
import { toTIME } from "carrot-patch-v2/src/utils/dateUtils";

export default {
  props: {
    audit: { type: Object, default: () => {} },
  },
  computed: {
    timeOfEdit() {
      return toTIME(this.audit.created_at);
    },
    editorName() {
      const first = get(this.audit, "editor.editor_first_name") || "";
      const last = get(this.audit, "editor.editor_last_name") || "";
      const firstLast = first + " " + last;
      return firstLast.trim();
    },
    editorNote() {
      const note = get(this.audit, "editor.editor_note");
      if (typeof note === "string" && note.trim() == "") return "";
      return note;
    },
  },
};
</script>

<style lang="scss" scoped>
.mar-audit-border {
  border-left: 1px solid $gray-1;
  padding-left: 8px;
  margin-left: 23px;
}
</style>
