<template>
  <div v-if="newStatusId">
    <status-id-cell :statusId="newStatusId" :map="statusMap" />
  </div>
  <import-audit-cell v-else :header="header" :item="item" />
</template>

<script>
import { get } from "carrot-patch-v2/src/utils/objectUtils";
import { statusMap } from "@/store/modules/generic.js";

export default {
  props: {
    header: { type: Object },
    item: { type: Object }
  },
  data() {
    return {
      statusMap
    };
  },
  computed: {
    newStatusId() {
      return get(this.item, ["changes", "user_status_id", 1]);
    }
  }
};
</script>
