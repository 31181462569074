<template>
  <v-radio-group
    :value="fields.extract_id.attrs.value"
    @change="fields.extract_id.input"
    label="Ignore by"
    v-if="item.pending_action === 'create'"
  >
    <v-radio
      v-for="(opt, k) in ignoreByOptions"
      :key="k"
      :label="`${snakeToSentenceCase(opt)} (${item.records.new_version[opt]})`"
      :value="item.records.new_version[opt]"
    ></v-radio>
  </v-radio-group>
</template>

<script>
import { mapState } from "vuex";

import { get } from "@cp/utils/objectUtils";
import { snakeToSentenceCase } from "@cp/utils/stringUtils";

import { pendingChange, etlForm } from "@/store/modules/pendingChange";

export default {
  mixins: [etlForm.mixin],
  computed: {
    ...mapState(pendingChange.mp, ["item"]),
    ignoreByOptions() {
      return get(
        this.item,
        "transformation_options.details.ignore_record.ignore_by"
      );
    },
  },
  methods: {
    snakeToSentenceCase,
  },
};
</script>
