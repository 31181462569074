import { Model } from "@/store/mixins/Model";

export const transform = new Model({
  module: "transform",
  urlKey: "transformations",
  noun: "data change",
  table: {
    exportOptions: { downloadXlsx: false },
    headers: [
      {
        text: "",
        value: "explanation",
        sortable: false,
        width: 48,
      },
      { text: "Type", value: "transform_type" },
      { text: "Data Type", value: "extract_type" },
      { text: "Details", value: "details" },
      {
        text: "Date Created",
        value: "created_at",
      },
    ],
    filters: [
      "q_text",
      {
        label: "General",
        type: "select",
        key: "general",
        items: [
          {
            label: "All",
            value: "all",
          },
          {
            label: "Transformations",
            value: "transformations",
          },
          {
            label: "Mappings",
            value: "mappings",
          },
        ],
      },
      {
        label: "Transformation Type",
        type: "select",
        key: "transform_types",
        items: [
          {
            label: "Ignored Records",
            value: "ignore_record",
          },
          {
            label: "Ignored Attributes",
            value: "ignore_attributes",
          },
        ],
      },
      {
        label: "Data Type",
        type: "select",
        key: "load_types",
        items: [
          {
            label: "Employees",
            value: "User",
          },
          {
            label: "Locations",
            value: "Location",
          },
          {
            label: "Positions",
            value: "Position",
          },
        ],
      },
      {
        label: "Transformation Ids",
        type: "sub_query",
        key: "transformation_ids",
      },
    ],
    useGlobalBulkActions: false,
  },
});

window.$transform = transform;

export default transform.toVuex;
