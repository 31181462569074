<template>
  <cp-loading :loading="loading">
    <v-form ref="modelForm" autocomplete="off">
      <template v-if="mode !== 'create'">
        <cp-unhandled-errors :formConf="formConf" />
      </template>

      <v-row dense>
        <v-col v-for="(field, i) in fields" :key="i" v-bind="colProps">
          <component
            :is="field.component"
            v-bind="field.props"
            v-on="field.listeners"
          />
        </v-col>
      </v-row>

      <cp-unhandled-errors :formConf="formConf" />
      <slot name="buttons">
        <cp-form-modal-success-or-buttons
          v-bind="successOrButtonsProps"
          @update="update"
          @reset="resetFields"
          class="mb-1"
        />
      </slot>
    </v-form>
  </cp-loading>
</template>

<script>
import { VTextField } from "vuetify/lib/components/VTextField";
import { VCheckbox } from "vuetify/lib/components/VCheckbox";
import { VSelect } from "vuetify/lib/components/VSelect";
import { VAutocomplete } from "vuetify/lib/components/VAutocomplete";

import { parentRoute } from "@cp/mixins";
import { snakeToTitleCase } from "@cp/utils/stringUtils";
import { get } from "@cp/utils/objectUtils";

const MODES = ["create", "edit", "import", "transform"];
const COLS_DEFAULT = { cols: "12", md: "6" };

export default {
  mixins: [parentRoute],
  components: { VTextField, VCheckbox, VSelect, VAutocomplete },
  props: {
    modelModule: { type: Object, required: true },
    mode: {
      type: String,
      default: "create",
      validator: propValue => MODES.includes(propValue),
    },
    cols: { type: Object, default: () => ({}) },
    filterFields: { type: Array, default: () => [] },
    fieldProps: { type: Object, default: () => ({}) },
  },
  computed: {
    colProps() {
      return Object.assign({}, COLS_DEFAULT, this.cols);
    },
    formModule() {
      return this.modelModule.form;
    },
    errors() {
      return this.$store.getters[this.formModule.p.g.errors];
    },
    success() {
      return get(this.$store.state, this.formModule.p.s.success);
    },
    loading() {
      return get(this.$store.state, this.formModule.p.s.loading);
    },
    formConf() {
      return {
        submitting: get(this.$store.state, this.formModule.p.s.submitting),
        success: this.success,
        unhandledErrors: this.errors.unhandledErrors,
      };
    },
    filteredFields() {
      // order the fields
      let fields = this.formModule.fieldOrder.map(key => ({
        key,
        ...this.formModule.fields[key],
      }));

      // only display fields available in this mode
      fields = fields.filter(({ modes }) =>
        (modes || MODES).includes(this.mode)
      );

      if (this.filterFields.length)
        fields = fields.filter(({ key }) => this.filterFields.includes(key));

      return fields;
    },
    fields() {
      const $vm = this;

      // get touched list
      const touched = get(this.$store.state, this.formModule.p.s.touched);
      const item = get(this.$store.state, this.formModule.p.s.stateKey);

      return this.filteredFields.reduce((r, { key, ...fieldObj }) => {
        const {
          type = "text",
          label = snakeToTitleCase(key),
          rules = [],
          initialValue = "",
          searchModule = "",
          searchParams = {},
          alsoTouch,
          resetOthers,
          hint: hintArg,
          persistentHint: persistentHintArg,
          ...field
        } = fieldObj || {};
        const hint =
          hintArg ||
          this.formModule.defaultHint(fieldObj, touched.includes(key));
        const persistentHint =
          persistentHintArg ||
          this.formModule.defaultPersistentHint(
            fieldObj,
            touched.includes(key)
          );
        function input(value) {
          $vm.$store.commit($vm.formModule.p.m.input, { [key]: value });
          $vm.$store.commit($vm.formModule.p.m.setTouched, key);
          if (alsoTouch)
            $vm.$store.commit($vm.formModule.p.m.setTouched, alsoTouch);
          if (resetOthers)
            $vm.$store.commit($vm.formModule.p.m.resetFields, resetOthers);
        }

        let component = "v-text-field";

        let props = {
          label,
          value: get(
            this.$store.state,
            `${this.formModule.p.s.formData}.${key}`
          ),
          errorMessages: this.errors[key],
          hint,
          persistentHint,
          "data-lpignore": "true", // Lastpass Ignore?!
          ...field,
          ...this.fieldProps,
        };

        // different components emit @input or @change
        // so we'll add the listener below
        let listeners = {};

        switch (type) {
          case "text":
            listeners.input = input;
            break;
          case "integer":
            props.type = "number";
            listeners.input = input;
            break;
          case "single-select":
            component = "v-select";
            listeners.input = input;
            break;
          case "autocomplete":
            component = "v-autocomplete";
            listeners.input = input;
            props.itemText = "label";
            break;
          case "checkbox":
            component = "v-checkbox";
            props.trueValue = true;
            props.inputValue = props.value;
            listeners.change = input;
            break;
          case "date":
            component = "date-picker";
            listeners.input = input;
            break;
          case "api-autocomplete":
            component = "cp-search-autocomplete";
            if (!searchModule)
              throw `${this.modelModule.mp} ModelForm.fields["${key}"] requires searchModule`;

            props.form = this.formModule;
            props.searchModule = searchModule;
            props.name = key;
            props.openOnClear = true;
            props.clearable = true;
            props.itemText = "label";
            listeners.input = input;
            props["no-data-text"] = "Type to search";

            if (!key.includes("_")) {
              // if this ever throws, we'll need to rewrite the MESS below...
              throw `${this.modelModule.mp} ModelForm.fields["${key}"] key needs an underscore`;
            }
            // don't set this value in the create form
            if (this.mode === "create" || !item[key]) break;

            let nameKey = key.replace(/_[^_]+$/, "");
            if (props.multiple) {
              nameKey += "s";
              props.initialValues = item[nameKey] || [];
            } else {
              props.initialValue = { value: item[key], label: item[nameKey] };
            }

            break;
        }

        r[key] = {
          component,
          props,
          listeners,
        };
        return r;
      }, {});
    },
    successOrButtonsProps() {
      const $vm = this;
      const touched = get(this.$store.state, this.formModule.p.s.touched);

      return {
        modalConf: {
          close: () =>
            $vm.parentRoute
              ? $vm.$router.push($vm.parentRoute)
              : $vm.$router.go(-1),
        },
        formConf: this.formConf,
        buttons: ["cancel", "reset", "update"],
        buttonConf: {
          update: {
            action: "update",
            text: `Update (${touched.length} changes)`,
            props: { disabled: !touched.length, color: "primary" },
          },
          reset: {
            action: "reset",
            text: "Reset",
            icon: "mdi-reload",
            props: { color: "white" },
          },
        },
      };
    },
  },
  methods: {
    update() {
      this.$store.dispatch(this.formModule.p.a.update);
    },
    resetFields() {
      this.$store.commit(this.formModule.p.m.resetFields);
    },
  },
};
</script>
