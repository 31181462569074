<template>
  <v-skeleton-loader v-if="delayForItemLoad" type="table" />
  <div v-else-if="noChangesWereMade">
    <div class="pa-4">
      <cp-h1>This sheet did not make any changes.</cp-h1>
      <p class="mb-0">
        This is probably ok, but the importer may have skipped records due to
        transforms or bad data. If any records were skipped, you'll see the
        summary in the...
      </p>
    </div>
  </div>
  <list-table
    v-else
    model-name="importAudit"
    :default-filters="defaultFilters"
    :staticHeaders="listTableHeaders"
    :can-import-export="false"
    :can-edit="true"
    hide-header
    hide-filters
  />
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { get } from "carrot-patch-v2/src/utils/objectUtils";

export default {
  props: { model: { type: Object } },
  computed: {
    ...mapState("import", ["item"]),
    ...mapGetters("import", ["currentTab"]),
    ...mapGetters("importAudit", ["listTableHeaders"]),
    noChangesWereMade() {
      return Object.keys(this.item.audit_summary).length === 0;
    },
    delayForItemLoad() {
      return !(this.item && this.item.id);
    },
    showTable() {
      const summaryKeys = Object.keys(get(this.item, "audit_summary", {}));
      return id && summaryKeys.length;
    },
    defaultFilters() {
      return [{ general: this.currentTab.slug }];
    }
  }
};
</script>

<style scoped>
pre {
  white-space: pre;
  font-family: monospace;
}
</style>
