<template>
  <div>
    <confirmation-dialog :model="lease" />
    <list-table
      model-name="lease"
      can-edit
      :can-import-export="userIsSwiftbunny"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "Leases",

  computed: {
    ...mapState({
      lease: state => state.lease,
    }),
    ...mapGetters("auth", ["userIsSwiftbunny"]),
  },

  mounted() {
    if (this.$route.params.uuid) {
      this.$store.dispatch("lease/openEditDialog", this.$route.params.uuid);
    } else {
      this.$store.commit("lease/setEditDialogWindow", false);
    }
  },
};
</script>
