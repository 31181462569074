<template>
  <div>Do I render?</div>
</template>

<script>
export default {
  mounted() {
    console.log("I RENDERED!!!");
  },
};
</script>
