<template>
  <div>
    <cp-search-autocomplete v-bind="searchProps" @input="input" />

    <div class="d-flex justify-end mt-6">
      <div v-if="success" class="d-flex align-center">
        <CpSuccessIcon class="mr-4" xLarge />
        {{ action.successMessage || "Success!" }}
      </div>
      <template v-else>
        <v-btn @click="action.modalConf.close" class="mr-4">Cancel</v-btn>
        <v-btn @click="confirm" color="primary" :loading="submitting">
          Assign category
        </v-btn>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import { get } from "@cp/utils/objectUtils";

import { position, categorySearch } from "@/store/modules/position";

const SUB = position.table.subModuleRefs.bulkAssignCategories;

export default {
  name: "BulkSetEmployeesManager",
  props: {
    selected: { type: Array, required: true },
    action: { type: Object, required: true },
  },
  computed: {
    ...mapState(categorySearch.mp, {
      items: categorySearch.keys.stateKey,
      loading: categorySearch.keys.loading,
    }),
    errors() {
      return this.$store.getters[SUB.p.g.errors];
    },
    formConf() {
      return {
        submitting: this.submitting,
        success: this.success,
        unhandledErrors: this.errors.unhandledErrors,
      };
    },
    selectedIds() {
      return this.selected.map(x => x.id);
    },
    searchProps() {
      return {
        form: SUB,
        name: "generalized_position_category_id",
        label: "Select Managers",
        searchModule: categorySearch,
        params: { filters: { not_ids: this.selectedIds } },
        errorMessages: this.errors.generalized_position_category_id,
      };
    },
    categoryId() {
      return get(
        this.$store.state,
        SUB.p.s.formData + ".generalized_position_category_id"
      );
    },
    success() {
      return get(this.$store.state, SUB.p.s.success);
    },
    submitting() {
      return get(this.$store.state, SUB.p.s.submitting);
    },
  },
  methods: {
    ...mapActions(categorySearch.mp, {
      search: categorySearch.keys.search,
    }),
    nameOfItem(item) {
      return (
        item.name ||
        item.full_name ||
        [item.first_name, item.last_name].join(" ") ||
        item.title ||
        item.unit_number
      );
    },
    input(value) {
      this.$store.commit(SUB.p.m.input, {
        generalized_position_category_id: value,
      });
      this.$store.commit(
        SUB.p.m.setTouched,
        "generalized_position_category_id"
      );
    },
    async confirm() {
      const generalized_position_category_id = this.categoryId;
      const data = {
        positions: this.selected.map(x => ({
          position_id: x.id,
          generalized_position_category_id,
        })),
      };
      this.$store.dispatch(SUB.p.a.confirm, data);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-label {
  display: block;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  color: $gray-4;
}

.v-card__actions {
  padding: 0 24px 20px !important;
}
</style>
