<template>
  <cp-loading :loading="loading">
    <cp-h1>
      {{ item.full_name }}

      <template #subtitle>
        <model-details-dates :item="item" />
      </template>

      <template #right>
        <v-btn
          @click="update"
          :disabled="!touched.length"
          :loading="loading"
          color="primary"
          text
        >
          <v-icon left>mdi-content-save</v-icon>
          Update
        </v-btn>

        <v-btn
          v-if="item.status_id === 1"
          @click="sendWelcomeEmail"
          color="primary"
          class="ml-4"
          text
        >
          <v-icon left>mdi-email</v-icon>
          Send Welcome Email
        </v-btn>

        <v-divider vertical class="mx-4" />
        <v-btn
          v-if="hasArchiveBA && item.status_id === 1"
          @click="archive"
          color="error"
          text
        >
          <v-icon left>mdi-archive</v-icon>
          Archive
        </v-btn>
        <v-btn
          v-else-if="hasRestoreBA && item.status_id === 2"
          @click="restore"
          color="green"
        >
          Restore
        </v-btn>
      </template>
    </cp-h1>
    <model-tabs :modelModule="employee" />
  </cp-loading>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import { toMDY } from "@cp/utils/dateUtils";
import { findByObj } from "@cp/utils/arrayUtils";

import { employee } from "@/store/modules/employee";

export default {
  data() {
    return {
      employee,
    };
  },
  computed: {
    ...mapState(employee.mp, ["item"]),
    ...mapState(employee.mp, {
      touched: "itemTouched",
      loading: "itemLoading",
      submitting: "itemSubmitting",
    }),
    ...mapGetters(employee.mp, ["tabs"]),
    hasArchiveBA() {
      return findByObj(employee.table.bulkActions, { name: "restore" });
    },
    hasRestoreBA() {
      return findByObj(employee.table.bulkActions, { name: "archive" });
    },
  },
  methods: {
    toMDY,
    ...mapMutations(employee.mp, ["resetItem"]),
    ...mapActions(employee.mp, ["fetchItem", "update", "archive", "restore"]),
    ...mapMutations(employee.mp, ["resetItem"]),
    sendWelcomeEmail() {
      const ap = employee.table.subModuleRefs.bulkSendWelcomeEmails.p.a.confirm;
      this.$store.dispatch(ap, { ids: [this.item.id] });
    },
  },
  mounted() {
    const { id } = this.$route.params;
    if (id) {
      this.resetItem();
      this.fetchItem({ id });
    }
  },
};
</script>
