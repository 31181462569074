<template>
  <v-card>
    <v-tabs
      v-model="currentIndex"
      slider-color="primary"
      background-color="#f1f1f1"
      @change="changeTab"
    >
      <v-tabs-slider />
      <v-tab v-for="tab in tabs" :key="tab">{{
        kebabToSentenceCase(tab)
      }}</v-tab>
    </v-tabs>

    <v-card-text>
      <v-tabs-items v-model="currentIndex">
        <v-tab-item v-for="tab in tabs" :key="tab">
          <cp-loading :loading="loading">
            <cp-unhandled-errors
              v-if="tab !== 'general'"
              :formConf="formConf"
            />
            <component :is="`${tab}-tab`" v-bind="$props" />
          </cp-loading>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { onCpEvent, setDataFromUrl } from "@cp/mixins";
import { kebabToSentenceCase } from "@cp/utils/stringUtils";
import { get } from "@cp/utils/objectUtils";

export default {
  mixins: [
    onCpEvent("modelUpdateFailed", function() {
      this.currentIndex = 0;
      this.changeTab(0);
    }),
    setDataFromUrl("currentIndex", function({ query }) {
      const { tab } = query;
      if (!tab) return 0;
      const foundTabIndex = this.tabs.findIndex(x => x === tab);
      if (foundTabIndex === -1) return 0;
      return foundTabIndex;
    }),
  ],
  props: { modelModule: { type: Object, required: true } },
  data() {
    const tabs = this.$store.getters[this.modelModule.p.g.detailTabs];
    return {
      tabs,
      currentIndex: 0,
    };
  },
  computed: {
    formModule() {
      return this.modelModule.form;
    },
    loading() {
      return get(this.$store.state, this.formModule.p.s.loading);
    },
    formConf() {
      const { unhandledErrors, ...errors } =
        this.$store.getters[this.formModule.p.g.errors] || {};
      return {
        submitting: get(this.$store.state, this.formModule.p.s.submitting),
        success: get(this.$store.state, this.formModule.p.s.success),
        unhandledErrors: { ...unhandledErrors, ...errors },
      };
    },
  },
  methods: {
    kebabToSentenceCase,
    changeTab(index) {
      const tab = this.tabs[index];
      this.$CpEvent.$emit("updateRoute", { query: { tab } });
    },
  },
};
</script>
