import List from "./List.vue";
import Detail from "./Detail.vue";
import Create from "./Create.vue";

export default [
  {
    path: "/locations",
    name: "Locations",
    component: List,
    meta: {
      title: "Locations",
    },
  },
  {
    path: "/locations/create",
    name: "addLocation",
    component: Create,
    meta: {
      title: "Add Location",
      parent: "Locations",
    },
  },
  {
    path: "/locations/:id",
    name: "locationDetail",
    component: Detail,
    meta: {
      title: "Edit Location",
      mode: "edit",
      parent: "Locations",
    },
  },
];
