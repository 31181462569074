<template>
  <div>
    <h6 class="text-h6 text--secondary mb-3">
      {{ modelModule.nounTitle }} Information
    </h6>

    <model-form :modelModule="modelModule" mode="edit" />
  </div>
</template>

<script>
export default {
  props: {
    modelModule: { type: Object, required: true },
  },
};
</script>
