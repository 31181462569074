import List from "./List.vue";
import Detail from "./Detail.vue";
import Create from "./Create.vue";

export default [
  {
    path: "reporting-groups",
    name: "Reporting Groups",
    component: List,
    meta: {
      title: "Reporting Groups",
    },
  },
  {
    path: "/reporting-groups/create",
    name: "addReportingGroup",
    component: Create,
    meta: {
      title: "Add Reporting Group",
      parent: "Reporting Groups",
    },
  },
  {
    path: "/reporting-groups/:id",
    name: "reportingGroupDetail",
    component: Detail,
    meta: {
      title: "Edit Reporting Group",
      mode: "edit",
      parent: "Reporting Groups",
    },
  },
];
