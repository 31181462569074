<template>
  <v-container class="pa-3">
    <v-row dense>
      <v-col
        cols="12"
        md="4"
        v-for="(service, i) in model.services"
        :key="i"
        class="mb-2"
      >
        <v-sheet
          color="#f1f1f1"
          rounded
          class="text-uppercase text-bold pa-1 px-2 mb-1"
        >
          {{ service }}
        </v-sheet>

        <v-select
          v-if="service === 'Engagement System'"
          :items="engageRoleOptions"
          :value="engageRole"
          @input="changeEngageRole"
        >
          <template #selection="{item}">
            <strong class="mr-3">{{ item.text }}</strong>
            <small class="text-caption text--secondary"
              >( {{ item.description }} )</small
            >
          </template>
          <template #item="{item}">
            <div>
              <div>{{ item.text }}</div>
              <h4 class="text-caption text--secondary mb-1">
                {{ item.description }}
              </h4>
            </div>
          </template>
        </v-select>

        <template
          v-else
          v-for="role in model.roles.filter(x => x.service == service)"
        >
          <v-checkbox
            v-model="model.item.role_ids"
            :key="role.value"
            :label="role.text"
            :value="role.value"
            hide-details
            class="mt-0"
            dense
          />
          <h4 class="text-caption text--secondary mb-1 ml-8">
            {{ role.description || "No description text" }}
          </h4>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    model: { type: Object, default: () => {} }
  },
  computed: {
    engageRoles() {
      return this.model.roles.filter(x => x.service === "Engagement System");
    },

    engageRoleOptions() {
      return [this.noneRole("Engagement System"), ...this.engageRoles];
    },

    engageRole() {
      const userRoles = this.model.item.role_ids || [];
      const selectedRole = this.engageRoles.find(x =>
        userRoles.includes(x.value)
      );
      return selectedRole || this.noneRole("Engagement System");
    },

    roles() {
      return this.model.item.role_ids.map(x =>
        this.model.roles.find(y => y.value === x)
      );
    }
  },

  methods: {
    noneRole(service) {
      return {
        description: `Cannot use ${service}`,
        text: "None",
        value: null
      };
    },

    changeEngageRole(newValue) {
      // remove all engage roles from role_ids first.
      const prevRoles = [...this.model.item.role_ids];
      const engageRoleIds = this.engageRoles.map(x => x.value);
      const newRoles = prevRoles.filter(role => !engageRoleIds.includes(role));
      if (newValue) {
        newRoles.push(newValue);
      }
      this.model.item.role_ids = newRoles;
    }
  }
};
</script>
