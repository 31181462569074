import { mergeMixins, ApiDataMixin, ItemsTable } from "@cp/store/mixins";

export const counts = new ApiDataMixin({
  baseUrl: `${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1/dashboard/deficiency_counts`,
  name: "counts",
  jsonApiResponse: false,
  initialValue: {
    user: {},
    location: {},
    position: {},
    geographical_group: {},
  },
});

export const table = new ItemsTable({
  module: "issues",
  baseUrl: `${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1/dashboard/deficiencies`,
  url: "/:model/:type",
  urlTemplate: true,
  noun: "Issue",
  keys: ["setupTable"],
  state: {
    itemsParams: {
      model: "",
      type: "",
    },
  },
  actions: {
    setupTable({ state, commit, dispatch }, { model, type }) {
      commit("resetItems");
      state.itemsParams.model = model;
      state.itemsParams.type = type;
      dispatch("fetchItems");
    },
  },
});
// this prevents table.mixin fron triggering fetch before setupTable
table.add({
  state: {
    itemsTableHasFetched: true,
  },
});

window.$issuesCounts = counts;
window.$issuesTable = table;

export default mergeMixins(counts, table);
