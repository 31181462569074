import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[(_vm.item.id)?_c('router-link',{attrs:{"icon":"","to":Object.assign({}, _vm.route, {params: { uuid: _vm.item.id }})}},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]):_c('span',[_vm._v(_vm._s(_vm.item.title))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c(VIcon,[_vm._v(_vm._s(_vm.show ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1),_c(VDivider),_c(VList,{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],attrs:{"dense":""}},_vm._l((_vm.attrMap),function(ref,i){
var title = ref.title;
var value = ref.value;
return _c(VListItem,{key:i},[_c(VListItemContent,[_vm._v(_vm._s(title)+":")]),_c(VListItemContent,[_c('div',{staticClass:"text-truncate text-right",attrs:{"title":value}},[_vm._v(" "+_vm._s(value)+" ")])])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }