import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';

function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{staticClass:"mb-5",attrs:{"dense":""}},_vm._l((_vm.singles),function(ref,i){
var item = ref.item;
var rest = objectWithoutProperties( ref, ["item"] );
var props = rest;
return _c(VCol,{key:i,attrs:{"cols":"6"}},[_c('model-card',_vm._b({attrs:{"item":item}},'model-card',props,false))],1)}),1),(_vm.lists.length)?_c(VExpansionPanels,{attrs:{"multiple":"","accordion":""}},_vm._l((_vm.lists),function(ref,i){
var title = ref.title;
var rest = objectWithoutProperties( ref, ["title"] );
var props = rest;
return _c(VExpansionPanel,{key:i},[_c(VExpansionPanelHeader,[_c('h3',[_vm._v(_vm._s(props.items.length)+" "+_vm._s(title))])]),_c(VExpansionPanelContent,[_c('relationship-list',_vm._b({},'relationship-list',props,false))],1)],1)}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }