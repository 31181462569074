<template>
  <v-data-table v-bind="$attrs" v-on="$listeners">
    <template v-for="(index, name) in $slots" v-slot:[name]>
      <slot :name="name" />
    </template>
    <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data"></slot>
    </template>

    <template v-for="(component, key) in defaultCells" v-slot:[key]="data">
      <component
        :is="component"
        :key="`row-${data.index}-${key}`"
        v-bind="data"
      />
    </template>
  </v-data-table>
</template>

<script>
import defaultCells from "@/components/tableCells/defaultCells";
import { pick } from "@cp/utils/objectUtils";
export default {
  computed: {
    overriddenSlotKeys() {
      return [...Object.keys(this.$slots), ...Object.keys(this.$scopedSlots)];
    },
    defaultCells() {
      const cells = Object.keys(defaultCells).filter(
        key => !this.overriddenSlotKeys.includes(key)
      );
      return pick(defaultCells, cells);
    },
  },
};
</script>
