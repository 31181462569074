<template>
  <div>
    <model-edit-dialog
      v-if="canEdit"
      :model.sync="model"
      :back-to-list-on-close="useRoute"
    />
    <model-create-dialog v-if="canCreate" :model.sync="model" />
    <bulk-action-dialog :model.sync="model" />

    <cp-h1 v-if="!hideHeader">
      <cp-chip class="text-h6" style="margin-bottom:3px;">
        {{ staticData.length ? staticData.length : model.total }}
      </cp-chip>
      {{ noun | pluralize(model.total) | capitalize }}

      <template #right>
        <issue-counter :model-name="model.singularName" />

        <cp-btn
          v-if="canCreate"
          color="success"
          class="ml-4"
          @click="$store.dispatch(`${modelName}/openCreateDialog`)"
        >
          <v-icon>mdi-plus</v-icon>
          Add {{ noun }}
        </cp-btn>

        <cp-menu
          v-if="canImportExport"
          left
          :options="[
            {
              title: `Import ${modelImportExportName}`,
              href: `/import/current`,
              type: `link`,
            },
            {
              title: `Export ${modelImportExportName}`,
              type: 'storeAction',
              action: `${modelName}/exportList`,
            },
          ]"
        >
          <template #activator="{ on, attrs }">
            <cp-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </cp-btn>
          </template>
        </cp-menu>
      </template>
    </cp-h1>

    <cp-card dense flush-body v-bind="$attrs">
      <template #title>
        <template
          v-if="
            model.bulkSelection.length > 0 &&
              !hideBulkActions &&
              $store.getters[`${modelName}/bulkActions`].length
          "
        >
          <h5 class="text-subtitle-1">
            {{ model.bulkSelection.length }}
            {{ modelName | pluralize(model.bulkSelection.length) | capitalize }}
            Selected
            <cp-menu
              bottom
              right
              transition="slide-x-transition"
              :options="$store.getters[`${modelName}/bulkActions`]"
              :min-width="130"
            >
              <template #activator="{on}">
                <v-btn color="primary" depressed small v-on="on" class="ml-4">
                  <v-icon class="mr-1">mdi-dots-horizontal</v-icon>Bulk Actions
                </v-btn>
              </template>
            </cp-menu>
          </h5>
        </template>
        <v-checkbox
          v-for="({ label, value, slug }, i) in toggles"
          :key="i"
          :label="label"
          :value="value"
          :value-comparator="v => v"
          @change="toggleFilter(slug)"
        />
      </template>

      <template #actions>
        <v-text-field
          v-if="tableOptions"
          v-model="tableOptions.q_text"
          append-icon="mdi-magnify"
          label="Search"
          dense
          single-line
          hide-details
          clearable
        />

        <v-btn
          v-if="!hideFilters"
          text
          rounded
          @click="filtersVisible = !filtersVisible"
          class="ml-4"
          :class="{ 'mar-filter-active': filtersVisible }"
        >
          <span v-if="tableOptions.filters.length" class="mr-1">
            ({{ tableOptions.filters.length }})
          </span>
          {{ "Filter" | pluralize(tableOptions.filters.length) }}
          <v-icon color="primary">mdi-chevron-left</v-icon>
        </v-btn>
      </template>

      <v-card flat class="overflow-hidden">
        <v-navigation-drawer
          v-if="filterOptions && !hideFilters"
          v-model="filtersVisible"
          right
          absolute
          class="pa-2"
          temporary
        >
          <v-select
            v-for="(filter, i) in filterOptions"
            v-model="tableOptions.filters"
            :placeholder="filter.placeholder"
            :items="filter.items"
            :ref="filter.type"
            :key="i"
            multiple
            hide-details
          />

          <div class="text-center mt-3">
            <v-btn
              text
              rounded
              color="error"
              @click="tableOptions.filters = []"
              :disabled="tableOptions.filters.length <= 0"
            >
              Reset Filters <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-navigation-drawer>

        <v-data-table
          v-model="model.bulkSelection"
          :headers="tableHeaders"
          :items="tableData"
          item-key="id"
          :loading="model.loading"
          class="fill-height"
          :items-per-page.sync="tableOptions.itemsPerPage"
          v-bind="tablePaginationProps"
          :footer-props="{
            itemsPerPageOptions: paginationCountOptions,
          }"
          :search="tableOptions.q_text"
          :page.sync="tableOptions.page"
          :sort-by.sync="tableOptions.sortBy"
          :sort-desc.sync="tableOptions.sortDesc"
          :show-select="$store.getters[`${modelName}/bulkActions`].length > 0"
          :single-select="singleSelect"
          :hide-default-footer="hideFooter"
          :show-expand="!!$scopedSlots['expanded-item']"
        >
          <template
            #item.alert="{item, isSelected, select, isExpanded, expand}"
          >
            <v-tooltip right v-if="item.deficiencies.length > 0">
              <template #activator="{ on, attrs }">
                <v-icon
                  small
                  class="icon icon-warning"
                  color="warning"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-alert
                </v-icon>
              </template>
              <h4 v-for="(deficiency, i) in item.deficiencies" :key="i">
                {{ deficiency | snakeToTitleCase }}
              </h4>
            </v-tooltip>
          </template>

          <!-- I don't think there's a DRY-er way to do this without completely rewriting <tr> -->
          <template #item.name="props">
            <span v-link-cell="props">{{ props.value }}</span>
          </template>
          <template #item.primary_resident_name="props">
            <span v-link-cell="props">{{ props.value }}</span>
          </template>
          <template #item.full_name="props">
            <span v-link-cell="props">{{ props.value }}</span>
          </template>
          <template #item.unit_number="props">
            <span v-link-cell="props">{{ props.value }}</span>
          </template>
          <template #item.cityState="{item}">
            <span>{{ [item.city, item.state].join(",") }}</span>
          </template>

          <template #item.status_id="{item}">
            <status-id-cell
              :statusId="item.status_id"
              :map="model.status_map"
            />
          </template>

          <template #item.managers="{item}">
            <template v-if="item.managers">
              {{ item.managers.map(x => x.text).join(", ") }}
            </template>
          </template>

          <template #item.location_type_id="{item}">
            <v-badge
              v-if="item.linked_locations && item.linked_locations.length"
              icon="mdi-link"
              color="primary"
              label="Linked to another location"
              class="linked-badge"
              bordered
            >
              <v-icon
                :title="item.location_type_id === 1 ? 'office' : 'property'"
                color="primary"
              >
                {{
                  item.location_type_id === 1
                    ? "mdi-office-building-marker"
                    : "mdi-home-map-marker"
                }}
              </v-icon>
            </v-badge>
            <v-icon
              v-else
              :title="item.location_type_id === 1 ? 'office' : 'property'"
              color="primary"
            >
              {{
                item.location_type_id === 1
                  ? "mdi-office-building-marker"
                  : "mdi-home-map-marker"
              }}
            </v-icon>
          </template>

          <template #item.format="props">
            <format-fn-cell v-bind="props" v-link-cell="props" />
          </template>

          <template #item.component_cell="props">
            <component
              :is="props.header.component"
              v-bind="props"
              v-link-cell="props"
            />
          </template>

          <template
            v-for="(_, name) in $scopedSlots"
            :slot="name"
            slot-scope="slotData"
            ><slot :name="name" v-bind="slotData"
          /></template>
        </v-data-table>
      </v-card>
    </cp-card>
  </div>
</template>

<script>
import { pick } from "carrot-patch-v2/src/utils/objectUtils";
import { mapActions, mapGetters, mapState } from "vuex";

const itemAttributesUsedInRoutes = [
  "id",
  "depth",
  "auditable_type",
  "audited_object",
];

export default {
  props: {
    modelName: { type: String, default: "" },
    canEdit: { type: Boolean, default: false },
    canCreate: { type: Boolean, default: false },
    canImportExport: { type: Boolean, default: true },
    hideBulkActions: { type: Boolean, default: false },
    hideHeader: { type: Boolean, default: false },
    hideFooter: { type: Boolean, default: false },
    staticData: { type: Array, default: () => [] },
    staticHeaders: { type: Array, default: () => [] },
    defaultFilters: { type: Array, default: () => [] },
    hideFilters: { type: Boolean, default: false },
    importExportName: { type: String, default: "" },
    singleSelect: { type: Boolean, default: false },
    useRoute: { type: Boolean, default: true },
  },

  data() {
    return {
      shouldWatchTableOptions: false,
      filtersVisible: false,
      searchText: null,
    };
  },

  computed: {
    ...mapState({
      tableOptions: state => state.table,
      issues: state => state.issues,
      file: state => state.file,
    }),
    ...mapGetters("table", ["toggles"]),

    tablePaginationProps() {
      if (this.staticData.length) {
        return {};
      }

      return {
        serverItemsLength: this.model.records,
      };
    },

    model() {
      return this.$store.state[this.modelName];
    },

    filterOptions() {
      return this.$store.getters[`${this.modelName}/filterOptions`];
    },

    issueCount() {
      return this.$store.getters["issues/uniqueRecordCount"][this.modelName];
    },

    paginationCountOptions() {
      if (this.model.total <= 150) {
        return [10, 25, 50, -1];
      }

      return [10, 25, 50];
    },

    tableData() {
      if (this.staticData.length) {
        return this.staticData;
      }

      return this.model.items;
    },

    tableHeaders() {
      if (this.staticHeaders.length) {
        return this.staticHeaders;
      }

      return this.model.listTableHeaders;
    },

    noun() {
      return this.model.noun || this.modelName;
    },

    modelImportExportName() {
      return this.importExportName || `${this.modelName}s`;
    },
  },

  methods: {
    ...mapActions("table", ["toggleFilter"]),

    navigate(item) {
      if (!this.canEdit) return;

      if (!this.useRoute) {
        this.$store.dispatch(`${this.modelName}/openEditDialog`, item.id);
        return;
      }

      const route = this.$store.getters[`${this.modelName}/itemRoute`](item);
      this.$router.push(route);
    },

    callNavigateWithHtmlAttr(event) {
      event.preventDefault();
      event.stopPropagation();
      this.navigate(JSON.parse(event.target.dataset.item));
    },
  },

  directives: {
    linkCell: {
      bind(el, { value: props }, instance) {
        if (props.header.linkCell && instance.context.canEdit) {
          el.classList.add("mar-table-row-link");
          const dataForLink = pick(props.item, itemAttributesUsedInRoutes);
          el.dataset.item = JSON.stringify(dataForLink);
          el.addEventListener(
            "click",
            instance.context.callNavigateWithHtmlAttr
          );
        }
      },
      unbind(el, binding, instance) {
        el.removeEventListener(
          "click",
          instance.context.callNavigateWithHtmlAttr
        );
      },
    },
  },

  watch: {
    tableOptions: {
      handler() {
        if (!this.shouldWatchTableOptions) return;

        if (this.searchText != this.tableOptions.q_text) {
          this.tableOptions.page = 1;
        }

        this.$store.dispatch(
          `${this.modelName}/fetchAutoCompleteItemsTimeout`,
          this.tableOptions
        );

        this.searchText = this.tableOptions.q_text;
      },
      deep: true,
    },
  },

  mounted() {
    if (!this.hideHeader) {
      this.$store.dispatch("file/fetchImportStatus");
    }

    if (this.defaultFilters) {
      this.$store.commit("table/clear");
      this.$store.commit("table/setFilters", this.defaultFilters);
    }

    if (!this.staticData.length) {
      this.$store
        .dispatch(`${this.modelName}/fetchAutoCompleteItems`, this.tableOptions)
        .then(() => {
          this.shouldWatchTableOptions = true;
        });
    }
  },

  beforeDestroy() {
    this.shouldWatchTableOptions = false;
    this.$store.commit(`${this.modelName}/reset`);
  },
};
</script>

<style lang="scss" scoped>
.mar-table-row-link {
  display: block;
  width: 100%;
  padding: 10px 0;
  color: $primary;
}

.mar-filter-active {
  background-color: $primary;
  color: $white;

  &::v-deep {
    .v-icon {
      color: $white !important;
      transform: rotateY(180deg);
    }
  }
}

small {
  font-size: 0.7em;
  color: $gray-4;
  vertical-align: middle;
}
</style>
