<template>
  <h4 class="text-body-1">
    Really restore all selected {{ model.pluralName }}?
  </h4>
</template>

<script>
export default {
  props: {
    model: { type: Object, default: () => {} }
  }
};
</script>
