<template>
  <cp-dialog
    :show-model.sync="file.fieldMappingDialogOpen"
    header-background
    scrollable
    max-width="640"
    @modalClosed="$store.commit('file/resetInstance')"
  >
    <template #title>
      Field Mapping
    </template>

    <template #body>
      <template v-if="file.loading">
        <v-row v-for="n in 4" align="center" class="py-1" :key="n">
          <v-col cols="3" align="right">
            <v-skeleton-loader type="text" />
          </v-col>
          <v-col cols="2" align="center">
            <v-icon>mdi-arrow-right</v-icon>
          </v-col>
          <v-col cols="7">
            <v-skeleton-loader type="text" />
          </v-col>
        </v-row>
      </template>
      <import-field-mapping v-else />
    </template>

    <template #actions="{ close }">
      <v-col cols="auto" class="ml-auto">
        <cp-btn
          color="primary"
          rounded
          :loading="file.saving"
          @click="
            $store.dispatch('file/submitFieldMapping', {
              job: file.instance,
              fieldMapping: $store.getters['file/fieldMapAsArray'],
              loadType: file.instance.load_type
            })
          "
        >
          Save Field Mappings
        </cp-btn>
      </v-col>
    </template>
  </cp-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      file: state => state.file
    })
  }
};
</script>
