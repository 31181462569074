<template>
  <span>{{ item.full_name || `${item.first_name} ${item.last_name}` }}</span>
</template>

<script>
export default {
  name: "FullNameCell",
  props: ["item"],
};
</script>
