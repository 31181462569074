<template>
  <cp-dialog
    :show-model.sync="model.bulkActionDialogOpen"
    max-width="860"
    header-background
    @close="model.bulkActionContext = null"
    ref="bulkActionDialog"
  >
    <template #title>
      {{ title }}
    </template>

    <template #body>
      <v-alert dense text color="primary">
        <v-icon color="primary" class="mr-2">mdi-information</v-icon>
        <span class="text-bold">
          {{ model.bulkSelection.length }}
          {{
            model.singularName
              | pluralize(model.bulkSelection.length)
              | capitalize
          }}
          will be affected.
        </span>
      </v-alert>

      <component
        v-if="model.bulkActionContext"
        :is="model.bulkActionContext"
        :model.sync="model"
      />
    </template>

    <template #actions="{close}">
      <v-spacer />
      <cp-btn color="error" text @click="close">
        Cancel
      </cp-btn>
      <cp-btn
        @click="submit"
        class="ml-4 px-6"
        :loading="model.saving"
        :disabled="model.saving"
        color="success"
      >
        <v-icon class="mr-1">mdi-check</v-icon>
        Submit
      </cp-btn>
    </template>
  </cp-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    model: { type: Object, default: () => {} }
  },

  data() {
    return {
      confirming: false
    };
  },

  computed: {
    ...mapState({
      tableOptions: state => state.table
    }),

    title() {
      switch (this.model.bulkActionContext) {
        case "link-locations":
          return "Link Locations";
        default:
          return "Perform Bulk Action";
      }
    }
  },

  methods: {
    submit() {
      switch (this.model.bulkActionContext) {
        case "bulk-assign-manager":
          this.$store.dispatch("employee/massAssignManagers", {
            manager_ids: this.model.bulkAssignManagerTargets,
            employee_ids: this.model.bulkSelection.map(x => x.id)
          });
          return;
        case "bulk-assign-position":
          this.$store.dispatch("employee/massAssignPosition", {
            position_id: this.model.bulkAssignPositionTarget,
            employee_ids: this.model.bulkSelection.map(x => x.id)
          });
          return;
        case "bulk-send-welcome-emails":
          this.$store.dispatch(
            "employee/sendWelcomeEmails",
            this.model.bulkSelection.map(x => x.id)
          );
          return;
        case "bulk-assign-property-manager":
          this.$store.dispatch("location/massAssignManagers", {
            manager_ids: this.model.bulkAssignManagerTargets,
            location_ids: this.model.bulkSelection.map(x => x.id)
          });
          return;
        case "bulk-assign-maintenance-manager":
          this.$store.dispatch("location/massAssignMaintenanceManagers", {
            manager_ids: this.model.bulkAssignManagerTargets,
            location_ids: this.model.bulkSelection.map(x => x.id)
          });
          return;
        case "bulk-assign-region":
          // field is a single select, so we need to put the value in an array
          this.$store.dispatch("location/massAssignRegion", {
            geographical_group_ids: [this.model.bulkAssignRegionTarget],
            location_ids: this.model.bulkSelection.map(x => x.id)
          });
          return;
        case "bulk-assign-group":
          this.$store.dispatch("location/massAssignGroup", {
            // field is a multiple select, so pass the array to the
            geographical_group_ids: this.model.bulkAssignGroupTarget,
            location_ids: this.model.bulkSelection.map(x => x.id)
          });
          return;
        case "bulk-assign-area":
          this.$store.dispatch("region/massAssignArea", {
            area_id: this.model.bulkAssignAreaTarget,
            region_ids: this.model.bulkSelection.map(x => x.id)
          });
          return;
        case "bulk-assign-regional-managers":
          this.$store.dispatch("region/massAssignRegionalManagers", {
            manager_ids: this.model.bulkAssignRegionTarget,
            region_ids: this.model.bulkSelection.map(x => x.id)
          });
          return;
        case "bulk-assign-position-category":
          this.$store.dispatch("position/massAssignCategory", {
            category_id: this.model.BulkAssignPositionCategoryTarget,
            position_ids: this.model.bulkSelection.map(x => x.id)
          });
          return;
        case "bulk-restore":
          this.$store.dispatch(
            `${this.model.singularName}/restore`,
            this.model.bulkSelection.map(x => x.id)
          );
          return;
        case "bulk-archive":
          this.$store.dispatch(
            `${this.model.singularName}/archive`,
            this.model.bulkSelection.map(x => x.id)
          );
          return;
        case "link-locations":
          this.$store.dispatch("location/linkLocations", {
            location_ids: this.model.bulkSelection.map(x => x.id)
          });
          return;

        // TEMPORARY until moved to triage
        case "bulk-assign-as-location-manager":
          this.$store.dispatch("employee/massAssignAsManager", {
            endpoint: "assign_as_location_managers",
            employee_ids: this.model.bulkSelection.map(x => x.id)
          });
          return;
        case "bulk-assign-as-location-maintenance-manager":
          this.$store.dispatch("employee/massAssignAsManager", {
            endpoint: "assign_as_maintenance_managers",
            employee_ids: this.model.bulkSelection.map(x => x.id)
          });
          return;
      }
    },

    bulkActionSuccessCallback() {
      this.$store.dispatch(
        `${this.model.singularName}/fetchAutoCompleteItems`,
        this.tableOptions
      );
      this.$refs.bulkActionDialog.close();
      this.model.bulkActionContext = null;
      this.model.bulkSelection = [];
    }
  },

  mounted() {
    this.$CpEvent.$on("bulkActionSuccess", this.bulkActionSuccessCallback);
  },

  beforeDestroy() {
    this.$CpEvent.$off("bulkActionSuccess");
  }
};
</script>
