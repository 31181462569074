import Overview from "./Overview";
import CurrentImport from "./CurrentImport";
import historyRoutes from "./history/routes";
import auditRoutes from "./audit/routes";
import transformRoutes from "./transform/routes";

export default [
  {
    path: "/import",
    name: "RootImports",
    component: Overview,
    meta: {
      title: "Import Overview",
    },
    children: [
      {
        path: "current",
        name: "CurrentImport",
        component: CurrentImport,
        meta: {
          title: "Import",
        },
      },
      ...historyRoutes,
    ],
  },
  ...auditRoutes,
  ...transformRoutes,
];
