import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VSelect,{staticClass:"mt-6 mb-4",attrs:{"solo":"","items":_vm.file.importTypeMap,"label":"Select Data Type","hide-details":"","clearable":""},on:{"input":function (importType) { return _vm.$store.dispatch('file/updateImportTypeFields', {
          job: _vm.job,
          importType: importType
        }); }},model:{value:(_vm.job.load_type),callback:function ($$v) {_vm.$set(_vm.job, "load_type", $$v)},expression:"job.load_type"}}),_c(VBtn,{staticClass:"mb-2",attrs:{"rounded":"","block":"","text":"","color":"primary"},on:{"click":function($event){_vm.file.importPreviewDialogOpen = true;
      _vm.$store.dispatch('file/fetchFieldMapDetails', _vm.job);}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-table-eye")]),_vm._v(" Preview Sample Data ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }