import { get } from "@cp/utils/objectUtils";
import { unique } from "@cp/utils/arrayUtils";

export default function orderedLabels() {
  const differences = get(this, "item.records.differences", []);
  const dataKeys = this.version ? Object.keys(this.version) : [];
  const modelKeys = get(this, "modelModule.form.fieldOrder", []);

  if (!differences.length || !modelKeys.length) {
    return dataKeys;
  }

  const sortByFormFieldOrder = (a, b) => {
    const indexOfA = modelKeys.indexOf(a);
    const indexOfB = modelKeys.indexOf(b);
    if (indexOfA !== -1 && indexOfB !== -1) return indexOfA - indexOfB;
    return 0;
  };

  // const diffLabels = differences
  //   .filter(x => dataKeys.includes(x))
  //   .sort(sortByFormFieldOrder);
  const formLabels = modelKeys.filter(x => dataKeys.includes(x));
  const rest = dataKeys.filter(x => !formLabels.includes(x));
  return unique([/*...diffLabels,*/ ...formLabels, ...rest]);
}
