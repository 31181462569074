<template>
  <v-card>
    <v-card-title>
      <v-icon
        v-if="location.location_type_id === 1"
        color="primary"
        title="office"
      >
        mdi-office-building-marker
      </v-icon>
      <v-icon
        v-if="location.location_type_id === 2"
        color="primary"
        title="property"
      >
        mdi-home-map-marker
      </v-icon>
      <strong class="mx-2">{{ location.location_type | capitalize }}:</strong>
      <span>{{ location.name }}</span>
    </v-card-title>
    <v-card-text>
      <div v-if="location.linked_locations.length">
        <strong class="warning--text">Already linked to:</strong>
        <span v-for="(loc, i) in location.linked_locations" :key="i">
          {{ loc.name }}
        </span>
        <br />
      </div>
      <strong>Address:</strong><br />
      {{ location.address }} {{ location.address_2 }} <br />
      {{ location.city }}, {{ location.state }} {{ location.zip }} <br />
      <template v-if="location.user_count">
        <strong>Employees:</strong> {{ location.user_count }} <br />
      </template>
      <template v-if="location.unit_count">
        <strong>Units:</strong> {{ location.unit_count }} <br />
      </template>
      <template v-if="location.region_name">
        <strong>Region:</strong> {{ location.region_name }} <br />
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    location: { type: Object },
  },
};
</script>
