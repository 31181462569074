<template>
  <div class="ReviewPendingChange" :class="{ modalIsOpen }">
    <div class="d-flex align-center pl-5">
      <template v-if="isError">
        <action-chip :action="pendingChange.pending_action" />
        <span class="ml-2">While importing</span>
      </template>
      <template v-else>
        <span class="mr-2">Proposed Change:</span>
        <action-chip :action="pendingChange.pending_action" />
      </template>
      <span class="ml-2">{{
        pluralize(pendingChange.changeable_type, 1)
      }}</span>
      <v-chip class="ml-2">{{ name }}</v-chip>

      <v-btn @click="closeModal" class="ml-auto" tile x-large color="primary">
        <v-icon large left>mdi-chevron-down</v-icon>
        Close
      </v-btn>
    </div>
    <v-divider />

    <div class="pa-3 pending-change">
      <template v-if="isError">
        <v-alert type="error" text>
          <div class="text-h6 error--text">
            Record for <strong>{{ name }}</strong>

            <span
              v-if="
                !pendingChange.errors.hasOwnProperty('reference_id') &&
                  pendingChange.records.new_version.reference_id
              "
              class="text-caption error--text"
              style="vertical-align: middle;"
            >
              ( Reference Id:
              {{ pendingChange.records.new_version.reference_id }}
              )
            </span>

            had these errors in your sheet:
          </div>
          <div
            v-for="(error, key) in pendingChange.errors || {}"
            :key="key"
            class="errorMessage my-3"
          >
            <div class="d-flex align-center">
              <strong>{{ snakeToTitleCase(key) }}:&nbsp;</strong>
              <div class="errorValue">
                {{ pendingChange.records.new_version[key] || "empty" }}
              </div>
            </div>
            <div>
              <span v-if="Array.isArray(error)" style="white-space: pre">{{
                error.join("\n")
              }}</span>
            </div>
          </div>
        </v-alert>

        <div class="d-flex justify-center mt-4">
          <v-btn @click="rejectChanges" color="error" outlined class="mr-4">
            <v-icon left>mdi-cancel</v-icon>
            Reject this record, continue importing
          </v-btn>
          <v-btn @click="resetImport" color="error">
            <v-icon left>mdi-close</v-icon>
            Cancel Import
          </v-btn>
        </div>
      </template>
      <div v-else>
        <v-row>
          <v-col>
            <pending-change-before />
          </v-col>
          <v-col>
            <applied-etls-display />
            <etl-form />
          </v-col>
          <v-col>
            <pending-change-after />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import pluralize from "pluralize";
import { mapState, mapActions } from "vuex";

import { get, getFirst } from "@cp/utils/objectUtils";
import { snakeToTitleCase } from "@cp/utils/stringUtils";

import {
  pendingChange,
  pendingChangesTable,
} from "@/store/modules/pendingChange";

export default {
  mixins: [pendingChange.modalMixin],
  computed: {
    ...mapState(pendingChange.mp, {
      pendingChange: pendingChange.keys.stateKey,
      modalIsOpen: pendingChange.keys.modalState,
    }),
    name() {
      const curFName = get(
        this.pendingChange,
        "records.current_version.first_name"
      );
      const curLName = get(
        this.pendingChange,
        "records.current_version.last_name"
      );
      if (curFName || curLName) return `${curFName} ${curLName}`;

      const newFName = get(
        this.pendingChange,
        "records.new_version.first_name"
      );
      const newLName = get(this.pendingChange, "records.new_version.last_name");
      if (newFName || newLName) return `${newFName} ${newLName}`;

      const otherPaths = [
        "records.current_version.name",
        "records.new_version.name",
        "records.current_version.unit_number",
        "records.new_version.unit_number",
      ];
      return getFirst(this.pendingChange, otherPaths);
    },
    isError() {
      return this.pendingChange.pending_action === "error";
    },
  },
  methods: {
    pluralize,
    snakeToTitleCase,
    ...mapActions(pendingChange.mp, ["acceptChanges", "rejectChanges"]),
    ...mapActions("confirm", ["confirm"]),
    closeModalOnEsc(evt) {
      if (evt.keyCode == 27) {
        if (this.modalIsOpen) {
          this.closeModal();
        } else {
          this.closeModal();
          this.$store.dispatch(pendingChangesTable.p.a.modalClose);
        }
      }
    },
    resetImport() {
      this.confirm({
        verb: "abandon",
        noun: "import",
        action: "file/resetImport",
      }).then(() => this.$store.dispatch(pendingChangesTable.p.a.modalClose));
    },
  },

  created() {
    window.addEventListener("keydown", this.closeModalOnEsc, false);
  },

  destroyed() {
    window.removeEventListener("keydown", this.closeModalOnEsc, false);
  },
};
</script>

<style lang="scss" scoped>
.ReviewPendingChange {
  transition: box-shadow 0.25s ease-in-out, height 0.25s ease-in-out;
  width: 100%;
  height: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: $white;
  z-index: 200;
  overflow: hidden;

  &.modalIsOpen {
    height: 100%;
    box-shadow: 0 -4px 15px 40px rgba($black, 15%);
  }
}

.pending-change {
  height: calc(100% - 53px);
  overflow: auto;
}

.errorValue {
  margin-left: 20px;
  padding: 0 5px;
  line-height: 1.3;
  border-radius: 5px;
  border: 1px solid;
}
</style>
