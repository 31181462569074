import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ReviewPendingChange",class:{ modalIsOpen: _vm.modalIsOpen }},[_c('div',{staticClass:"d-flex align-center pl-5"},[(_vm.isError)?[_c('action-chip',{attrs:{"action":_vm.pendingChange.pending_action}}),_c('span',{staticClass:"ml-2"},[_vm._v("While importing")])]:[_c('span',{staticClass:"mr-2"},[_vm._v("Proposed Change:")]),_c('action-chip',{attrs:{"action":_vm.pendingChange.pending_action}})],_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.pluralize(_vm.pendingChange.changeable_type, 1)))]),_c(VChip,{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.name))]),_c(VBtn,{staticClass:"ml-auto",attrs:{"tile":"","x-large":"","color":"primary"},on:{"click":_vm.closeModal}},[_c(VIcon,{attrs:{"large":"","left":""}},[_vm._v("mdi-chevron-down")]),_vm._v(" Close ")],1)],2),_c(VDivider),_c('div',{staticClass:"pa-3 pending-change"},[(_vm.isError)?[_c(VAlert,{attrs:{"type":"error","text":""}},[_c('div',{staticClass:"text-h6 error--text"},[_vm._v(" Record for "),_c('strong',[_vm._v(_vm._s(_vm.name))]),(
              !_vm.pendingChange.errors.hasOwnProperty('reference_id') &&
                _vm.pendingChange.records.new_version.reference_id
            )?_c('span',{staticClass:"text-caption error--text",staticStyle:{"vertical-align":"middle"}},[_vm._v(" ( Reference Id: "+_vm._s(_vm.pendingChange.records.new_version.reference_id)+" ) ")]):_vm._e(),_vm._v(" had these errors in your sheet: ")]),_vm._l((_vm.pendingChange.errors || {}),function(error,key){return _c('div',{key:key,staticClass:"errorMessage my-3"},[_c('div',{staticClass:"d-flex align-center"},[_c('strong',[_vm._v(_vm._s(_vm.snakeToTitleCase(key))+": ")]),_c('div',{staticClass:"errorValue"},[_vm._v(" "+_vm._s(_vm.pendingChange.records.new_version[key] || "empty")+" ")])]),_c('div',[(Array.isArray(error))?_c('span',{staticStyle:{"white-space":"pre"}},[_vm._v(_vm._s(error.join("\n")))]):_vm._e()])])})],2),_c('div',{staticClass:"d-flex justify-center mt-4"},[_c(VBtn,{staticClass:"mr-4",attrs:{"color":"error","outlined":""},on:{"click":_vm.rejectChanges}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-cancel")]),_vm._v(" Reject this record, continue importing ")],1),_c(VBtn,{attrs:{"color":"error"},on:{"click":_vm.resetImport}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v(" Cancel Import ")],1)],1)]:_c('div',[_c(VRow,[_c(VCol,[_c('pending-change-before')],1),_c(VCol,[_c('applied-etls-display'),_c('etl-form')],1),_c(VCol,[_c('pending-change-after')],1)],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }