<template>
  <div>
    <cp-search-autocomplete v-bind="searchProps" @input="input" />

    <v-checkbox v-bind="checkboxProps" @change="setAppend" />

    <div class="d-flex justify-end mt-6">
      <div v-if="success" class="d-flex align-center">
        <CpSuccessIcon class="mr-4" xLarge />
        {{ action.successMessage || "Success!" }}
      </div>
      <template v-else>
        <v-btn @click="action.modalConf.close" class="mr-4">Cancel</v-btn>
        <v-btn @click="confirm" color="primary" :loading="submitting">
          Assign selected manager
        </v-btn>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import { get } from "@cp/utils/objectUtils";

import { employee } from "@/store/modules/employee";
import { location } from "@/store/modules/location";

const SUB = location.table.subModuleRefs.bulkAssignManagers;

export default {
  name: "BulkSetEmployeesManager",
  props: {
    selected: { type: Array, required: true },
    action: { type: Object, required: true },
  },
  computed: {
    ...mapState(employee.search.mp, {
      items: employee.search.keys.stateKey,
      loading: employee.search.keys.loading,
    }),
    errors() {
      return this.$store.getters[SUB.p.g.errors];
    },
    formConf() {
      return {
        submitting: this.submitting,
        success: this.success,
        unhandledErrors: this.errors.unhandledErrors,
      };
    },
    selectedIds() {
      return this.selected.map(x => x.id);
    },
    searchProps() {
      return {
        form: SUB,
        name: "manager_ids",
        label: "Select Managers",
        multiple: true,
        searchModule: employee.search,
        params: { filters: { not_ids: this.selectedIds } },
        errorMessages: this.errors.manager_ids,
      };
    },
    checkboxProps() {
      const value =
        get(this.$store.state, SUB.p.s.formData + ".update_method") === "";
      return {
        label: SUB.fields.update_method.label,
        value,
      };
    },
    managerIds() {
      return;
    },
    success() {
      return get(this.$store.state, SUB.p.s.success);
    },
    submitting() {
      return get(this.$store.state, SUB.p.s.submitting);
    },
  },
  methods: {
    ...mapActions(employee.search.mp, {
      search: employee.search.keys.search,
    }),
    nameOfItem(item) {
      return (
        item.name ||
        item.full_name ||
        [item.first_name, item.last_name].join(" ") ||
        item.title ||
        item.unit_number
      );
    },
    input(value) {
      this.$store.commit(SUB.p.m.input, { manager_ids: value });
      this.$store.commit(SUB.p.m.setTouched, "manager_ids");
    },
    setAppend(value) {
      this.$store.commit(SUB.p.m.input, {
        update_method: value ? "" : "append",
      });
    },
    async confirm() {
      const manager_ids = get(
        this.$store.state,
        SUB.p.s.formData + ".manager_ids"
      );
      const update_method = get(
        this.$store.state,
        SUB.p.s.formData + ".update_method"
      );
      const data = {
        update_method,
        locations: this.selected.map(x => ({
          manager_ids,
          location_id: x.id,
        })),
      };
      this.$store.dispatch(SUB.p.a.confirm, data);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-label {
  display: block;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  color: $gray-4;
}

.v-card__actions {
  padding: 0 24px 20px !important;
}
</style>
