import { Api } from "@cp/lib";
import { mapItem, mapItems } from "@cp/utils/jsonapiUtils";
import capitalize from "vue-string-filter/dist/capitalize";
import { get } from "@cp/utils/objectUtils";

import { mergeMixins } from "@cp/store/mixins";
import { Audit } from "../mixins/Audit";

export const statusMap = {
  1: "active",
  2: "archived",
};

const baseUrl = `${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1/`;
const bApi = new Api(baseUrl);

export default class {
  constructor(singularName, pluralName, path = "", singularPath = "") {
    const urlPath = path || pluralName;

    this.namespaced = true;

    const audit = new Audit({
      module: singularName,
      baseUrl: baseUrl + urlPath,
    });

    const mixins = mergeMixins(audit);
    // prettier-ignore
    this.state = {
      ...mixins.state(),
      item: {                         // TODO -- use ItemFormModal
        locations: [],
      },
      items: [],                      // TODO -- use ModelTable
      errors: {},                     // TODO -- use ItemFormModal
      pending_errors: {},             // TODO -- remove?
      total: 0,                       // TODO -- use ModelTable.getters
      saving: false,                  // TODO -- use ItemFormModal.submitting
      loading: false,                 // TODO -- use ItemFormModal.loading
      searching: false,               // TODO -- remove?
      searchTimeout: null,            // TODO -- remove? (already handled by ItemsTable debounce)
      client: bApi,                   // TODO -- remove? use ApiDataMixin
      meta: {},                       // TODO -- use ModelTable
      existingRecordHash: {},         // TODO -- use ItemFormModal validation
      formIsValid: true,              // TODO -- use ItemFormModal validation
      editDialogOpen: false,          // TODO -- use store/confirm
      createDialogOpen: false,        // TODO -- use ItemFormModal.mixin, combine Create/Edit modals
      bulkSelection: [],              // TODO -- add to ModelTable
      bulkActionDialogOpen: false,    // TODO -- add to ModelTable
      bulkActionContext: null,        // TODO -- add to ModelTable
      locationManagers: [],           // TODO -- remove, add only to certain models
      locationMaintenanceManagers: [],// TODO -- remove, add only to certain models
      singularName: singularName,     // TODO -- add to ModelTable
      singularPath: singularPath,     // TODO -- remove?
      pluralName: pluralName,         // TODO -- add to ModelTable
      path: path,                     // TODO -- remove? use ApiDataMixin
      recordDetailTabs: ["General", "History"], // TODO -- add to ModelTable
      records: 0,                     // TODO -- use ItemsTable.getters.serverItemsLength
      actionConfirmation: {           // TODO -- remove? use store/confirm
        show: false,
        successCallback: "",
        successCallbackData: null,
        confirmationTitle: null,
        confirmationBody: null,
      },
      status_map: statusMap,          // TODO -- add to ModelTable as default
      statusColors: {                 // TODO -- add to ModelTable as default
        active: "success",
        archived: "error",
      },
      itemsKeyMapper: {},             // TODO -- remove?
      trackChangesHash: (fields, item) => { // TODO -- use ItemFormModal validation
        let trackedObject = {};
        fields.forEach(key => {
          if (Array.isArray(item[key])) {
            trackedObject[key] = item[key].sort() || null;
          } else {
            trackedObject[key] = item[key] !== null ? item[key] : null;
          }
        });
        return JSON.stringify(trackedObject);
      },
      formRules: {                    // TODO -- use ItemFormModal validation
        required: value => !!value || "Required",
        noSpecialChars: value =>
          !["'", '"', " "].some(char => (value || "").includes(char)) ||
          "No special characters allowed",
      },
    };

    this.getters = {
      ...mixins.getters,
      listRoute: () => ({
        name: capitalize(pluralName),
      }),

      autocompleteUrl: () => `${urlPath}/autocomplete`,

      itemRoute() {
        return ({ id }) => ({
          name: capitalize(pluralName),
          params: { uuid: id },
        });
      },

      filterOptions(state) {
        if (!state.meta || !state.meta.filter_options) return [];

        let filterOptions = state.meta.filter_options;
        return Object.keys(filterOptions).map(x => ({
          placeholder: filterOptions[x].label,
          items: filterOptions[x].values.map(y => ({
            text: y.text,
            value: { [x]: y.value },
          })),
        }));
      },

      editDialogHeader({ item }) {
        return item.full_name || item.name;
      },

      formFields(state) {
        return [];
      },

      trackedProperties(state, getters) {
        return getters.formFields.map(x => x.value);
      },

      isDirty(state, getters) {
        return (
          state.existingRecordHash !=
          state.trackChangesHash(getters.trackedProperties, state.item)
        );
      },

      globalBulkActions(state) {
        return [
          {
            title: "Restore",
            type: "storeAction",
            action: `${singularName}/openBulkActionDialog`,
            data: {
              context: "bulk-restore",
            },
          },
          {
            title: "Archive",
            type: "storeAction",
            action: `${singularName}/openBulkActionDialog`,
            data: {
              context: "bulk-archive",
            },
          },
        ];
      },

      bulkActions(state, { globalBulkActions }) {
        return globalBulkActions;
      },

      globalInstanceActions(state, getters) {
        return [
          {
            title: "Restore",
            type: "storeAction",
            action: `${singularName}/restore`,
            color: "success",
            shouldShow: state.item.status_id == 2,
            requiresConfirmation: true,
            confirmationBody: `Are you sure you want to restore this ${singularName}?`,
            data: state.item.id,
          },
          {
            title: "Archive",
            type: "storeAction",
            action: `${singularName}/archive`,
            color: "error",
            icon: "archive",
            shouldShow: state.item.status_id == 1,
            requiresConfirmation: true,
            confirmationBody: `Are you sure you want to archive this ${singularName}?`,
            data: state.item.id,
          },
          {
            title: "Update",
            type: "componentMethod",
            action: "validate",
            shouldShow: true,
            disabled: !getters.isDirty || !state.formIsValid || state.saving,
          },
        ];
      },

      recordDetailTabs({ recordDetailTabs }) {
        return recordDetailTabs;
      },
    };

    this.mutations = {
      ...mixins.mutations,
      setSaving(state, savingState) {
        state.saving = savingState;
      },

      setItems(state, response) {
        state.errors = [];
        state.items = mapItems(response);
        state.records = get(
          response,
          "meta.pagination.records",
          state.items.length
        );
        state.total = get(
          response,
          "meta.pagination.records",
          state.items.length
        );
        state.meta = response.meta;
      },

      setItem(state, response) {
        const item = response.data;
        const included = response.included;
        state.item = {
          ...state.item,
          ...mapItem(item, included),
        };

        this._vm.$CpEvent.$emit(`${singularName}Loaded`, state.item.id);
      },

      update(state, { data }) {
        state.errors = [];
        Object.assign(state.item, data.attributes);
        const index = state.items.findIndex(obj => obj.id === data.id);
        Object.assign(state.items[index], data.attributes);
      },

      create(state, { data }) {
        state.errors = [];
        state.items.push(data.attributes);
        state.total = state.total + 1;
      },

      remove(state, id) {
        state.errors = [];
        const index = state.items.findIndex(obj => obj.id === id);
        state.items.splice(index, 1);
        state.total = state.total - 1;
      },

      errors(state, data) {
        state.errors = data;
      },

      reorder(state, data) {
        const movedItem = state.items.splice(data.oldIndex, 1)[0];
        state.items.splice(data.newIndex, 0, movedItem);
      },

      clear(state) {
        state.item = {
          locations: [],
        };
        state.saving = false;
      },

      reset(state) {
        state.item = {
          locations: [],
        };
        state.existingRecordHash = {};
        state.bulkActionContext = null;
        state.bulkSelection = [];
        state.locationManagers = [];
      },

      setCreateDialogWindow(state, isOpen) {
        state.createDialogOpen = isOpen;
      },

      setEditDialogWindow(state, isOpen) {
        state.editDialogOpen = isOpen;
      },

      setBulkActionDialog(state, isOpen) {
        state.bulkActionDialogOpen = isOpen;
      },

      setExistingRecordHash(state, recordHash) {
        state.existingRecordHash = recordHash;
      },

      setConfirmationDialog(
        state,
        { isOpen = true, action = "", data = null, title = null, body = null }
      ) {
        state.actionConfirmation.show = isOpen;
        state.actionConfirmation.successCallback = action;
        state.actionConfirmation.successCallbackData = data;
        state.actionConfirmation.confirmationTitle = title;
        state.actionConfirmation.confirmationBody = body;
      },
    };

    this.actions = {
      ...mixins.actions,
      fetchAutoCompleteItems(
        { commit, state, rootState, getters },
        options = {}
      ) {
        state.loading = true;
        const url = getters["autocompleteUrl"];

        return state.client
          .authorize()
          .get(url, {
            page: {
              number: options.page,
              size: options.itemsPerPage,
            },
            q_text: options.q_text,
            filters: options.filters,
            sort_by: options.sortBy,
            sort_desc: options.sortDesc,
          })
          .then(data => {
            commit("setItems", data);

            if (rootState.region.locationAssignmentDialogOpen) {
              rootState.location.items = rootState.location.items.filter(
                x =>
                  !rootState.region.item.locations.map(y => y.id).includes(x.id)
              );
            }
          })
          .finally(() => {
            state.loading = false;
          });
      },

      fetchAutoCompleteItemsTimeout({ state, dispatch }, options = {}) {
        clearTimeout(state.searchTimeout);
        state.searchTimeout = setTimeout(() => {
          dispatch("fetchAutoCompleteItems", options);
        }, 250);
      },

      fetchItems({ commit, state }, options = {}) {
        state.loading = true;
        return state.client
          .authorize()
          .get(`/${urlPath}`)
          .then(data => {
            commit("setItems", data);
          })
          .finally(() => {
            state.loading = false;
          });
      },

      fetchItem({ commit, state, getters }, id) {
        state.loading = true;
        return state.client
          .authorize()
          .get(`/${urlPath}/${id}`, { full_details: true })
          .then(data => {
            commit("setItem", data);
            commit(
              "setExistingRecordHash",
              state.trackChangesHash(getters.trackedProperties, state.item)
            );
            return data;
          })
          .finally(() => {
            state.loading = false;
          });
      },

      fetchLocationManagers({ commit, state }, value) {
        state.searching = true;

        return state.client
          .authorize()
          .get("/users/autocomplete", {
            q_text: value,
          })
          .then(({ data }) => {
            state.locationManagers = data.map(x => {
              if (x.id.split(",").length > 1) {
                x.id = x.id.split(",")[0];
              }

              return { text: x.attributes.full_name, value: x.id };
            });
          })
          .finally(() => {
            state.searching = false;
          });
      },

      fetchLocationMaintenanceManagers({ commit, state }, value) {
        state.searching = true;

        return state.client
          .authorize()
          .get("/users/autocomplete", {
            q_text: value,
          })
          .then(({ data }) => {
            state.locationMaintenanceManagers = data.map(x => {
              if (x.id.split(",").length > 1) {
                x.id = x.id.split(",")[0];
              }

              return { text: x.attributes.full_name, value: x.id };
            });
          })
          .finally(() => {
            state.searching = false;
          });
      },

      create({ commit, state }, data) {
        state.errors = {};
        commit("setSaving", true);
        return state.client
          .authorize()
          .post(`${urlPath}`, data)
          .then(data => {
            // commit("create", data);
            this._vm.$CpEvent.$emit(`${singularName}Created`);
            this._vm.$CpEvent.$emit(`snackAlert`, {
              message: `${singularName} Created!`,
            });
          })
          .catch(error => {
            state.errors = error.response.data;
          })
          .finally(() => {
            commit("setSaving", false);
          });
      },

      update({ state, commit, dispatch }, data) {
        state.errors = {};
        commit("setSaving", true);
        return state.client
          .authorize()
          .put(`${urlPath}/${data.id}`, data)
          .then(data => {
            commit("setItem", data);
            dispatch("fetchAudits", { id: data.data.id });
            this._vm.$CpEvent.$emit(`${singularName}Updated`);
            this._vm.$CpEvent.$emit(`snackAlert`, {
              message: `${singularName} Updated!`,
            });
          })
          .catch(error => {
            state.errors = error.response.data;
          })
          .finally(() => {
            commit("setSaving", false);
          });
      },

      destroy({ commit, state }, id) {
        state.saving = true;

        return state.client
          .authorize()
          .delete(`/${urlPath}/${id}`)
          .then(() => {
            this._vm.$CpEvent.$emit(`${singularName}Destroyed`);
            this._vm.$CpEvent.$emit(`snackAlert`, {
              message: `${singularName} Deleted!`,
            });
          })
          .finally(() => {
            state.saving = false;
          });
      },

      restore({ commit, state }, ids = null) {
        state.saving = true;

        if (ids != null && !Array.isArray(ids)) {
          ids = [ids];
        }

        return state.client
          .authorize()
          .put(`/${urlPath}/restore`, { ids: ids })
          .then(() => {
            if (ids.length > 1) {
              this._vm.$CpEvent.$emit(`bulkActionSuccess`);
            } else {
              this._vm.$CpEvent.$emit(`${singularName}Updated`);
            }

            this._vm.$CpEvent.$emit(`snackAlert`, {
              message: "Records restored",
            });
          })
          .finally(() => {
            state.saving = false;
          });
      },

      archive({ commit, state }, ids = null) {
        state.saving = true;

        if (ids != null && !Array.isArray(ids)) {
          ids = [ids];
        }

        return state.client
          .authorize()
          .put(`/${urlPath}/archive`, { ids: ids })
          .then(() => {
            if (ids.length > 1) {
              this._vm.$CpEvent.$emit(`bulkActionSuccess`);
            } else {
              this._vm.$CpEvent.$emit(`${singularName}Updated`);
            }

            this._vm.$CpEvent.$emit(`snackAlert`, {
              message: "Records archived",
            });
          })
          .finally(() => {
            state.saving = false;
          });
      },

      exportList({ state }, modelOnly = true) {
        return state.client
          .authorize()
          .download(`/dashboard/export/export.xlsx`, null, {
            [path || pluralName]: modelOnly,
          })
          .then(() => {
            this._vm.$CpEvent.$emit(`snackAlert`, {
              message: "Records exported",
            });
          });
      },

      reorder({ commit, state }, { newIndex, oldIndex }) {
        const data = {
          newIndex: newIndex,
          oldIndex: oldIndex,
        };
        commit("reorder", data);
        return state.client.authorize().post(`/${urlPath}/reorder`, data);
      },

      errors({ commit }, data) {
        commit("errors", data);
      },

      clear({ commit }) {
        commit("clear");
      },

      openCreateDialog({ state, commit, getters, dispatch }) {
        state.item = {
          locations: [],
        };
        this._vm.$CpEvent.$emit("resetModelForm");
        commit("setCreateDialogWindow", true);
        commit(
          "setExistingRecordHash",
          state.trackChangesHash(getters.trackedProperties, state.item)
        );
      },

      openEditDialog({ commit, dispatch }, id) {
        this._vm.$CpEvent.$emit("resetModelForm");
        dispatch("fetchAudits", { id });
        commit("setEditDialogWindow", true);
        return dispatch("fetchItem", id);
      },

      changeEditDialogTab({ getters, dispatch, state }, activeTabIndex) {
        const tab = getters["recordDetailTabs"][activeTabIndex];
        const tabText = tab.text || tab;

        // default behavior only affects history tab
        if (tabText === "History")
          dispatch("fetchAudits", { id: state.item.id });
      },

      openBulkActionDialog({ state, commit }, { context }) {
        state.bulkActionContext = context;
        commit("setBulkActionDialog", true);
      },

      fetchRelatedOptions() {},
    };
  }
}
