import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"etl-form text-center"},[(_vm.isCreating)?_c('cp-card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Adding: "+_vm._s(_vm.selectedOption.text))]},proxy:true}],null,false,3851644377)},[_c('h2',[_vm._v("What will this do")]),(_vm.etl.transform_type === 'ignore_record')?_c('p',[_vm._v(" Ignore future imports of this "),_c('strong',[_vm._v(_vm._s(_vm.modelModule.noun))]),_c('extract-id-form')],1):_vm._e(),(_vm.etl.transform_type === 'merge_record')?_c('div',[_vm._v(" TODO -- merge record form ")]):_vm._e(),(_vm.etl.transform_type === 'ignore_attributes')?_c('div',[_c('p',[_vm._v(" When importing "),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" this "),_c('strong',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.record.singularName)))]),_c('sup',[_c(VIcon,{attrs:{"x-small":""}},[_vm._v(" mdi-information-outline ")])],1)])]}}],null,false,2594903665)},[_vm._v(" Remote ID: "+_vm._s(_vm.item.reference_id)+" ")]),_vm._v(" the import will ignore "),(_vm.ignoredAttributes && _vm.ignoredAttributes.length)?[_vm._v(" these fields:"),_c('br'),_vm._l((_vm.ignoredAttributes),function(attribute){return _c(VChip,{key:attribute,staticClass:"ma-1"},[_vm._v(" "+_vm._s(_vm._f("snakeToTitleCase")(attribute))+" ")])})]:[_vm._v(" the fields you select. ")]],2)]):_vm._e(),(_vm.etl.transform_type === 'prevent_events')?_c('div',[_vm._v(" TODO -- ignore record form ")]):_vm._e(),(_vm.selectedOption.use_case)?[_c('h2',[_vm._v("When to use this")]),_c('p',[_vm._v(_vm._s(_vm.selectedOption.use_case))])]:_vm._e(),_c(VBtn,{attrs:{"color":"primary","disabled":_vm.submitDisabled},on:{"click":_vm.submit}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" Add ")],1),_c(VBtn,{staticClass:"ml-4",on:{"click":_vm.resetEtl}},[_c(VIcon,[_vm._v("mdi-cancel")]),_vm._v(" Cancel ")],1)],2):(_vm.options.length)?_c('div',{staticClass:"d-flex align-center justify-center"},[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"outlined":"","color":"warning"}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Transformation ")],1)]}}])},[_c(VList,_vm._l((_vm.options),function(ref){
var id = ref.id;
var text = ref.text;
var description = ref.description;
var transform_type = ref.transform_type;
return _c(VListItem,{key:id,attrs:{"title":description},on:{"click":function($event){return _vm.open({ transform_type: transform_type })}}},[_c(VListItemTitle,[_vm._v(_vm._s(text))])],1)}),1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }