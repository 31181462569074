import corporateRoutes from "./corporate/routes.js";
import Company from "./Company.vue";
import areaRoutes from "./area/routes.js";
import regionRoutes from "./region/routes.js";
import reportingGroupRoutes from "./reportingGroup/routes.js";

export default [
  {
    path: "/company",
    name: "RootCompany",
    component: Company,
    meta: {
      title: "Your Company",
    },
    children: [
      ...corporateRoutes,
      ...areaRoutes,
      ...regionRoutes,
      ...reportingGroupRoutes,
    ],
  },
];
