<template>
  <div class="pa-3">
    <v-btn @click="openAssignRegions" color="primary" class="mb-3">
      <v-icon class="mr-1">mdi-plus</v-icon>Assign New Regions
    </v-btn>
    <v-expansion-panels multiple>
      <v-expansion-panel v-for="region in model.item.regions" :key="region.id">
        <v-expansion-panel-header>
          <span>
            <v-btn icon color="error" @click.stop="removeRegion(region.id)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            {{ region.name }}
          </span>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          Employees: {{ region.user_count }}
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <cp-dialog
      :show-model.sync="area.regionAssignmentDialogOpen"
      max-width="1100"
      header-background
      scrollable
      @modalClosed="tableOptions.q_text = null"
    >
      <template #title>
        Assign Regions
      </template>

      <template #body>
        <list-table
          :static-headers="locHeaders"
          model-name="region"
          hide-header
          hide-filters
          hide-bulk-actions
          flat
        />
      </template>

      <template #actions>
        <v-btn
          color="primary"
          @click="assignRegions"
          :disabled="region.saving || region.bulkSelection.length <= 0"
          :loading="region.saving"
        >
          Assign Regions to Area
        </v-btn>
      </template>
    </cp-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import pluralize from "pluralize";

export default {
  name: "AreaRegions",

  props: {
    model: { type: Object, default: () => {} }
  },

  computed: {
    ...mapState({
      area: state => state.area,
      region: state => state.region,
      tableOptions: state => state.table
    }),

    locHeaders() {
      return [
        {
          text: "Name",
          value: "name",
          linkCell: true,
          width: "auto"
        },
        {
          text: "Manager",
          value: "group_manager_name",
          width: "15%"
        },
        {
          text: "Locations",
          value: "locations.length",
          width: "15%"
        }
      ];
    }
  },

  methods: {
    openAssignRegions() {
      const filters = [
        {
          depth: "corporate"
        },
        {
          depth: "region"
        }
      ];
      const options = {
        ...this.tableOptions,
        filters
      };
      this.$store.dispatch("region/fetchAutoCompleteItems", options);
      this.area.regionAssignmentDialogOpen = true;
    },

    assignRegions() {
      const region_ids = this.region.bulkSelection.map(x => x.id);
      this.$store
        .dispatch("region/massAssignArea", {
          area_id: this.area.item.id,
          region_ids
        })
        .then(() => {
          const count = region_ids.length;
          const noun = pluralize("Region", count);
          this.$store.dispatch("area/fetchItem", this.area.item.id);
          this.area.regionAssignmentDialogOpen = false;
          this.$CpEvent.$emit("regionUpdated");
          this.$CpEvent.$emit("snackAlert", {
            message: `${count} ${noun} added to ${this.area.item.name}!`
          });
        });
    },

    removeRegion(locId) {
      this.$store
        .dispatch("region/updateArea", { id: locId, area_id: null })
        .then(() => {
          this.$CpEvent.$emit("regionUpdated");
          this.$CpEvent.$emit("snackAlert", {
            message: `Region removed from ${this.area.item.name}!`
          });
          this.$store.dispatch("area/fetchItem", this.area.item.id);
        });
    }
  }
};
</script>
