<template>
  <cp-fullscreen-modal
    :show.sync="model.editDialogOpen"
    :title="`${model.noun || model.singularName}`"
    back-text="Close"
    back-icon="close"
    :close-confirmation="$store.getters[`${model.singularName}/isDirty`]"
    @fullscreenModalClosed="reset"
  >
    <cp-card flush-body>
      <template #title>
        <span v-html="modelGetter('editDialogHeader')" />
        <h5 class="text-subtitle-2 text--secondary">
          Created {{ formatAmericanDateWithTime(model.item.created_at) }}
          <span v-if="model.item.status_id == 2" class="red--text">
            | Archived On {{ model.item.archived_at }}
          </span>
        </h5>
      </template>

      <template #actions>
        <model-edit-dialog-actions
          :model="model"
          @action="handleInstanceAction"
        />
      </template>

      <v-tabs
        v-model="activeTab"
        slider-color="primary"
        background-color="#f1f1f1"
      >
        <v-tabs-slider />
        <v-tab v-for="(tab, i) in modelGetter('recordDetailTabs')" :key="i">
          {{ tab.text || tab }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab">
        <v-tab-item
          v-for="(tab, i) in modelGetter('recordDetailTabs')"
          :key="i"
        >
          <template v-if="tab == 'General'">
            <h6 class="text-h6 text--secondary pa-3">
              {{ model.noun || model.singularName | capitalize }} Information
            </h6>

            <model-form-set ref="modelFormSet" :model.sync="model" />
          </template>

          <template v-if="tab == 'Related Items'">
            <h6 class="text-h6 text--secondary pa-3">
              Related Items
            </h6>

            <related-items :model="model" />
          </template>

          <template v-if="tab == 'Office'">
            <model-form-set ref="modelFormSet" :model.sync="model" />
            <hr class="formset-divide" />
            <linked-locations />
          </template>

          <template v-if="tab == 'Property'">
            <linked-locations />
            <hr class="formset-divide" />
            <model-form-set ref="modelFormSet" :model.sync="model" />
          </template>

          <template v-if="tab == 'Settings'">
            <h6 class="text-h6 text--secondary pa-3">
              {{ model.noun || model.singularName | capitalize }} Permissions
            </h6>

            <roles :model.sync="model" />
          </template>

          <template v-if="tab == 'Locations'">
            <region-locations :model.sync="model" />
          </template>

          <template v-if="tab == 'Regions'">
            <area-regions :model.sync="model" />
          </template>

          <template v-if="tab == 'History'">
            <h6 class="text-h6 text--secondary pa-3">
              {{ model.noun || model.singularName | capitalize }} Change History
            </h6>
            <div
              v-for="(auditsOnDay, index) in modelGetter('auditsByDate')"
              class="px-3 pb-3"
              :key="`audit-${index}`"
            >
              <v-sheet color="#f7f7f7" rounded class="pa-1 px-2 mb-2">
                <span
                  style="color: #666666"
                  class="text-subtitle-1 font-weight-bold"
                >
                  {{ auditsOnDay.date }}
                </span>
              </v-sheet>
              <audits
                v-for="(audit, ai) in auditsOnDay.audits"
                :audit="audit"
                :model="model"
                :key="ai"
              />
            </div>
          </template>

          <template v-if="tab.component">
            <component :is="tab.component" :model.sync="model" />
          </template>
        </v-tab-item>
      </v-tabs-items>
    </cp-card>
  </cp-fullscreen-modal>
</template>

<script>
import { mapState } from "vuex";
import helpers from "@cp/mixins/helpers";
import ModelEditDialogActions from "./ModelEditDialogActions.vue";

export default {
  components: { ModelEditDialogActions },
  props: {
    model: { type: Object, default: () => {} },
    backToListOnClose: { type: Boolean, default: true },
  },

  mixins: [helpers],

  data() {
    return {
      activeTab: 0,
    };
  },

  computed: {
    ...mapState({
      tableOptions: state => state.table,
    }),
    auditsByDate() {
      return this.$store.getters[`${this.model.singularName}/auditsByDate`];
    },
  },

  methods: {
    callInstanceAction(actionName, data = null) {
      this[actionName](data);
    },

    modelGetter(key) {
      return this.$store.getters[`${this.model.singularName}/${key}`];
    },

    showAlert(message) {
      this.$CpEvent.$emit(`snackAlert`, {
        message: message,
        color: "error",
        dismissable: true,
      });
    },

    validate() {
      this.$refs.modelFormSet.forEach(formset => formset.validate());

      if (this.model.formIsValid) {
        this.$store.dispatch(
          `${this.model.singularName}/update`,
          this.model.item
        );
      }
    },

    reset() {
      this.activeTab = 0;
      if (this.$refs.modelFormSet)
        this.$refs.modelFormSet.forEach(formset => formset.reset());
      this.model.editDialogOpen = false;
      this.$store.commit(`${this.model.singularName}/reset`);
      this.$store.dispatch(
        `${this.model.singularName}/fetchAutoCompleteItems`,
        this.tableOptions
      );

      const listRoute = this.$store.getters[
        `${this.model.singularName}/listRoute`
      ];

      if (!this.backToListOnClose) return;
      this.$router.push(listRoute);
    },

    handleInstanceAction(ia) {
      if (ia.requiresConfirmation) {
        this.$store.commit(`${this.model.singularName}/setConfirmationDialog`, {
          action: ia.action,
          data: ia.data,
          title: ia.confirmationTitle,
          body: ia.confirmationBody,
        });
        return;
      }

      ia.type == "storeAction"
        ? this.$store.dispatch(ia.action, ia.data)
        : this.callInstanceAction(ia.action, ia.data);
    },
  },

  watch: {
    activeTab(newVal) {
      this.$store.dispatch(
        `${this.model.singularName}/changeEditDialogTab`,
        newVal
      );
    },
  },

  mounted() {
    this.$CpEvent.$on(`${this.model.singularName}Updated`, () => {
      this.$store.dispatch(
        `${this.model.singularName}/fetchItem`,
        this.model.item.id
      );
    });

    this.$CpEvent.$on(`${this.model.singularName}Destroyed`, () => {
      this.reset();
    });
  },

  beforeDestroy() {
    this.$CpEvent.$off(`${this.model.singularName}Updated`);
    this.$CpEvent.$off(`${this.model.singularName}Destroyed`);
  },
};
</script>

<style scoped>
.formset-divide {
  margin: 8px 0 0;
  border-width: 1px 0 0 0;
}
</style>
