import { slugify } from "carrot-patch-v2/src/utils/stringUtils";
import Crud from "./generic";
import { toEXACT } from "carrot-patch-v2/src/utils/dateUtils";
import { get } from "carrot-patch-v2/src/utils/objectUtils";
import pluralize from "pluralize";

let base = new Crud("import", "imports", "import_history", "import");

export const summaryTextMap = {
  create: "created",
  update: "changed",
  delete: "archived",
  archived: "archived",
  restore: "restored",
  restored: "restored"
};

export default {
  namespaced: true,

  state: {
    ...base.state,
    item: {
      audit_summary: {},
      id: ""
    },
    listTableHeaders: [
      {
        text: "Date, Time",
        value: "format",
        format: ({ created_at }) => toEXACT(created_at),
        width: "200px",
        sortable: false
      },
      {
        text: "File Name",
        value: "parent_upload_details.name",
        width: "350px"
      },
      {
        text: "Sheets",
        value: "component_cell",
        component: "upload-sheets",
        width: "auto"
      }
    ],
    statusComponent: "import-status",
    currentTabIndex: 0
  },

  getters: {
    ...base.getters,
    bulkActions: () => [],

    listRoute() {
      return { name: "ImportHistory" };
    },

    instanceActions() {
      return [];
    },

    currentTab({ currentTabIndex }, { recordDetailTabs }) {
      return recordDetailTabs[currentTabIndex];
    },

    editDialogHeader({ item }) {
      const sheetName = get(item, "upload_details.sheet_name");
      return `<strong>Sheet:</strong> ${sheetName}`;
    },
    autocompleteUrl: () => `import_history/uploads`,

    recordDetailTabs: ({ item }) => {
      const keys = Object.keys(item.audit_summary || []);
      if (!keys.length)
        return [
          {
            text: "No changes were made",
            component: "upload-results",
            slug: "no-changes"
          }
        ];
      return keys.map(slug => {
        const model = pluralize(
          item.import_model,
          item.audit_summary[slug],
          true
        );
        return {
          slug,
          text: `${model} ${summaryTextMap[slug]}`,
          component: "upload-results"
        };
      });
    }
  },

  actions: {
    ...base.actions,
    changeEditDialogTab({ state, commit, getters }, tabIndex) {
      state.currentTabIndex = tabIndex;
      const tab = getters["currentTab"];
      state.noun = tab.text;
      const filters = [{ general: tab.slug }];

      commit("table/clear", null, { root: true });
      commit("table/setFilters", filters, { root: true });
    }
  },

  mutations: {
    ...base.mutations
  }
};
