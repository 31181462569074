import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mar-import-summary fill-height pa-2",class:{
    'mar-import-summary--ready': _vm.stepCompleted,
    'mar-import-summary--cancelled': _vm.job.step == 'cancelled',
    'mar-import-summary--issue':
      !_vm.stepCompleted && _vm.job.upload_details.messages != null,
  },attrs:{"flat":""}},[_c(VIcon,{staticClass:"mar-import-summary__icon",attrs:{"color":"success"}},[_vm._v(" mdi-check-circle ")]),(_vm.showMessages)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"mar-import-summary__icon--issue",attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")])]}}],null,false,363452016)},_vm._l((_vm.job.upload_details.messages),function(message,i){return _c('h4',{key:i},[_vm._v(" "+_vm._s(message)+" ")])}),0):_vm._e(),_c('p',{staticClass:"mb-0 text-subtitle-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.job.is_followup_task ? "Task" : "Sheet")+": "+_vm._s(_vm.job.upload_details.sheet_name)+" "),(!['cancelled', 'completed', 'processing'].includes(_vm.job.step))?_c('cp-menu',{attrs:{"left":"","options":_vm.jobMenuOptions(_vm.job)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('cp-btn',_vm._g(_vm._b({staticClass:"ml-0",attrs:{"icon":"","small":""}},'cp-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,903903043)}):_vm._e()],1),(_vm.job.step == 'cancelled')?_c('div',{staticClass:"text-center"},[_c(VIcon,{attrs:{"size":"64px"}},[_vm._v("mdi-close-circle-outline")]),_c('h5',{staticClass:"text-subtitle-2"},[_vm._v("CANCELLED")])],1):[_vm._v(" Status: "+_vm._s(_vm._f("capitalize")(_vm.job.step))+" "),(_vm.file.importStep == 1)?_c('set-data-type',{attrs:{"job":_vm.job}}):_vm._e(),(_vm.file.importStep == 2)?_c('map-fields',{attrs:{"job":_vm.job}}):_vm._e(),(_vm.file.importStep == 3)?_c('process-uploads',{attrs:{"job":_vm.job}}):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }