<template>
  <div>
    <v-row>
      <v-col align="center">
        <h4 class="text-subtitle-1 font-weight-bold text--secondary">
          {{ file.importTypeMap.find(x => x.value == job.load_type).text }}s
        </h4>
        <v-icon size="64px">mdi-file-table</v-icon>
        <v-icon size="44px">mdi-arrow-right</v-icon>
        <v-icon size="78px">mdi-table</v-icon>
      </v-col>
    </v-row>
    <v-btn
      rounded
      block
      text
      :color="job.step == 'cancelled' ? 'error' : 'primary'"
      class="mb-2"
      @click="
        file.fieldMappingDialogOpen = true;
        $store.dispatch('file/fetchFieldMapDetails', job);
      "
    >
      Edit Field Mapping
    </v-btn>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    job: { type: Object, default: () => {} }
  },

  computed: {
    ...mapState({
      file: state => state.file
    })
  }
};
</script>
