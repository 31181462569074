import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSheet,{staticClass:"mt-4",attrs:{"elevation":"3"}},[_c(VContainer,[_c('h2',{staticClass:"text-subtitle-1 font-weight-bold",staticStyle:{"color":"#666666"}},[_vm._v(" Imported Information ")]),_c(VList,{attrs:{"dense":""}},_vm._l((_vm.importedFields),function(ref,i){
var text = ref.text;
var value = ref.value;
return _c(VListItem,{key:i},[_c(VListItemContent,[_vm._v(_vm._s(text)+":")]),_c(VListItemContent,[_vm._v(" "+_vm._s(_vm.model.item[value])+" ")])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }