<template>
  <div class="mar-edit-change-window" :class="{ 'is-active': modalIsOpen }">
    DEPRICATED
    <!-- <cp-card flat dense flush-body scrollable>
      <template #title> Details for this {{ modelTitle }} </template>

      <template #actions>
        <v-btn
          v-if="file.importReviewTab != 1"
          @click="updateRecord"
          color="success"
        >
          <v-icon class="mr-2">mdi-check</v-icon>
          Save Changes
        </v-btn>
        <v-btn text color="primary" @click="closeModal">
          <v-icon>mdi-chevron-down</v-icon>
          Close
        </v-btn>
      </template>

      <template v-if="file.importReviewTab != 1 && modalIsOpen">
        <v-container v-if="modelModule">
          <v-row>
            <v-col cols="6">
              <applied-etls-display />
              <etl-form />
            </v-col>
            <v-col cols="6">
              <v-sheet class="pa-2">
                <model-form
                  :modelModule="modelModule"
                  mode="import"
                  :cols="{ md: 12 }"
                />
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-if="file.importReviewTab == 1">
        <v-container>
          <v-row>
            <v-col>
              <compare-pending-change version="current_version" />
            </v-col>

            <v-col>
              <applied-etls-display />
              <etl-form />
            </v-col>

            <v-col>
              <compare-pending-change version="new_version" />
            </v-col>
          </v-row>
        </v-container>
      </template>
    </cp-card> -->
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import {
  pendingChange,
  pendingChangesTable,
} from "@/store/modules/pendingChange";

const loadTypeModuleNameMap = {
  units: "unit",
  residents: "resident",
  leases: "lease",
  prospects: "prospect",
  move_outs: "move_out",
  move_ins: "move_in",
  completed_work_orders: "completed_work_order",
};

export default {
  mixins: [pendingChange.modalMixin],
  computed: {
    ...mapState(pendingChange.mp, {
      modalIsOpen: pendingChange.keys.modalState,
    }),
    ...mapGetters(pendingChange.mp, { modelModule: "polyModule" }),
    ...mapState({
      file: state => state.file,
      employee: state => state.employee,
    }),

    modelName() {
      return loadTypeModuleNameMap[this.file.instance.load_type];
    },

    modelTitle() {
      if (this.modelModule) return this.modelModule.noun;
      return this.modelName;
    },
  },

  methods: {
    closeModalOnEsc(evt) {
      if (evt.keyCode == 27) {
        if (this.modalIsOpen) {
          this.closeModal();
        } else {
          this.closeModal();
          this.$store.dispatch(pendingChangesTable.p.a.modalClose);
        }
      }
    },
  },

  mounted() {
    this.$CpEvent.$on("pendingChangesSaved", () => {
      this.closeModal();
    });
  },

  created() {
    window.addEventListener("keydown", this.closeModalOnEsc, false);
  },

  destroyed() {
    window.removeEventListener("keydown", this.closeModalOnEsc, false);
  },
};
</script>

<style lang="scss">
.mar-edit-change-window {
  transition: box-shadow 0.25s ease-in-out, height 0.25s ease-in-out;
  width: 100%;
  height: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba($white, 0.9);
  z-index: 200;
  overflow: hidden;

  &.is-active {
    height: 100%;
    box-shadow: 0 -4px 15px 40px rgba($black, 15%);
  }
}
</style>
