import List from "./List.vue";
import Detail from "./Detail.vue";
import Create from "./Create.vue";

export default [
  {
    path: "areas",
    name: "Areas",
    component: List,
    meta: {
      title: "Areas",
    },
  },
  {
    path: "/areas/create",
    name: "addArea",
    component: Create,
    meta: {
      title: "Add Area",
      parent: "Areas",
    },
  },
  {
    path: "/areas/:id",
    name: "areaDetail",
    component: Detail,
    meta: {
      title: "Edit Area",
      mode: "edit",
      parent: "Areas",
    },
  },
];
