<template>
  <cp-fullscreen-modal
    :show.sync="model.createDialogOpen"
    :title="`Add ${model.noun || model.singularName}`"
    back-text="Close"
    back-icon="close"
    :close-confirmation="$store.getters[`${model.singularName}/isDirty`]"
    @fullscreenModalClosed="reset"
    ref="createModal"
  >
    <cp-card>
      <template #title>
        {{ model.item.name }}
      </template>

      <template #actions>
        <cp-btn @click="$refs.createModal.close()" text color="error">
          Cancel
        </cp-btn>
        <cp-btn
          @click="validate"
          color="primary"
          :disabled="
            !$store.getters[`${model.singularName}/isDirty`] ||
              !model.formIsValid ||
              model.saving
          "
          :loading="model.saving"
        >
          Create
        </cp-btn>
      </template>

      <model-form-set ref="modelFormSet" :model="model" />
    </cp-card>
  </cp-fullscreen-modal>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    model: { type: Object, default: () => {} }
  },

  computed: {
    ...mapState({
      tableOptions: state => state.table
    })
  },

  methods: {
    validate() {
      this.$refs.modelFormSet.validate();

      if (this.model.formIsValid) {
        this.$store.dispatch(
          `${this.model.singularName}/create`,
          this.model.item
        );
      }
    },

    reset() {
      this.model.createDialogOpen = false;
      this.$store.commit(`${this.model.singularName}/reset`);
      this.$refs.modelFormSet.reset();
    }
  },

  mounted() {
    this.$CpEvent.$on(`${this.model.singularName}Created`, () => {
      this.$refs.createModal.close(true);
      this.$store.dispatch(
        `${this.model.singularName}/fetchAutoCompleteItems`,
        this.tableOptions
      );
    });
  },

  beforeDestroy() {
    this.$CpEvent.$off(`${this.model.singularName}Created`);
  }
};
</script>
