import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-3"},[_c(VRow,{attrs:{"dense":""}},_vm._l((_vm.model.services),function(service,i){return _c(VCol,{key:i,staticClass:"mb-2",attrs:{"cols":"12","md":"4"}},[_c(VSheet,{staticClass:"text-uppercase text-bold pa-1 px-2 mb-1",attrs:{"color":"#f1f1f1","rounded":""}},[_vm._v(" "+_vm._s(service)+" ")]),(service === 'Engagement System')?_c(VSelect,{attrs:{"items":_vm.engageRoleOptions,"value":_vm.engageRole},on:{"input":_vm.changeEngageRole},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('strong',{staticClass:"mr-3"},[_vm._v(_vm._s(item.text))]),_c('small',{staticClass:"text-caption text--secondary"},[_vm._v("( "+_vm._s(item.description)+" )")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',[_vm._v(_vm._s(item.text))]),_c('h4',{staticClass:"text-caption text--secondary mb-1"},[_vm._v(" "+_vm._s(item.description)+" ")])])]}}],null,true)}):_vm._l((_vm.model.roles.filter(function (x) { return x.service == service; })),function(role){return [_c(VCheckbox,{key:role.value,staticClass:"mt-0",attrs:{"label":role.text,"value":role.value,"hide-details":"","dense":""},model:{value:(_vm.model.item.role_ids),callback:function ($$v) {_vm.$set(_vm.model.item, "role_ids", $$v)},expression:"model.item.role_ids"}}),_c('h4',{staticClass:"text-caption text--secondary mb-1 ml-8"},[_vm._v(" "+_vm._s(role.description || "No description text")+" ")])]})],2)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }