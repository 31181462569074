<template>
  <span>{{ managers.map(x => x.text).join(", ") }}</span>
</template>

<script>
import { get } from "@cp/utils/objectUtils";

export default {
  name: "GroupManagersCell",
  props: ["item"],
  computed: {
    managers() {
      return get(this.item, "group_managers", []);
    },
  },
};
</script>
