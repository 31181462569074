<template>
  <v-expansion-panels v-show="show" class="mb-6" :disabled="isEditing">
    <v-expansion-panel
      v-for="transform in item.transformations"
      :key="transform.id"
    >
      <v-expansion-panel-header>
        <cp-data-to-text
          :data="transform"
          :getter="getTransformValue(transform.transform_type, 'applied_text')"
        />
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <cp-data-to-text
          :data="transform"
          :getter="
            getTransformValue(transform.transform_type, 'applied_description')
          "
        />

        <div class="text-right mt-3">
          <v-btn v-if="isEditing" color="primary" @click="submit">
            <v-icon>mdi-content-save</v-icon>
            Update
          </v-btn>
          <v-btn
            v-if="!isEditing"
            color="error"
            class="ml-2"
            @click="deleteEtl(transform.id)"
          >
            <v-icon>mdi-delete</v-icon>
            Delete
          </v-btn>
          <v-btn
            v-if="
              getTransformValue(transform.transform_type, 'editable') &&
                !isEditing
            "
            color="primary"
            class="ml-2"
            @click="open(transform)"
          >
            <v-icon>mdi-pencil</v-icon>
            Edit
          </v-btn>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { transformsByType } from "@/store/modules/file.js";
import { get } from "carrot-patch-v2/src/utils/objectUtils";
import { mapActions, mapState } from "vuex";

import { pendingChange, etlForm } from "@/store/modules/pendingChange";

export default {
  props: {
    context: { type: String, default: "import" },
  },
  computed: {
    ...mapState(pendingChange.mp, ["item"]),
    ...mapState(etlForm.mp, ["etl"]),
    show() {
      return this.item.transformations && this.item.transformations.length;
    },
    isEditing() {
      return !!this.etl.id;
    },
  },
  methods: {
    ...mapActions(etlForm.mp, ["open", "delete", "submit"]),
    ...mapActions(etlForm.mp, { deleteEtl: "delete" }),
    getTransformValue(type, key) {
      return get(transformsByType, [type, key]);
    },
  },
};
</script>
