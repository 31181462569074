import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[(_vm.transforms && _vm.transforms.length)?_c(VDialog,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"text":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.pluralize("transformation", _vm.transforms.length, true))+" ")])]}}],null,false,1431983589)},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.pluralize("transformation", _vm.transforms.length, true))+" ")]),_c(VDivider),_c(VSimpleTable,{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.transforms),function(transform,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(i + 1)+".")]),_c('td',{staticStyle:{"white-space":"pre","font-family":"monospace"}},[_vm._v(" "+_vm._s(JSON.stringify(transform))+" ")])])}),0)]},proxy:true}],null,false,2788023167)})],1)],1):_vm._e(),(_vm.messages && _vm.messages.length)?_c(VDialog,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"text":"","color":"warning"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.pluralize("warning", _vm.messages.length, true))+" ")])]}}],null,false,1227771519)},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.pluralize("warning", _vm.messages.length, true))+" ")]),_c(VDivider),_c(VSimpleTable,{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.messages),function(message,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(i + 1)+".")]),_c('td',[_vm._v(_vm._s(message))])])}),0)]},proxy:true}],null,false,3773866719)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }