// must be first for load-order
import table from "./modules/table";
import file from "./modules/file";
import position_category from "./modules/position_category";
import issues from "./modules/issues";
import unit from "./modules/unit";
import resident from "./modules/resident";
import lease from "./modules/lease";
import importModule from "./modules/import.js";
import importAudit from "./modules/importAudit.js";

import dashboard from "./modules/dashboard";
import employee from "./modules/employee";
import location from "./modules/location";
import position from "./modules/position";
import corporate from "./modules/corporate";
import area from "./modules/area";
import region from "./modules/region";
import reporting_group from "./modules/reportingGroup";
import import_history from "./modules/importHistory";
import audit from "./modules/audit";
import transform from "./modules/transform";

export default {
  // old
  table,
  file,
  position_category,
  issues,
  unit,
  resident,
  lease,
  import: importModule,
  importAudit,

  // new
  dashboard,
  employee,
  location,
  position,
  corporate,
  area,
  region,
  reporting_group,
  import_history,
  audit,
  transform,
};
