<template>
  <div class="pa-3">
    <v-btn @click="openAssignLocations" color="primary" class="mb-3">
      <v-icon class="mr-1">mdi-plus</v-icon>Assign New Locations
    </v-btn>
    <v-expansion-panels multiple>
      <v-expansion-panel
        v-for="location in model.item.locations"
        :key="location.id"
      >
        <v-expansion-panel-header>
          <span>
            <v-btn icon color="error" @click.stop="removeLocation(location.id)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            {{ location.name }}
          </span>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          Employees: {{ location.user_count }}
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <cp-dialog
      :show-model.sync="region.locationAssignmentDialogOpen"
      max-width="1100"
      header-background
      scrollable
      @modalClosed="tableOptions.q_text = null"
    >
      <template #title>
        Assign Locations
      </template>

      <template #body>
        <list-table
          :static-headers="locHeaders"
          model-name="location"
          hide-header
          hide-filters
          hide-bulk-actions
          flat
        />
      </template>

      <template #actions>
        <v-btn
          color="primary"
          @click="assignLocations"
          :disabled="location.saving || location.bulkSelection.length <= 0"
          :loading="location.saving"
        >
          Assign Locations to Region
        </v-btn>
      </template>
    </cp-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "RegionLocations",

  props: {
    model: { type: Object, default: () => {} },
  },

  computed: {
    ...mapState({
      region: state => state.region,
      location: state => state.location,
      tableOptions: state => state.table,
    }),

    locHeaders() {
      return [
        {
          text: "Name",
          value: "name",
          linkCell: true,
          width: "auto",
        },
        {
          text: "Type",
          value: "component_cell",
          component: "location-type-id",
          width: "20px",
          sortable: false,
        },
        {
          text: "Region",
          value: "region.name",
          width: "15%",
        },
        {
          text: "City",
          value: "city",
          width: "15%",
        },
        {
          text: "State",
          value: "state",
          width: "10%",
        },
        {
          text: "Employees",
          value: "employee_count",
          width: "10%",
        },
        {
          text: "Units",
          value: "unit_count",
          width: "10%",
        },
        {
          text: "Status",
          value: "status_id",
          width: "100px",
        },
      ];
    },

    depth() {
      return this.$route.params.depth || "region";
    },

    itemId() {
      return this.$store.state[this.depth].item.id;
    },
  },

  methods: {
    ...mapMutations("table", ["registerToggles", "clear"]),

    openAssignLocations() {
      this.region.locationAssignmentDialogOpen = true;
      if (this.location.items.length > 0) {
        this.$store.dispatch(
          "location/fetchAutoCompleteItems",
          this.tableOptions
        );
      }
    },

    assignLocations() {
      this.$store
        .dispatch("location/massAssignRegion", {
          geographical_group_ids: [this.itemId],
          location_ids: this.location.bulkSelection.map(x => x.id),
        })
        .then(() => {
          this.$store.dispatch(`${this.depth}/fetchItem`, this.itemId);
          this.region.locationAssignmentDialogOpen = false;
        });
    },

    removeLocation(locId) {
      this.$store
        .dispatch("location/update", { id: locId, region_id: null })
        .then(() => {
          this.$store.dispatch(`${this.depth}/fetchItem`, this.itemId);
        });
    },
  },
  mounted() {
    // This is registering the toggle on the root table, not the modal table ugh... need more modular approach
    // this.registerToggles([
    //   {
    //     label: "Hide offices",
    //     filter: { location_types: 2 },
    //     value: true,
    //   },
    // ]);
  },
  destroyed() {
    this.clear();
  },
};
</script>
