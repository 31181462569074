<template>
  <div v-if="office && property">
    <p>Link this office with this property?</p>
    <div class="d-flex align-center">
      <linked-location-card :location="office" />

      <div class="text-center mx-5">
        <v-icon class="mx-5" huge>mdi-link-plus</v-icon><br />
        Create Link
      </div>

      <linked-location-card :location="property" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LinkedLocationCard from "./LinkedLocationCard.vue";

export default {
  components: { LinkedLocationCard },
  props: {
    model: { type: Object, default: () => {} }
  },

  computed: {
    ...mapState("location", ["bulkSelection"]),
    office() {
      return this.bulkSelection.find(
        ({ location_type }) => location_type === "office"
      );
    },
    property() {
      return this.bulkSelection.find(
        ({ location_type }) => location_type === "property"
      );
    }
  }
};
</script>
