import { VCard } from 'vuetify/lib/components/VCard';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cp-h1',{scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_vm._v("Total: "+_vm._s(_vm.meta.pagination.records))]},proxy:true},(!_vm.hideFilters)?{key:"right",fn:function(){return [_c('cp-filters-menu',{attrs:{"module":_vm.table}})]},proxy:true}:null],null,true)},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VCard,[_c('Table',_vm._g(_vm._b({staticClass:"clickable-rows",on:{"click:row":function (ref) {
	var id = ref.id;

	return _vm.$router.push({ name: 'transformDetail', params: { id: id } });
}},scopedSlots:_vm._u([{key:"item.transform_type",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.snakeToSentenceCase(value))+" ")]}},{key:"item.extract_type",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.capitalize(_vm.pluralize(value, 1)))+" "),_c('span',{attrs:{"set":(_vm.label = _vm.get(item, 'system_record.label'))}},[(_vm.label)?_c('span',[_vm._v("- "+_vm._s(_vm.label))]):_vm._e()])]}},{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.toMDYTime(value))+" ")]}},{key:"item.explanation",fn:function(ref){
var value = ref.value;
return [_c('cp-explain',[_vm._v(_vm._s(value))])]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [(item.transform_type === 'ignore_attributes')?_c('span',[_c('div',{staticClass:"text-caption"},[_vm._v("Fields Ignored:")]),_c('span',[_vm._v(" "+_vm._s(_vm.get(item, "transformations.ignore_attributes", []) .map(_vm.snakeToSentenceCase) .join(", "))+" ")])]):_vm._e(),(item.transform_type === 'ignore_record')?_c('span',[_vm._v(" Whole record skipped ")]):_vm._e()]}}]),model:{value:(_vm.tableSelected),callback:function ($$v) {_vm.tableSelected=$$v},expression:"tableSelected"}},'Table',_vm.tableProps,false),_vm.tableListeners))],1),_c('bulk-action-modals',_vm._b({},'bulk-action-modals',_vm.bulkActionsMenuProps,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }