<template>
  <div>
    <cp-toolbar-module-modal
      v-for="(action, i) in actionModals"
      :key="`ba-dialog-${i}`"
      :modalConf="action.modalConf"
    >
      <template #title> {{ capitalize(actionLabel(action)) }}? </template>

      <p v-if="action.explanation">
        {{ action.explanation }}
      </p>

      <div
        v-if="!action.hideChips"
        class="d-flex justify-center flex-wrap"
        style="gap: 10px"
      >
        <template v-for="item in selected">
          <v-chip :key="item.id" :set="(name = nameOfItem(item))">
            {{ name }}
          </v-chip>
        </template>
      </div>

      <component
        v-if="action.modalComponent"
        :is="action.modalComponent"
        :selected="selected"
        :action="action"
      />

      <div v-else class="d-flex justify-end mt-6">
        <div v-if="action.success" class="d-flex align-center">
          <CpSuccessIcon class="mr-4" xLarge />
          {{ successMessage(action) }}
        </div>
        <template v-else>
          <v-btn @click="action.modalConf.close" class="mr-4">Cancel</v-btn>
          <v-btn
            @click="action.confirm"
            color="primary"
            :loading="action.loading"
          >
            {{ buttonText(action) }}
          </v-btn>
        </template>
      </div>
    </cp-toolbar-module-modal>
  </div>
</template>

<script>
import pluralize from "pluralize";
import { capitalize, kebabToSentenceCase } from "@cp/utils/stringUtils";
import { get } from "@cp/utils/objectUtils";

export default {
  props: {
    bulkActions: { type: Array, required: true },
    selected: { type: Array, required: true },
    noun: { type: String, default: "item" },
  },
  computed: {
    actionModals() {
      const $vm = this;
      return this.bulkActions.map(({ subModule, ...ba }) => {
        const labelParts = [ba.name.replace("_")];
        if (ba.article) labelParts.push(ba.article);
        labelParts.push(pluralize(this.noun, this.selected.length, true));
        return {
          ...ba,
          confirm: () => {
            let data;
            if (ba.dataFn) data = ba.dataFn($vm.selected);
            $vm.$store.dispatch(subModule.p.a.confirm, data);
          },
          loading: get($vm.$store.state, subModule.p.s.submitting),
          success: get($vm.$store.state, subModule.p.s.success),
          subModule,
          modalConf: {
            props: {
              value: get(this.$store.state, subModule.p.s.modalState),
              width: subModule.width || 500,
            },
            close: args => $vm.$store.dispatch(subModule.p.a.modalClose, args),
            open: args => $vm.$store.dispatch(subModule.p.a.modalOpen, args),
            opened: subModule.keys.modalOpenedEvent,
            closed: subModule.keys.modalClosedEvent,
            listeners: {
              input(value) {
                if (value) {
                  open();
                } else {
                  close();
                }
              },
            },
          },
        };
      });
    },
  },
  methods: {
    capitalize,
    kebabToSentenceCase,
    nameOfItem(item) {
      return (
        item.name ||
        item.full_name ||
        [item.first_name, item.last_name].join(" ") ||
        item.title ||
        item.unit_number
      );
    },
    actionLabel(action) {
      if (action.label) return action.label;
      const labelParts = [action.name.replace(/_/g, " ")];
      if (action.article) labelParts.push(action.article);
      labelParts.push(pluralize(this.noun, this.selected.length, true));
      return labelParts.join(" ");
    },

    buttonText(action) {
      const textParts = [action.name.replace(/_/g, " ")];
      if (textParts[0].length < 10) {
        if (action.article) textParts.push(action.article);
        textParts.push(pluralize(this.noun, this.selected.length, true));
      }
      return textParts.join(" ");
    },

    successMessage(action) {
      if (!action.successMessage) return "Success!";
      if (typeof action.successMessage === "function")
        return action.successMessage({ ids: this.selected });
      return action.successMessage;
    },
  },
};
</script>
