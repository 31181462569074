import { required } from "@cp/utils/rules";

import { Model } from "@/store/mixins/Model";
import search from "@/store/modules/search";

const positionSearch = search.register("positions");
export const categorySearch = search.register("position_categories");

export const position = new Model({
  module: "position",
  table: {
    headers: [
      { text: "", value: "alert", sortable: false, width: "20px" },
      {
        text: "Name",
        value: "name",
        width: "auto",
        cellClass: "linkedCell",
      },
      {
        text: "Category",
        value: "generalized_position_category",
        width: "20%",
      },
      {
        text: "Employees",
        value: "employee_count",
        width: "10%",
      },
      {
        text: "Status",
        value: "status_id",
        width: "100px",
      },
    ],
    filters: [
      "q_text",
      {
        label: "General",
        type: "select",
        key: "general",
        items: [
          { label: "Active", value: "active" },
          { label: "Inactive", value: "archived" },
          {
            label: "No Category Assigned",
            value: "missing_category",
          },
        ],
        initialValue: "active",
      },
      {
        label: "Position Categories",
        type: "sub_query",
        key: "position_categories",
        multiple: true,
      },
    ],
    bulkActions: [
      {
        name: "assign_categories",
        label: "Assign position category",
        explanation: "Assign category to all these positions:",
        fields: {
          generalized_position_category_id: { rules: [required] },
        },
        modalComponent: "bluk-set-positions-category",
        successMessage: "Positions updated!",
      },
    ],
  },
  form: {
    initialValue: {},
    fields: {
      name: {
        label: "Name",
        rules: [required],
      },
      reference_id: {
        label: "Position ID",
      },
      generalized_position_category_id: {
        label: "Category",
        type: "api-autocomplete",
        searchModule: categorySearch,
      },
    },
    fieldOrder: ["name", "reference_id", "generalized_position_category_id"],
  },
  search: positionSearch,
  detailTabs: ["general", "history"],
});

window.$position = position;

export default position.toVuex;
