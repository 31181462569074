import List from "./List.vue";
import Detail from "./Detail.vue";
import Create from "./Create.vue";

export default [
  {
    path: "/positions",
    name: "Positions",
    component: List,
    meta: {
      title: "Positions",
    },
  },
  {
    path: "/positions/create",
    name: "addPosition",
    component: Create,
    meta: {
      title: "Add Position",
      parent: "Positions",
    },
  },
  {
    path: "/positions/:id",
    name: "positionDetail",
    component: Detail,
    meta: {
      title: "Edit Position",
      parent: "Positions",
      mode: "edit",
    },
  },
];
