import Vue from "vue";

const MarigoldComponents = require.context(
  "@/components",
  true,
  /\.(js|vue)$/i
);

MarigoldComponents.keys().map(path => {
  let componentName = path.substring(path.lastIndexOf("/") + 1).split(".")[0];
  return Vue.component(componentName, MarigoldComponents(path).default);
});
