import List from "./List.vue";
import Detail from "./Detail.vue";
import Create from "./Create.vue";

export default [
  {
    path: "corporate",
    name: "Corporate Locations",
    component: List,
    meta: {
      title: "Employees",
    },
  },
  {
    path: "/corporate-locations/create",
    name: "addCorporateLocation",
    component: Create,
    meta: {
      title: "Add Corporate Location",
      parent: "Corporate Locations",
    },
  },
  {
    path: "/corporate-locations/:id",
    name: "corporateLocationDetail",
    component: Detail,
    meta: {
      title: "Edit Corporate Location",
      mode: "edit",
      parent: "Corporate Locations",
    },
  },
];
