import { mapItem } from "@cp/utils/jsonapiUtils";

import { Model } from "@/store/mixins/Model";

export const importSteps = [
  "processing",
  "un-mapped",
  "mapped",
  "pre-processed",
  "completed",
  "cancelled",
];

export const importHistory = new Model({
  module: "import_history",
  urlKey: "import_history",
  noun: "imported data",
  compoundId: true,
  table: {
    exportOptions: { downloadXlsx: false },
    headers: [
      {
        text: "User",
        value: "parent_upload_details.uploading_user",
        sortable: false,
      },
      {
        text: "Date",
        value: "created_at",
        sortable: false,
        cellClass: "text-no-wrap",
      },
      {
        text: "File Name",
        value: "parent_upload_details.name",
        sortable: false,
      },
      {
        text: "Results",
        value: "parent_audit_counts",
        sortable: false,
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
      },
      // {
      //   text: "Adds",
      //   value: "added",
      //   width: 30,
      //   sortable: false,
      // },
      // {
      //   text: "Changes",
      //   value: "changes",
      //   width: 30,
      //   sortable: false,
      // },
      // {
      //   text: "Archives",
      //   value: "archives",
      //   width: 30,
      //   sortable: false,
      // },
      // {
      //   text: "Restores",
      //   value: "restores",
      //   width: 30,
      //   sortable: false,
      // },
      // {
      //   text: "Errors",
      //   value: "errors",
      //   width: 30,
      //   sortable: false,
      // },
    ],
    filters: ["q_text"],
    useGlobalBulkActions: false,
    bulkActions: [],
  },
  form: {
    url: "",
    urlTemplate: false,
    initialValue: {
      parent_upload_id: "",
      parent_upload_details: {
        mime: "",
        name: "",
        ip_address: "",
        full_signiture: "",
        uploading_user: "",
        contains_content: true,
      },
      created_at: "",
      parent_audit_counts: {
        etls: 0,
        archived: 0,
        restored: 0,
        update: 0,
      },
      sheets: [],
    },
  },
});

// force item to be an array, because it loads sheets
importHistory.form.mapData = function(response) {
  return mapItem(response.data.data[0]);
};

window.$importHistory = importHistory;

export default importHistory.toVuex;
