import List from "./List.vue";
import Detail from "./Detail.vue";

export default [
  {
    path: "/transforms",
    name: "Transforms",
    component: List,
    meta: {
      title: "Data Transforms",
    },
  },
  {
    path: "/transforms/:id",
    name: "transformDetail",
    component: Detail,
    meta: {
      title: "Data Transform Detail",
    },
  },
];
