import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cp-h1',{scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [(_vm.selected.length)?_c('span',[_vm._v(" Selected: "+_vm._s(_vm.selected.length)+"/"+_vm._s(_vm.meta.pagination.records)+" ")]):_c('span',[_vm._v("Total: "+_vm._s(_vm.meta.pagination.records))])]},proxy:true},{key:"rightOfTitle",fn:function(){return [(_vm.selected.length)?_c('bulk-actions-menu',_vm._b({},'bulk-actions-menu',_vm.bulkActionsMenuProps,false)):_c(VBtn,{attrs:{"to":{ name: 'addLocation' },"color":"primary","small":""}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add Location ")],1)]},proxy:true},{key:"right",fn:function(){return [_c('cp-filters-menu',{attrs:{"module":_vm.table}})]},proxy:true}])},[_vm._v(" Locations ")]),_c(VCard,[_c('Table',_vm._g(_vm._b({staticClass:"clickable-rows",on:{"click:row":function (ref) {
	var id = ref.id;

	return _vm.$router.push({ name: 'locationDetail', params: { id: id } });
}},model:{value:(_vm.tableSelected),callback:function ($$v) {_vm.tableSelected=$$v},expression:"tableSelected"}},'Table',_vm.tableProps,false),_vm.tableListeners))],1),_c('bulk-action-modals',_vm._b({},'bulk-action-modals',_vm.bulkActionsMenuProps,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }