<template>
  <cp-loading :loading="itemLoading">
    <cp-h1>
      Import Summary

      <template #subtitle v-if="item">
        <div class="d-flex align-center">
          <v-chip small>
            <v-icon left small>mdi-file-table-outline</v-icon>
            {{ item.parent_upload_details.name }}
          </v-chip>
          <span class="ml-3">
            {{ toMDYTime(item.created_at) }}
          </span>

          <span class="ml-3">
            Uploaded by: {{ item.parent_upload_details.uploading_user }}
          </span>
        </div>
      </template>

      <template #right> </template>
    </cp-h1>

    <v-divider />

    <v-row class="mt-6 px-6 SheetHeader">
      <v-col cols="4">Sheet Name</v-col>
      <v-col cols="2">Status</v-col>
      <v-col>Results</v-col>
    </v-row>

    <v-expansion-panels>
      <v-expansion-panel
        v-for="sheet in item.sheets"
        :key="sheet.id"
        @click="() => headerClick(sheet)"
        class="mb-3"
      >
        <v-expansion-panel-header>
          <v-row
            class="align-center"
            :set="(completed = sheet.step === 'completed')"
          >
            <v-col cols="4">
              <v-icon class="mr-2">mdi-table-large</v-icon>
              {{ sheet.upload_details.sheet_name }}
            </v-col>
            <v-col cols="2">
              <v-chip :color="completed ? 'success' : ''" small>
                <v-icon v-if="completed" small left>mdi-check</v-icon>
                {{ sheet.step }}
              </v-chip>
            </v-col>
            <v-col>
              <div class="d-flex flex-wrap" style="gap:6px">
                <action-chip
                  v-for="(v, key) in sheet.audit_summary"
                  :key="`${item.id}-${key}s`"
                  :action="key"
                  :count="v"
                  class="pl-2"
                  small
                />
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <cp-loading :loading="sheetLoading">
            <TransformList
              v-if="!sheetHasNoTransforms"
              title="Overrides"
              hide-filters
            />

            <cp-h1 v-if="!auditMeta.pagination.records">
              No Changes Applied
            </cp-h1>
            <AuditList v-else title="Changes Applied" />
          </cp-loading>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </cp-loading>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import { toMDYTime } from "@cp/utils/dateUtils";
import { wait } from "@cp/utils/promiseUtils";

import { importHistory } from "@/store/modules/importHistory";
import { transform } from "@/store/modules/transform";
import { audit } from "@/store/modules/audit";

import TransformList from "@/views/import/transform/List";
import AuditList from "@/views/import/audit/List";

export default {
  components: { TransformList, AuditList },
  data() {
    return {
      importHistory,
      openSheetId: "",
      sheetLoading: false,
      sheetHasNoTransforms: false,
    };
  },
  computed: {
    ...mapState(importHistory.mp, ["item"]),
    ...mapState(importHistory.mp, ["itemLoading"]),
    ...mapState(transform.mp, {
      transformLoading: "itemsLoading",
      transformMeta: "itemsMeta",
    }),
    ...mapState(audit.mp, {
      auditLoading: "itemsLoading",
      auditMeta: "itemsMeta",
    }),
    ...mapGetters(importHistory.mp, ["tabs"]),
    firstSheet() {
      return this.item.sheets[0];
    },
  },
  methods: {
    toMDYTime,
    ...mapMutations(importHistory.mp, ["resetItem"]),
    ...mapActions(importHistory.mp, [
      "fetchItem",
      "update",
      "archive",
      "restore",
    ]),
    ...mapMutations(importHistory.mp, ["resetItem"]),
    sendWelcomeEmail() {
      const ap =
        importHistory.table.subModuleRefs.bulkSendWelcomeEmails.p.a.confirm;
      this.$store.dispatch(ap, { ids: [this.item.id] });
    },
    headerClick(sheet) {
      const id = sheet.id;
      if (id === this.openSheetId) return;
      this.openSheetId = id;
      this.sheetLoading = true;
      const transformation_ids = sheet.upload_details.invoked_etl_rules;
      this.sheetHasNoTransforms =
        !transformation_ids || !transformation_ids.length;
      const p1 = this.$store.dispatch(transform.table.p.a.updateParams, {
        filters: { transformation_ids },
      });
      const p2 = this.$store.dispatch(audit.table.p.a.updateParams, {
        filters: { imports: [id] },
      });
      const waitPromise = wait(500);
      Promise.all([p1, p2, waitPromise]).then(async () => {
        await this.$nextTick();
        this.sheetLoading = false;
      });
    },
  },
  mounted() {
    const { id } = this.$route.params;
    if (id) {
      this.resetItem();
      this.fetchItem({ params: { filters: { parent_id: [id] } } });
    }
    this.$store.commit(transform.table.p.m.reset);
    this.$store.commit(audit.table.p.m.reset);
  },
  beforeDestroy() {
    this.$store.commit(transform.table.p.m.reset);
    this.$store.commit(audit.table.p.m.reset);
  },
};
</script>

<style lang="scss" scoped>
::v-deep .CpH1__subtitle {
  span.ml-3 {
    line-height: 1.5em;
  }
}

::v-deep {
  .theme--light.v-expansion-panels .v-expansion-panel {
    &,
    &:before {
      background: transparent;
      box-shadow: none;
    }
  }
  .v-expansion-panel-header {
    background: #fff;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }
  .v-expansion-panel-content__wrap {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}
</style>
