<template>
  <div>
    <v-tooltip v-for="sheet in sheets" :key="sheet.id" bottom>
      <template #activator="{on, attrs}">
        <v-badge v-bind="sheet.badgeProps">
          <v-btn v-bind="{ ...attrs, ...sheet.btnProps }" v-on="on">
            <v-icon left>mdi-file-table-outline</v-icon>
            {{ sheet.sheetName }}
            <v-icon v-if="sheet.icon" right>{{ sheet.icon }}</v-icon>
            <small v-if="sheet.iconExplained">
              <em>({{ sheet.iconExplained }})</em>
            </small>
          </v-btn>
        </v-badge>
      </template>
      <div class="summaryText">{{ sheet.summary }}</div>
    </v-tooltip>
  </div>
</template>

<script>
import { get } from "carrot-patch-v2/src/utils/objectUtils";
import pluralize from "pluralize";
import { summaryTextMap } from "@/store/modules/import";

function sheetStatus(sheet) {
  let badgeProps = { bordered: true, color: "success", overlap: true },
    btnProps = { class: "my-1 mx-2", color: "primary", value: false },
    summary = [],
    icon = "";

  switch (sheet.step) {
    case "completed":
    case "cancelled":
      btnProps.to = {
        name: "ImportHistory",
        params: { uuid: sheet.id }
      };
      break;
    case "pre-processed":
      summary.push("Ready for review");
      icon = "mdi-open-in-new";
      badgeProps.color = "primary";
      btnProps.color = "primary";
      btnProps.to = { name: "CurrentImport" };
      break;
    case "processing":
      summary.push("(Currently Processing...)");
    case "mapped":
      icon = "mdi-pause-circle";
      btnProps.disabled = true;
      summary.push("(Mapped, but not started)");
      btnProps.to = { name: "CurrentImport" };
      break;
  }

  const summaryKeys = Object.keys(sheet.audit_summary || {});
  const nResults = summaryKeys.reduce((r, k) => r + sheet.audit_summary[k], 0);
  const nMessages = get(sheet, "upload_details.messages.length", 0);
  const nEtls = get(sheet, "upload_details.invoked_etl_rules.length", 0);

  if (nResults || nMessages || nEtls) {
    badgeProps.content = nResults + nMessages + nEtls;
    badgeProps.value = true;
  }

  if (nResults > 0) {
    summaryKeys.forEach(k => {
      const item = pluralize(sheet.import_model, sheet.audit_summary[k], true);
      summary.push(`${item} ${summaryTextMap[k]}`);
    });

    if (sheet.step === "cancelled")
      summary.push("then the rest of the sheet was cancelled.");
  } else {
    badgeProps.value = false;
    summary.push("No changes made");
    if (sheet.step === "cancelled") summary.push("Sheet was cancelled");
  }

  if (nMessages) {
    badgeProps.color = "warning";
    summary.push(pluralize("Warning", nMessages, true));
  }
  if (nEtls) summary.push(pluralize("Transform", nEtls, true));
  summary = summary.join("\n");

  return {
    badgeProps,
    btnProps,
    sheetName: get(sheet, "upload_details.sheet_name"),
    summary,
    id: sheet.id,
    icon
  };
}

export default {
  props: {
    header: { type: Object },
    item: { type: Object }
  },
  computed: {
    sheets() {
      return this.item.sheets.map(sheet => ({
        sheet,
        ...sheetStatus(sheet)
      }));
    }
  }
};
</script>

<style scoped lang="scss">
.summaryText {
  white-space: pre;
}
</style>
