<template>
  <span>
    <v-menu v-if="selected.length && bulkActions.length" bottom offset-y>
      <template #activator="{ on, attrs }">
        <v-btn color="primary" small v-on="on" v-bind="{ ...attrs, ...$attrs }">
          <v-icon left>mdi-chevron-down</v-icon>
          Bulk Actions (
          {{ selected.length }} selected )
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item
          v-for="(action, i) in applicableBulkActions"
          :key="`action-${i}`"
          @click="openBAModal(action.subModule)"
        >
          {{ capitalize(actionLabel(action)) }}
        </v-list-item>
      </v-list>
    </v-menu>
  </span>
</template>

<script>
import pluralize from "pluralize";
import { capitalize, kebabToSentenceCase } from "@cp/utils/stringUtils";

export default {
  props: {
    bulkActions: { type: Array, required: true },
    selected: { type: Array, required: true },
    noun: { type: String, default: "item" },
  },
  methods: {
    pluralize,
    capitalize,
    kebabToSentenceCase,
    openBAModal(subModule) {
      this.$store.dispatch(subModule.p.a.modalOpen, this.selected);
    },
    actionLabel(action) {
      if (action.label) return action.label;
      const labelParts = [action.name.replace(/_/g, " ")];
      if (action.article) labelParts.push(action.article);
      labelParts.push(pluralize(this.noun, this.selected.length, true));
      return labelParts.join(" ");
    },
  },
  computed: {
    applicableBulkActions() {
      const selected = this.selected;
      return this.bulkActions.filter(({ showIf } = {}) => {
        if (typeof showIf !== "function") return true;
        return showIf(selected);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-label {
  display: block;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  color: $gray-4;
}

.v-card__actions {
  padding: 0 24px 20px !important;
}
</style>
